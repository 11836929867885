.header {
  z-index: 1000;
}

.header__log-out svg {
  fill: var(--title-color);
  transition: 0.3s ease;
}

.header__log-out:hover svg {
  fill: #e4dc00;
}

.header.fixed {
  position: fixed;
  z-index: 10000;
  width: 100%;
}

.header__left,
.header__right {
  display: flex;
  align-items: center;
}

.header__log-out:hover .header__log-in {
  color: var(--title-color);
}

@media (min-width: 1300px) {
  .header__left,
  .header__right {
    min-width: 40%;
  }
}

@media only screen and (min-width: 601px) {
  .header {
    padding: 12px 0 7px;
    position: relative;
    z-index: 11;
    background: var(--main-bg);
    box-shadow: var(--header-box-shadow);
  }

  .header .container {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    justify-content: space-between;
    align-items: center;
    position: relative;
    max-width: 1370px;
  }

  .header__nav {
    display: -webkit-box;
    display: flex;
    width: auto;
    margin: 0 0 0 auto;
  }

  .header__nav .btn {
    width: 228px;
    margin-right: 18px;
  }

  .header__lang-wrap {
    position: relative;
    padding-right: 20px;
  }

  .header__lang-wrap:after {
    position: absolute;
    content: '';
    width: 13px;
    height: 5px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 0;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("data:image/svg+xml,%3Csvg width='13' height='6' viewBox='0 0 13 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.49504 5.25L-0.000146407 -1.81981e-07L12.9902 9.53674e-07L6.49504 5.25Z' fill='white'/%3E%3C/svg%3E%0A");
  }

  .header__lang {
    font-size: 14px;
    text-align: center;
    letter-spacing: 0.03em;
    color: #ffffff;
    font-weight: 700;
    cursor: pointer;
  }

  .header__logo {
    max-width: 198px;
    height: 51px;
    position: relative;
    left: auto;
    top: auto;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }

  .header__logo img {
    width: 100%;
  }

  .header__lk {
    -webkit-box-flex: 0;
    flex: none;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    margin: 0 9px 0 auto;
    width: 54px;
    height: 54px;
    border-radius: 50%;
    background-color: var(--header-lk-bg);
  }

  .header__lk:hover {
    background-color: var(--header-lk-bgHover);
  }

  .header__lk svg {
    width: 26px;
    height: 27px;
  }

  .header__log-out {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    margin-left: 22px;
  }

  .header__log-out svg {
    width: 24px;
    height: 24px;
  }

  .header__log-in {
    font-weight: 700;
    font-size: 14px;
    line-height: 1;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #ffffff;
    margin-left: 6px;
  }

  .header__balance {
    background-color: var(--header-balance-bg);
    border-radius: 48px;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    padding: 9px 20px 6px 35px;
    margin-right: 15px;
    min-width: 267px;
  }

  .header__balance .balance__title {
    text-transform: uppercase;
    font-weight: 600;
    color: #fff;
  }

  .balance-wrap {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }

  .balance__wrap {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }

  .balance__info {
    font-weight: 700;
    font-size: 12px;
    color: #ffffff;
    padding-left: 8px;
  }

  .balance__title {
    font-size: 12px;
    color: var(--description-text-color);
    padding-left: 8px;
    white-space: nowrap;
  }

  .balance__count-wrap {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    margin: -5px 0;
  }

  .balance__img {
    -webkit-box-flex: 0;
    flex: none;
  }

  .balance__img svg {
    width: 32px;
    height: 32px;
  }

  .balance__count {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    font-weight: 700;
    font-size: 14px;
    color: #ffffff;
    white-space: nowrap;
  }

  .balance__count_main {
    color: var(--title-color);
  }

  .balance__commission {
    font-size: 12px;
    color: var(--title-color);
    padding: 0 0 0 8px;
  }

  .header__balance .balance {
    margin-right: 30px;
  }

  .header__balance .balance:last-child {
    margin-right: 0;
  }

  .nav_lk {
    margin-right: 25px;
  }

  .nav_lk .nav__item {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    white-space: nowrap;
  }

  .nav_lk svg {
    width: 20px !important;
    height: 25px !important;
    top: auto !important;
  }

  .nav_lk svg.games-icon {
    width: 25px !important;
    margin-bottom: 2px;
  }

  .nav_lk .nav__text {
    color: #ffffff;
    margin-left: 7px;
  }

  .nav_lk .nav__text:hover {
    color: var(--link-color);
  }

  .nav__list {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
  }

  .nav__item {
    margin-right: 16px;
  }

  .nav__item:last-child {
    margin-right: 0;
  }

  .nav__item:first-child svg {
    position: relative;
    top: -2px;
  }

  .nav__link {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    font-weight: 700;
  }

  .nav__link.active svg,
  .nav__link:hover svg {
    fill: var(--link-color);
  }

  .nav svg {
    width: 35px;
    height: 35px;
    margin-bottom: 5px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }

  .nav__text {
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: var(--link-color);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }

  .nav__text:hover {
    text-shadow: var(--link-text-shadow);
  }

  .news-wrap {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: stretch;
    align-items: stretch;
    flex-wrap: wrap;
    margin: 0 -10px;
  }
}

@media only screen and (max-width: 1096px) {
  .header .container {
    justify-content: space-between;
  }

  .burger {
    margin-right: 5px;
  }

  .header__lk {
    margin-left: 0 !important;
    margin-right: 5px;
  }

  .header__balance {
    margin-right: 5px;
  }
}
@media only screen and (max-width: 768px) {
  .header__nav .register-btn {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .header {
    position: relative;
    z-index: 11;
    box-shadow: var(--header-box-shadow);
  }

  .header_lk {
    padding: 8px 0 7px;
  }

  .header_lk .header__logo {
    max-width: 198px;
    height: 51px;
    position: absolute;
    left: 50%;
    top: auto;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  .header .container {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    -webkit-box-pack: justify;
    justify-content: space-between;
    max-width: 1370px;
    padding: 0 10px;
  }

  .header__nav {
    display: -webkit-box;
    display: flex;
    width: auto;
    margin: 0 0 0 auto;
  }

  .header__nav .btn {
    width: 228px;
    margin-right: 18px;
  }

  .header .btn.secondary {
    font-size: 10px;
    padding: 8px 17px;
  }

  .header__lang-wrap {
    position: relative;
    padding-right: 20px;
  }

  .header__lang-wrap:after {
    position: absolute;
    content: '';
    width: 13px;
    height: 5px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 0;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("data:image/svg+xml,%3Csvg width='13' height='6' viewBox='0 0 13 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.49504 5.25L-0.000146407 -1.81981e-07L12.9902 9.53674e-07L6.49504 5.25Z' fill='white'/%3E%3C/svg%3E%0A");
  }

  .header__lang {
    font-size: 14px;
    text-align: center;
    letter-spacing: 0.03em;
    color: #ffffff;
    font-weight: 700;
    cursor: pointer;
  }

  .header__logo {
    max-width: 315px;
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    top: 15%;
  }

  .header__logo img {
    width: 100%;
  }

  .header__lk {
    -webkit-box-flex: 0;
    flex: none;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
  }

  .header__lk svg {
    width: 18px;
    height: 19px;
    margin-right: 10px;
  }

  .header__balance-lk {
    font-weight: 700;
    font-size: 14px;
    color: #ffffff;
  }

  .header__log-out {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
  }

  .header__log-out svg {
    width: 19px;
    height: 19px;
    margin-right: 5px;
  }

  .header__log-out:hover .header__log-in {
    color: var(--title-color);
  }

  .header__log-in {
    font-weight: 700;
    font-size: 12px;
    line-height: 1;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #ffffff;
    margin-left: 6px;
  }

  .header__balance {
    background-color: var(--header-balance-bg);
    border-radius: 48px;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    padding: 9px 20px 6px 35px;
    margin-right: 15px;
  }

  .header__balance .balance {
    margin-right: 30px;
  }

  .header__balance .balance:last-child {
    margin-right: 0;
  }

  .header__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 31px;
  }

  .header__logo img {
    height: 31px;
    width: auto;
  }
}

.logo-auth {
  width: 112px;
  height: 39px;
}

.logo-auth > img {
  width: 100%;
  height: 100%;
}

.header__log-out {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-left: 22px;
}
