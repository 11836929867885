.lottery-title {
  font-weight: 700;
  font-size: 20px;
  letter-spacing: 0.05em;
  color: #ffffff;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.game-head__name-wrap {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.game-head {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.game-head__name {
  font-weight: 700;
  font-size: 30px;
  line-height: 1;
  color: var(--title-color);
  text-shadow: var(--title-text-shadow);
}

.block-title__svg {
  width: 43px;
  height: 43px;
  margin-right: 11px;
}

.block-title__svg img {
  height: 100%;
  width: 100%;
}

.section-title {
  font-weight: 700;
  font-size: 36px;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
  color: var(--title-color);
  mix-blend-mode: normal;
  text-shadow: var(--link-text-shadow);
  margin-bottom: 40px;
}

@media only screen and (max-width: 992px) {
  .section-title {
    font-size: 30px;
    margin: 0;
    margin-bottom: 29px;
  }
  .game-head__name {
    font-size: 30px;
  }
  .block-title__svg {
    width: 36px;
    height: 36px;
  }
}
@media only screen and (max-width: 768px) {
  .section-title {
    font-size: 24px;
    margin: 0;
    margin-bottom: 29px;
  }
  .game-head__name {
    font-size: 24px;
  }
  .block-title__svg {
    width: 32px;
    height: 32px;
    margin-right: 6px;
  }
}

@media only screen and (max-width: 600px) {
  .section-title {
    font-size: 18px;
    margin: 0;
    margin-bottom: 29px;
  }
  .game-head__name {
    font-size: 18px;
  }
  .game-head__img {
    display: none;
  }

  .block-title__svg {
    width: 28px;
    height: 28px;
  }

  .block-title {
    text-transform: uppercase;
    margin-bottom: 10px;
  }
}
