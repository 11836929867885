.bonus-slider__wrap {
  position: relative;
  padding: 5px 36px 45px 38px;
}



.bonus-slider__wrap .swiper-pagination {
  bottom: -34px;
}

.bonus-slider__wrap .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: var(--slider-bullet-color);
}

.bonus-slider__wrap .swiper-pagination-bullet-active {
  background-color: var(--slider-bullet-active-color);
}

.bonus-slider__wrap--lk .swiper-pagination-bullets {
  transform: translateY(50%);
}

@media (max-width: 768px) {
  .bonus-slider__wrap--lk {
    padding-right: 52px;
    padding-left: -5px ;
  }

}

.border-wrap {
  padding: 30px 0 0;
  border-top: 1px solid var(--acc-border-color);
  margin-bottom: 20px;
}

.promo-form .main-form__bonus-wrap {
  margin-top: 8px;
}

@media only screen and (min-width: 768px) and (max-width: 900px) {
  .promo-form .main-form__wrap {
    padding-bottom: 0;
    padding-top: 10px;
  }
  .border-wrap .fund .btn {
    min-width: 0;
  }
}


@media only screen and (max-width: 600px) {
  .border-wrap {
    padding: 20px 15px 0;
    border-top: 1px solid var(--slider-bullet-active-color);
    margin-bottom: 20px;
  }
  .bonus-slider__wrap {
    padding: 0 0 35px 10px;
  }
  .bonus-slider__wrap--lk {
    padding-left: 0;
  }
}

/*.point {*/
/*  max-width: 510px;*/
/*  width: 100%;*/
/*  margin: 0 auto;*/
/*  background: var(--acc-border-color);*/
/*  border-radius: 5px;*/
/*  padding: 4px 4px 14px 4px;*/
/*}*/

/*.point__wrap {*/
/*  border-radius: 5px;*/
/*  background-color: #ffffff;*/
/*  padding: 23px 15px 15px;*/
/*  display: -webkit-box;*/
/*  display: flex;*/
/*  -webkit-box-align: center;*/
/*  align-items: center;*/
/*}*/

/*.point__img {*/
/*  max-width: 120px;*/
/*  width: 100%;*/
/*  height: 120px;*/
/*  margin-right: 18px;*/
/*}*/

/*.point__img img {*/
/*  width: 100%;*/
/*  height: 100%;*/
/*}*/

/*.point__head {*/
/*  display: -webkit-box;*/
/*  display: flex;*/
/*  -webkit-box-align: start;*/
/*  align-items: flex-start;*/
/*}*/

/*.point__info {*/
/*  display: -webkit-box;*/
/*  display: flex;*/
/*  -webkit-box-orient: vertical;*/
/*  -webkit-box-direction: normal;*/
/*  flex-direction: column;*/
/*}*/

/*@media (max-width: 600px) {*/
/*  .point__info {*/
/*    display: -webkit-box;*/
/*    display: flex;*/
/*    -webkit-box-orient: vertical;*/
/*    -webkit-box-direction: normal;*/
/*    flex-direction: column;*/
/*  }*/
/*}*/

@media (max-width: 400px) {
  .promocode__input::placeholder {
    font-size: 13px;
  }
}

@media (max-width: 360px) {
  .promocode__input::placeholder {
    font-size: 12px;
  }
}
