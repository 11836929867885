.accordion-title {
    margin: 50px 0 17px;
    font-weight: 700;
    font-size: 18px;
    line-height: 1.2;
    text-align: center;
    color: var(--title-color);
}

.accordion-title:first-of-type {
    margin-top: 0
}

.accordion.open .accordion__head:after {
    -webkit-transform: translateY(-50%) rotate(-180deg);
    -ms-transform: translateY(-50%) rotate(-180deg);
    transform: translateY(-50%) rotate(-180deg);
}

.accordion__head {
    cursor: pointer;
    font-weight: 700;
    font-size: 18px;
    line-height: 1;
    color: #ffffff;
    padding: 25px 0;
    position: relative;
    border-top: 1px solid rgba(255, 255, 255, 0.07);
}

.accordion__head:last-child {
    border-bottom: 1px solid rgba(255, 255, 255, 0.07);
}

.accordion__head:after {
    content: "";
    position: absolute;
    width: 13px;
    height: 8px;
    top: 50%;
    right: 17px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='7' viewBox='0 0 13 7' fill='none'%3E%3Cpath d='M11.3469 0L12.3287 0.984371L6.43826 6.98434L0.547852 0.984371L1.52959 0L6.43826 4.99998L11.3469 0Z' fill='white'/%3E%3C/svg%3E%0A");
}

.accordion__content {
    max-height: 0;
    overflow: hidden;
    -webkit-transition: max-height .3s ease;
    transition: max-height .3s ease;
}

.accordion__text {
    font-size: 14px;
    line-height: 1.7;
    color: var(--description-text-color);
    width: 90%;
    padding-bottom: 30px;
}

.accordion__text a {
    color: var(--title-color);
}

.accordion__text a:hover {
    text-decoration: underline;
}

@media only screen and (max-width: 1200px) {}

@media only screen and (max-width: 992px) {}

@media only screen and (max-width: 768px) {
    .accordion-title {
        text-align: left;
        font-size: 16px;
    }
    .article_violet,
    .article_violet>.article__wrap {
        background: none;
    }
    .accordion__head {
        padding: 21px 0;
        font-size: 16px;
    }
}

@media only screen and (max-width: 600px) {
    .accordion-title {
        text-align: left;
        font-size: 14px;
    }
    .accordion__head {
        padding: 16px 35px 16px 0;
        font-size: 14px;
    }
}
