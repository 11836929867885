.account-section {
  padding: 50px 0;
  background-color: var(--acc-section-bg);
}

.account-section .container {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  justify-content: center;
}

.game-section {
  padding: 10px 0 35px;
}

.game-section_padding {
  padding: 50px 0 260px;
}
.game-section .container {
  max-width: 1330px;
}

.info-section {
  padding: 50px 0 100px;
  background-color: var(--info-section-bg);
}

.info-section .container {
  max-width: 1330px;
}

.info-section .presents-content {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  padding-top: 50px;
  margin-bottom: -50px;
}

.info-section .presents-content .bonus-card {
  width: 20%;
  margin-bottom: 63px;
}

.lottery-section {
  padding: 50px 0;
}

.lottery-section .lottery {
  margin-bottom: 120px;
}

.lottery-section .lottery:last-child {
  margin-bottom: 0;
}

.game-section_padding {
  padding: 50px 0 260px;
}

.game-section .game-container {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  padding: 12px 0 0;
  margin: 0 -7px 25px;
}

.game-section .game-container .game {
  width: 16.66%;
  padding: 0 7px;
  margin-bottom: 14px;
}

.game-section .game-nav {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  margin-bottom: 53px;
}

@media only screen and (max-width: 992px) {
  .lottery-section {
    padding: 21px 0 50px;
  }
}
@media only screen and (max-width: 768px) {
  .section-title {
    font-size: 24px;
    margin: 0;
    margin-bottom: 29px;
  }
  .account-section {
    padding: 0;
  }
  .info-section {
    padding: 30px 0 50px;
  }
}

@media only screen and (max-width: 600px) {
  .section-title {
    font-size: 18px;
    margin: 0;
    margin-bottom: 29px;
  }
  .info-section {
    padding: 20px 0 50px;
  }
  .game-section {
    padding: 10px 0 16px;
  }
}
