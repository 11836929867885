.lottery-content {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding-top: 15px;
}

.lot {
  width: 100%;
  margin-bottom: 18px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}

.lottery-table__ticket {
  width: 40% !important;
}

.game-section.lottery-section {
  padding-top: 7px;
  padding-bottom: 25px;
}

.lottery-section .block-title__svg {
  width: 60px;
  height: 70px;
  margin-top: -14px;
  margin-bottom: -10px;
  margin-right: 4px;
  margin-left: -6px;
}

@media (max-width: 992px) {
  .lottery-section .block-title__svg {
    width: 54px;
    height: 51px;
    margin-right: 0;
  }
}
@media (max-width: 768px) {
  .lottery-section .block-title__svg {
    width: 50px;
    height: 46px;

    margin-top: -10px;
  }
}
@media (max-width: 600px) {
  .lottery-section .block-title__svg {
    width: 38px;
    height: 36px;
  }
  .lottery-section {
    padding-top: 7px;
    padding-bottom: 8px;
  }
}

.details-link {
  margin-top: 10px;
  padding-left: 6px;
  flex: 1 1 auto;
  display: flex;
  align-items: flex-end;
}

.details-link a {
  font-size: 16px;
}

.lottery-table__player {
  width: 50% !important;
}

.lottery-table__prize {
  width: 10% !important;
}

.tour-table__col.lottery-table__player,
.tour-table__col.lottery-table__ticket {
  color: white;
}

.tour-table__col.lottery-table__prize {
  font-weight: 600;
  color: white;
}

.lottery-winners__table .tour-table__head .tour-table__col {
  color: var(--description-text-color) !important;
}

.lot__wrap {
  position: relative;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 20px 10px;
  overflow: hidden;
  border-radius: 5px;
  flex: 1;
}

.lot__footer {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
}

.lot__content {
  padding: 0px 10px 6px;
  width: 52%;
  height: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  color: #ffffff;
  font-weight: 700;
  text-align: center;
  letter-spacing: 0.05em;
}

.lot__name {
  font-size: 24px;
  line-height: 1.2;
  margin-bottom: 10px;
}

.lot__price {
  font-size: 48px;
  line-height: 1;
  position: relative;
  width: 100%;
  margin-bottom: 6px;
}

.lot .counter {
  margin-bottom: 8px;
}

.lot .btn {
  min-width: 203px;
  font-size: 18px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 8px;
}

.lot__footer .btn {
  margin-bottom: 0;
}

.fund {
  max-width: 510px;
  width: 100%;
  margin: 0 auto;
}

.fund__title {
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  letter-spacing: 0.05em;
  margin-bottom: 10px;
  color: #ffffff;
}

.fund__wrap {
  height: 160px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.fund__info {
  padding: 25px 40px 30px 50px;
  width: 50%;
  border-right: 1px dashed #ffffff;
  height: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
}

.fund__prize-title {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0.05em;
  color: var(--title-color);
}

.fund__prize {
  font-weight: 700;
  font-size: 30px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #ffffff;
  margin-bottom: 5px;
  width: 100%;
}

.fund__ticket-title {
  font-size: 14px;
  line-height: 1.4;
  letter-spacing: 0.05em;
  color: #ffffff;
  max-width: 170px;
  width: 100%;
  margin-left: 5px;
}

.fund__count {
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0.05em;
  color: #ffffff;
  margin-left: 3px;
}

.fund__ticket-wrap {
  margin-top: 25px;
  padding: 0 13px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.fund__ticket-wrap .btn {
  min-width: 203px;
}

.fund__title-wrap {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.fund__title-wrap svg {
  width: 50px;
  height: 40px;
}

@keyframes load {
  from {
    left: -150px;
  }
  to {
    left: 100%;
  }
}

@media only screen and (max-width: 1200px) {
  .lot__price {
    font-size: 36px;
  }
}

@media only screen and (max-width: 992px) {
  .lot__name {
    font-size: 20px;
  }
  .lot__price {
    height: 45px;
    font-size: 50px;
  }
  .lot__content > .counter {
    margin: auto 0 0;
  }
  .lot__content {
    padding: 0px;
  }
  .lot__wrap {
    margin-bottom: 10px;
  }
  .lot__footer {
    min-height: 36px;
  }
}

@media only screen and (max-width: 920px) {
  .lot .btn {
    min-width: 165px;
    font-size: 12px;
    padding: 12px 20px;
  }
}

@media only screen and (max-width: 768px) {
  .lot__name {
    font-size: 14px;
  }
  .lot__price {
    font-size: 24px;
    height: 30px;
  }
  .details-link a {
    font-size: 12px;
  }
}

.lot.skeleton {
  background: #e2e7ee;
  overflow: hidden;
}

.lot.skeleton.loading::after {
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 1),
    transparent
  );
}

@media only screen and (max-width: 600px) {
  .lot.skeleton {
    height: 160px;
  }
  .fund__ticket-wrap .btn {
    margin: 0 auto;
    margin-bottom: 10px;
  }
  .lot__price {
    height: 20px;
  }
  .fund__info {
    border-right: none;
  }
  .fund__wrap {
    border-radius: 5px;
  }
  .lottery__btns {
    margin-bottom: 20px;
  }
  .lottery-table__ticket {
    width: 43% !important;
  }
  .lottery-table__player {
    width: 40% !important;
  }
  .lottery-table__prize {
    width: 17% !important;
  }
  .lot__footer {
    min-height: 0px;
  }
}

@media only screen and (max-width: 600px) {
  .grid-lot-item {
    width: 100% !important;
  }
  .lot.skeleton {
    height: 142px;
  }
  .fund__prize {
    font-size: 26px;
  }
}

@media only screen and (min-width: 601px) {
  .lot.skeleton {
    height: 160px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */

@media only screen and (min-width: 768px) {
  .lot.skeleton {
    height: 240px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */

@media only screen and (min-width: 992px) {
  .lot.skeleton {
    height: 340px;
  }
}

@media only screen and (max-width: 460px) {
  .fund__ticket-wrap {
    flex-wrap: wrap;
  }
  .fund__title-wrap {
    margin-bottom: 5px;
  }
  .fund__ticket-title {
    max-width: 100%;
  }
  .fund__info {
    padding-left: 5px;
    padding-right: 5px;
  }
}
