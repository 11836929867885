.bonus--article p {
  margin: 0;
  font-size: 18px;
  line-height: 1.56;
  margin-bottom: 25px;
  color: #58466f;
}

.bonus--article h2 {
  font-weight: 700;
  font-size: 30px;
  line-height: 1.6;
  text-align: center;
  text-transform: uppercase;
  color: #000000;
  padding-bottom: 25px;
  margin-bottom: 30px;
  border-bottom: 1px solid #e0dfe2;
}

.bonus--article tr:nth-child(2n),
.bonus--article .row:nth-child(2n) {
  background-color: #efeff0;
}

.bonus--article h3 {
  font-weight: 700;
  font-size: 18px;
  line-height: 1.56;
  color: #58466f;
  margin-bottom: 25px;
}

.bonus--article .bonus-page__list,
.bonus--article ul {
  margin-bottom: 25px;
}

.bonus--article li {
  list-style: initial;
}

.bonus--article ul li,
.bonus--article ol li,
.bonus--article .bonus-page__list p {
  margin: 0;
  font-size: 18px;
  line-height: 1.56;
  margin-bottom: 25px;
  color: #58466f;
  margin-bottom: 0;
}

.bonus--article table {
  width: 100%;
}

.bonus--article table,
.bonus--article .table {
  border-collapse: collapse;
  border: 2px solid #e0dfe2;
  border-radius: 5px;
  margin: 48px 0;
}

.bonus--article .btn {
  display: block;
  width: 318px;
  margin: 0 auto 25px;
  font-size: 24px;
  padding: 18px;
  line-height: 1;
  border-radius: 48px;
}

.bonus--article tr,
.bonus--article .row {
  border-bottom: 2px solid #e0dfe2;
  display: table-row;
}

.bonus--article tr.head,
.bonus--article .row.head {
  font-weight: 700;
}

.bonus--article td,
.bonus--article .col {
  padding: 20px;
  border-right: 2px solid #e0dfe2;
  font-size: 18px;
  color: #000000;
  text-align: center;
  display: table-cell;
}

.bonus--article td:last-child,
.bonus--article .col:last-child {
  border-right: none;
}

.bonus--article .row:last-child,
.bonus--article tbody tr:last-child {
  border-bottom: none;
}

@media only screen and (max-width: 1200px) {
  .bonus--article td,
  .bonus--article .col {
    padding: 17px 12px;
  }
}

@media only screen and (max-width: 992px) {
  .bonus--article p {
    margin-bottom: 20px;
  }
  .bonus--article td,
  .bonus--article .col {
    font-size: 16px;
    padding: 14px 8px;
  }
  .bonus--article .btn {
    width: 260px;
    padding: 15px;
  }
}


@media only screen and (max-width: 768px) {
  .bonus--article table,
  .bonus--article .table,
  .bonus--article tr,
  .bonus--article .row,
  .bonus--article td,
  .bonus--article .col {
    border-width: 1px;
  }
  /* .bonus--article tr,
  .bonus--article .row {
    display: flex;
    -webkit-box-align: center;
    justify-content: space-between;
    align-items: stretch;
  } */

  /* .bonus--article td,
  .bonus--article .col {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-basis: 0;
    flex-grow: 1;
    border-right: none;
  } */
  .bonus--article h2 {
    font-size: 24px;
    padding-bottom: 25px;
    margin-bottom: 30px;
  }
  .bonus--article h3 {
    font-size: 14px;
    margin-bottom: 15px;
  }
  .bonus--article ul li,
  .bonus--article ol li,
  .bonus--article p {
    font-size: 14px;
  }
  .bonus--article td,
  .bonus--article .col {
    font-size: 14px;
    padding: 14px 5px;
  }
  .bonus--article .btn {
    width: 190px;
    padding: 8px 8px;
    font-size: 20px;
  }
}

@media only screen and (max-width: 600px) {
  .bonus--article td,
  .bonus--article .col {
    font-size: 12px;
    padding: 8px 3px;
  }
  .bonus--article table,
  .bonus--article .table {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .bonus--article .btn {
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 460px) {
  .bonus--article h2 {
    font-size: 20px;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 400px) {
  .bonus--article td,
  .bonus--article .col {
    font-size: 10px;
    padding: 5px 2px;
  }
}

@media only screen and (max-width: 360px) {
  .bonus--article td,
  .bonus--article .col {
    font-size: 9px;
  }
}
