.main-form__method-wrap {
  display: grid;
  grid-column-gap: 5px;
  grid-row-gap: 20px;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  max-width: 415px;
  margin: 0 0 10px 0;
}

.promocode-row input {
  max-width: 150px;
}

.method__row {
  padding: 30px 15px 0;
  margin-bottom: -10px;
}

.postTerminalFrame {
  margin: 20px 0;
}


@media (min-width: 769px) {
  .account-section .acc-content__close-btn {
    display: none;
  }
}

@media (max-width: 360px) {
  .main-form__method-wrap {
    grid-template-columns: repeat(3, 1fr);
  }
}
