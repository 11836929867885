.account.mob .account-nav__list {
    padding-bottom: 80px;
}

.account-nav__list {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
}

.account-nav__item {
    width: calc(100% - 10px);
}

@media (max-width: 768px) {
    .account-nav__item {
        width: 100%;
    }
}

@media (max-width: 600px) {
  .promo-form .btn.primary {
    margin: 0 0 0 10px;
  }
}

.account-nav__link {
    border-top: 1px solid var(--primary-border-color);
    padding: 25px 77px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 700;
    color: #ffffff;
    width: 100%;
    position: relative;
}

.account.mob .account-nav__link {
    padding: 15px 16px 11px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 700;
    color: #ffffff;
    width: 100%;
    position: relative;
    display: block;
    cursor: pointer;
    border: none;
}

.account-nav__link:after {
    position: absolute;
    content: '';
    width: 100%;
    height: 1px;
    background: var(--acc-content-bg);
    bottom: -1px;
    left: 0;
    z-index: 4;
    opacity: 0;
}

.account-nav__link.active,
.account-nav__link:hover {
    z-index: 1;
    background: var(--acc-content-bg);
    color: var(--title-color);
    text-shadow: var(--link-text-shadow);
    border-color: var(--acc-content-bg);
}

.account-nav__link.active:after,
.account-nav__link:hover:after {
    opacity: 1;
}
