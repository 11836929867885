.loader-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(37,31,45,.8);

  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 1000;

}

.loader-text {
  font-size: 42px;
  color: #fff;
}


@media (max-width: 600px) {
  .loader-text {
    font-size: 28px;
  }
}

@media (max-width: 360px) {
  .loader-text {
    font-size: 24px;
  }
}
