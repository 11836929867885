.account {
    max-width: 320px;
    width: 100%;
    background: var(--acc-sidemenu-bg);
    border-radius: 5px;
    margin-top: 25px;
}

.account.mob {
    max-width: 100%;
    margin-top: 0;
}

.account.mob .account__user {
    max-width: 385px;
}

.acc-content__title {
    font-weight: 700;
    font-size: 14px;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    padding-bottom: 20px;
    border-bottom: 2px solid var(--acc-border-color);
}

.acc-content {
    background-color: var(--acc-content-bg);
    border-radius: 5px;
    max-width: 630px;
    width: 100%;
    position: relative;
    left: 0;
    padding: 22px 20px 0;
    min-height: 515px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.5);
}

.acc-content.mob {
    overflow: auto;
}

.acc-content__id {
    text-align: center;
    font-weight: 700;
    font-size: 12px;
    color: var(--title-color);
    display: block;
    margin: -20px 0 0 0;
}

.acc-content__bonus-wrap {
    padding: 20px 0;
    font-size: 16px;
    text-align: center;
    border-bottom: 2px solid var(--acc-border-color);
}

.acc-content__bonus-name {
    color: #fcfcfc;
}

.acc-content__bonus-percent {
    color: var(--title-color);
}

.acc-content__balance {
    padding: 22px 7px 10px;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: start;
    align-items: flex-start;
    border-bottom: 1px solid var(--primary-border-color);
    justify-content: space-between;
}

.account__user {
    padding: 11px 35px 10px 10px;
}

.acc-content.mob .acc-content__close-btn {
    position: absolute;
    right: 13px;
    display: block;
    top: 15px;
    z-index: 1;
}

.acc-content__close-btn svg {
    width: 14px;
    height: 14px;
}

.acc-content.mob>.acc-content__title {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    background-color: var(--acc-sidemenu-bg);
    position: relative;
    border-bottom: 1px solid var(--primary-border-color);
    padding: 6px 0 4px;
    height: 45px;
}

.acc-content.mob>div {
    max-width: 550px;
    margin: 0 auto;
}

.acc-content.mob {
    position: absolute;
    height: 100%;
    padding: 0 0 80px;
    top: 0;
    left: 0;
    max-width: 100%;
    z-index: 12;
}

.acc-content.mob .main-form__wrap {
    max-width: 550px;
    margin: 0 auto;
}

@media only screen and (min-width: 769px) and (max-width: 992px) {
    .acc-content {
        min-width: 62%;
    }
}

@media only screen and (min-width: 769px) and (max-width: 860px) {
    .account-section .container {
        padding: 10px;
    }
    .acc-content {
        flex: 1;
    }
    .account-nav__link {
        padding-right: 50px !important;
        padding-left: 50px !important;
    }
}

@media (max-width: 400px) {
    .acc-content__balance {
        display: flex;
        flex-wrap: wrap;
    }
    .acc-content__balance .balance {
        width: 50%;
        margin-bottom: 5px;
    }
}
