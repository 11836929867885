.main.bg {
  background-color: var(--main-bg2);
}
.main {
  overflow: hidden;
  flex: 1;
}
.wrap {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
