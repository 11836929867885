.deposit__email-wrap {
    display: flex;
    height: 100%;
    width: 100%;
    position: relative;
    align-items: center;
    justify-content: space-between;
}

.deposit__email-wrap .field {
    width: 70%;
    flex: 1 1 auto;
}

.deposit__email-wrap .btn {
    min-width: 150px;
    height: 100%;
    font-size: 14px;
    margin-left: 25px;
}

.payments-error__link {
    color: #ff3294;
    text-decoration: underline;
}

.deposit__email-code__wrap {
    display: flex;
    width: 100%;
}

.deposit__email-code .field {
    width: 120px;
}

.deposit__email-code input {
    background: #555555;
    border-color: #555555;
}

.deposit__email-wrap .field input[disabled] {
    cursor: default;
    background: none;
    border: none;
}

.deposit__email-code .btn {
    min-width: 140px;
    margin-left: 0;
    height: 100%;
    font-size: 14px;
    margin-left: 18px;
}

.deposit__email-wrap .disabled {
    margin-right: 0px;
}

.deposit__email-wrap .try-again__message {
    min-width: 150px;
    margin-left: 10px;
}

.deposit__email-code input::-webkit-input-placeholder {
    font-size: 14px;
    letter-spacing: -0.02em;
    color: #a4a4a4;
}

.deposit__email-code input:-ms-input-placeholder {
    font-size: 14px;
    letter-spacing: -0.02em;
    color: #a4a4a4;
}

.deposit__email-code input::-ms-input-placeholder {
    font-size: 14px;
    letter-spacing: -0.02em;
    color: #a4a4a4;
}

@media (min-width: 993px) {
    .deposit__email-wrap,
    .deposit__email-code__wrap {
        max-width: 415px;
    }
}

@media (max-width: 460px) {
    .deposit__email-wrap .btn {
        min-width: 120px;
        padding: 12px 0px;
        font-size: 12px;
    }
    .deposit__email-code__wrap .btn {
        min-width: 100px;
        padding: 12px 10px;
        font-size: 12px;
    }
}