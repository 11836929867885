.pay-method input[type='radio']:checked + label.pay-method__label {
  border-color: var(--title-color);
  background: var(--pay-method-bg-colorHover);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.5);
}

.pay-method
  input[type='radio']:checked
  + label.pay-method__label
  .pay-method__name {
  color: #a4a4a4;
}

.pay-method__input {
  display: none;
}


.full_card_info {
  color: white;
  max-width: 415px;
  flex-wrap: nowrap;
  display: flex;
  justify-content: space-between;
}

.full_card_info .field__text {
  display: none;
}

.full_card_info .exp {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.card-form__description .main-form__wrap {
  padding-top: 13px;
  padding-bottom: 13px;
}

.card-form__description {
  color: white;
}

.card-form__description .main-form__main-wrap {
  margin-bottom: 0;
}

.card-form__description .main-form__label {
  color: white;
}

.card-form .main-form__wrap {
  padding-top: 15px;
}

.card-form__description .main-form__main-wrap div {
  width: 100%;
  max-width: 415px;
}

.acc-content__back-button {
  position: absolute;
  left: 19px;
  top: 22px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.acc-content__back-button:hover svg {
  transform: translateX(-3px);
}

.acc-content__back-button svg {
  display: block;
  width: 8px;
  height: 14px;
  transition: .3s ease;
  margin-right: 10px;
  fill: white;
}

.acc-content__back-button span {
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: white;
}

@media (max-width: 768px) {
  .acc-content__back-button span {
    display: none;
  }
  .acc-content__back-button {
    top: 15px;
  }

}

@media only screen and (min-width: 768px) and (max-width: 900px) {
  .card-form__description .main-form__main-wrap {
    flex-direction: row;
  }
  .card-form__description-sum {
    display: flex;
    justify-content: flex-end;
  }
  .full_card_info {
    max-width: none;
  }
  .cashbox-mainform .btn {
    margin-left: 0;
  }
}
@media only screen and (max-width: 600px) {
  .card-form__description .main-form__main-wrap {
    flex-direction: row;
  }
  .card-form__description-sum {
    display: flex;
    justify-content: flex-end;
  }
  .full_card_info {
    max-width: none;
  }
  .cashbox-mainform .btn {
    width: 100%;
  }
  .payments__imgs img {
    margin-right: 0;
  }
  .payments__imgs {
    justify-content: space-around;
  }
}

.card-form__description-sum {
  font-size: 24px;
  font-weight: 700;
}

.payments__imgs {
  margin-top: 15px;
  display: flex;
  padding-left: 10px;
  width: 100%;
}
.payments__imgs img {
  margin-right: 20px;
}

.full_card_info .exp span {
  margin: 0 7px;
}

.full_card_info .cvv {
  width: 50%;
  margin-right: 10px;
}

/* .full_card_info .cvv input {
  font-family: HiddenText;
} */

.full_card_info .cvv-icon {
  width: 40px;
  height: 40px;
}

.full_card_info .field input {
  padding-right: 10px;
}

.pay-method__label {
  border: 1px solid var(--pay-method-border-color);
  padding: 0 3px;
  cursor: pointer;
  border-radius: 5px;
  width: 73px;
  height: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.pay-method__label:hover {
  border-color: var(--title-color);
  background: var(--pay-method-bg-colorHover);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.5);
}

.pay-method__label:hover .pay-method__name {
  color: var(--pay-method-name-colorChecked);
}

.pay-method__img {
  -webkit-box-flex: 0;
  flex: none;
  width: 100%;
  height: 40px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}

.pay-method__img img {
  object-fit: contain;
  z-index: 1;
  width: 100%;
  height: 100%;
  padding: 3px 0;
}

.pay-method__icon_visa {
  width: 58px;
  height: 18px;
}

.pay-method__icon_master {
  width: 35px;
  height: 27px;
}

.pay-method__icon_megafon {
  width: 29px;
  height: 29px;
}

.pay-method__icon_oxxo {
  width: 52px;
  height: 25px;
}

.pay-method__icon_qiwi {
  width: 29px;
  height: 31px;
}

.pay-method__icon_yandex {
  width: 22px;
  height: 28px;
}

.pay-method__icon_beeline {
  width: 27px;
  height: 27px;
}

.pay-method__name {
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-size: 10px;
  padding-top: 5px;
  text-align: center;
  color: var(--pay-method-name-color);
  position: relative;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.bonus-info {
  margin-left: 145px;
  margin-bottom: 20px;
  color: #fff;
  line-height: 24px;
  text-align: center;
}

@media (max-width: 600px) {
  .bonus-info {
    margin-left: 0;
  }
}

@media (min-width: 901px) and (max-width: 960px) {
  .quick-btns .main-form__sum-wrap {
    justify-content: space-between;
    flex-wrap: nowrap;
  }
  .btn.deposit {
    width: 33%;
  }
  .quick-btns {
    padding-left: 135px;
  }
}
@media (min-width: 601px) and (max-width: 768px) {
  .quick-btns .main-form__sum-wrap {
    justify-content: space-between;
    flex-wrap: nowrap;
  }
  .btn.deposit {
    width: 33%;
  }
  .quick-btns {
    padding-left: 135px;
  }
}
@media (max-width: 600px) {
  .quick-btns .main-form__sum-wrap {
    justify-content: space-between;
    flex-wrap: nowrap;
  }
  .btn.deposit {
    width: 33%;
    margin-right: 5px;
  }
  .quick-btns {
    padding-left: 0px;
  }
}
@media (max-width: 600px) {
  .btn.deposit {
    padding-left: 15px;
  }
}

.pay-method__name:before {
  position: absolute;
  content: ' ';
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: -webkit-radial-gradient(
    50% 50%,
    50% 50%,
    rgba(255, 255, 255, 0.25) 63.54%,
    rgba(0, 0, 0, 0) 100%
  );
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(255, 255, 255, 0.25) 63.54%,
    rgba(0, 0, 0, 0) 100%
  );
}

.pay-method input[type='radio']:checked + label.pay-method__label {
  border-color: var(--title-color);
  background: var(--pay-method-bg-colorHover);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.5);
}

.pay-method
  input[type='radio']:checked
  + label.pay-method__label
  .pay-method__name {
  color: var(--pay-method-name-colorChecked);
}

@media (min-width: 769px) {
  .deposit-canvas {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-height: 80vh;
  }
  .off-canvas .acc-content {
    margin: 0 auto;
    width: 630px;
    overflow: auto;
    position: relative;
    padding-bottom: 60px;
  }

  .off-canvas .acc-content::-webkit-scrollbar {
    width: 20px;
  }

  .off-canvas .acc-content::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 10px var(--acc-border-color);
    border: solid 7px transparent;
    border-radius: 15px;
  }

  .off-canvas .acc-content::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px 10px #424242;
    border: solid 7px transparent;
    border-radius: 15px;
  }


  .off-canvas .acc-content .acc-content__close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
  }
}
