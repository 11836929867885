.footer-nav {
    padding: 25px 0;
    border-bottom: 1px solid var(--footer-text-border-color);
    border-top: 1px solid var(--footer-text-border-color);
}

.footer-nav__list {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  flex-wrap: wrap;
}

.footer-nav__item {
  position: relative;
  margin: 0 16px;
}

.method__pay-icon {
  max-width: 65px;
}

.footer-nav__item:after {
  content: '';
  position: absolute;
  top: 50%;
  right: -16px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 1px;
  height: 12px;
  background: var(--footer-navlink-color);
}

.footer-nav__item:last-child:after {
  display: none;
}

.footer-nav__link {
  font-size: 14px;
  line-height: 1.7;
  text-transform: uppercase;
  color: var(--footer-navlink-color);
}

.footer-nav__link:hover {
  color: #ffffff;
}

.footer {
  position: relative;
  padding: 50px 0 22px;
  background-image: var(--footer-bg);
  overflow: hidden;
}

.footer .bg-img {
  position: absolute;
  top: 0;
  width: 100vw;
  left: 0;
  right: 0;
}

.footer .container {
  max-width: 962px;
}

.footer__text {
  color: var(--footer-text-color);
  font-size: 11px;
  line-height: 2.7;
  text-align: center;
  letter-spacing: 0.02em;
  padding: 29px 8px;
  border-bottom: 1px solid var(--footer-text-border-color);
  border-top: 1px solid var(--footer-text-border-color);
  margin-bottom: 25px;
}

.footer__contacts-text {
  font-size: 14px;
  line-height: 1.7;
  text-align: center;
  letter-spacing: 0.02em;
  color: var(--footer-contacts-color);
}

.footer__contacts {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: stretch;
  -webkit-box-pack: center;
  justify-content: center;
  margin-bottom: 32px;
}

.footer__copyright-wrap {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  margin-top: 20px;
}

.footer__copyright-wrap .footer__contacts-text {
}

.footer__copyright-wrap svg {
  width: 27px;
  height: 30px;
  margin-right: 14px;
}

.footer__copyright-icons-wrap svg {
  margin-right: 14px;
}
.footer__copyright-icons-wrap svg:last-child {
  margin-right: 0px;
}

.footer__copyright-icons-wrap {
  display: flex;
  justify-content: center;
  margin-top: 3px;
}

.old-icon {
  width: 27px;
  height: 30px;
}

.gamcare-icon {
  width: 27px;
  height: 30px;
}

.aware-icon {
  width: 146px;
  height: 30px;
}

.footer__copyright-wrap svg:last-child {
  width: 146px;
  margin-right: 0;
}

.partners {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 60px 0 10px;
  margin: 0 -15px;
}

.partners__item {
  width: 12.5%;
  padding: 0 15px;
  margin: 0 0 55px;
}

.partners__link {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.partners svg {
  width: 100%;
  height: 45px;
}

.method {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 35px 10px;
  -webkit-box-align: center;
  align-items: center;
}

.method__method {
  padding: 0 15px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.method svg {
  width: 100%;
  height: 45px;
}

.contact-links {
  display: flex;
}

.contact-link {
  font-weight: 700;
  line-height: 1.2;
  color: var(--footer-contact-link-color);
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-right: 30px;
}

.contact-link:hover {
  color: var(--footer-contact-link-colorHover);
}

.contact-link svg {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}

.soc-wrap {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.soc-wrap li {
  margin-right: 16px;
}

.soc-wrap li:last-child {
  margin-right: 0;
}

.soc-wrap a:hover {
  -webkit-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  transform: translateY(-5px);
}

.soc-wrap svg {
  width: 32px;
  height: 32px;
}

@media only screen and (max-width: 1200px) {
  .footer .bg-img {
    position: absolute;
    top: 0;
    width: 200%;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}

@media only screen and (max-width: 992px) {
  .footer-nav {
    padding: 16px 0;
  }
  .footer-nav__list {
    flex-wrap: wrap;
    margin: 0 -10px;
  }
  .footer-nav__item {
    margin: 0px 8px 4px 8px;
  }
  .footer-nav__item:after {
    right: -9px;
  }
  .footer__text {
    padding: 12px 8px;
  }
  .partners__item {
    margin-bottom: 20px;
  }
  .partners {
    padding-top: 40px;
  }
  .footer .bg-img {
    position: absolute;
    top: 0;
    width: 280%;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}

@media only screen and (max-width: 768px) {
  .footer-nav__link {
    font-size: 10px;
    line-height: 1.7;
  }
  .method {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 20px 0 0;
    -webkit-box-align: center;
    align-items: center;
  }
  .method svg {
    height: 30px;
  }
  .method__item {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    width: 17%;
    padding: 0 5px;
    margin-bottom: 22px;
  }
  .method__pay-icon {
    max-height: 45px;
  }
  .validator-link {
    justify-content: center;
  }
  .partners {
    padding-top: 0px;
  }
  .partners__item {
    width: 12%;
    padding: 0 10px;
  }
  .footer__contacts {
    flex-wrap: wrap;
    margin-bottom: 15px;
  }
  .contact-links {
    width: 100%;
    justify-content: center;
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 600px) {
  .partners__item {
    width: 12%;
    margin-bottom: 20px;
  }
  .partners {
    padding-top: 10px;
  }
  .footer .bg-img {
    width: 280%;
  }
  .footer__text {
    padding: 12px 8px;
  }
}

@media only screen and (max-width: 500px) {
  .contact-links {
    width: 100%;
    font-size: 12px;
    justify-content: center;
    margin-bottom: 15px;
  }
  .contact-link:nth-child(2) {
    margin-right: 0px;
  }
  .contact-link svg {
    height: 14px;
    width: 12px;
    margin-right: 10px;
  }
  .footer__copyright-wrap {
    flex-wrap: wrap;
  }
  .partners__item {
    margin: 0;
    width: 25%;
  }
  .footer__copyright-wrap>.footer__contacts-text {
    width: 100%;
    font-size: 12px;
    margin-bottom: 15px;
    margin-right: 0;
  }
  .footer__copyright-wrap>svg {
    width: 20px;
    height: 20px;
    margin-right: 14px;
  }
}

@media only screen and (max-width: 360px) {
  .contact-link:first-child {
    margin-right: 10px;
  }
  .contact-link svg {
    margin-right: 8px;
  }
}
