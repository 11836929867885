.counter {
    border-radius: 7px;
    background-color: var(--counter-bg);
    padding: 2px 2px 5px;
    margin-bottom: 20px;
}

.counter_final .counter__wrap {
    background-color: var(--counter-finish-wrap-bg);
}

.counter__descr--final {
    font-weight: 900;
    font-size: 14px;
    line-height: 0.9;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    position: absolute;
    top: 8px;
    left: 10px;
    color: #ffffff;
}

.counter__wrap {
    border-radius: 5px;
    padding: 5px 8px 2px;
    background-color: var(--counter-wrap-bg);
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.counter__img {
    margin-right: 12px;
}

.counter__img svg {
    width: 28px;
    height: 28px;
}

.counter__date {
    display: -webkit-box;
    display: flex;
    position: relative;
}

.counter__col {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    margin: 0 6px 0 0;
}

.counter__col:nth-child(1) {
    margin-right: 15px;
}

.counter__col:nth-child(2) .counter__count:before,
.counter__col:nth-child(3) .counter__count:before {
    content: ':';
    position: absolute;
    top: -1px;
    right: -6px;
    font-weight: 900;
    font-size: 18px;
    color: var(--counter-text-color);
}

.counter__col:last-child {
    margin-right: 0;
}

.counter__text {
    font-size: 8px;
    text-transform: uppercase;
    color: var(--counter-text-color);
    margin-bottom: 5px;
}

.counter__count {
    font-weight: 900;
    font-size: 18px;
    min-width: 22px;
    height: 18px;
    line-height: 0.75;
    text-align: center;
    letter-spacing: 0.02em;
    color: var(--counter-text-color);
    position: relative;
}

.counter__descr {
    color: var(--counter-text-color);
    font-weight: 900;
    font-size: 14px;
    line-height: 0.9;
    letter-spacing: 0.02em;
    text-transform: uppercase;
}

.counter__text-big {
    font-size: 13px;
}

.counter_final .counter__count {
    opacity: 0;
    cursor: default;
}

.counter_final .counter__text {
    opacity: 0;
}

.counter_final .counter__col {
    user-select: none;
}

@media (max-width: 768px) {
    .counter {
        padding: 1px 1px 3px;
        border-radius: 5px;
    }
    .counter__wrap {
        padding: 3px 5px 3px;
    }
    .counter__descr--final {
        top: 8px;
        left: 7px;
        font-size: 12px;
    }
    .counter__img {
        margin-right: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .counter__img svg {
        width: 19px;
        height: 19px;
    }
    .counter__col:nth-child(1) {
        margin-right: 10px;
    }
    .counter__col:nth-child(2) .counter__count:before,
    .counter__col:nth-child(3) .counter__count:before {
        right: -5px;
        font-weight: 900;
        font-size: 13px;
    }
    .counter__count {
        font-size: 13px;
        min-width: 15px;
        height: 10px;
    }
}

@media (max-width: 600px) {
    .counter__col {
        margin-right: 4px;
    }
}
