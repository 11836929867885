.frame {
  background-color: #060504;
  padding: 2px 0 20px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.frame__header {
  padding: 3px 0;
  margin-bottom: 19px;
  background-image: var(--game-page-header-bg);
  box-shadow: var(--game-page-header-shadow);
}

.frame__header .frame__container {
  -webkit-box-align: center;
  align-items: center;
  padding: 0 30px;
}

.frame__container {
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
  position: relative;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  flex: 1;
}

.frame__list {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.frame__item {
  margin-right: 41px;
}

.frame__item:last-child {
  margin-right: 0;
}

.frame__link {
  font-size: 14px;
  line-height: 0.8;
  letter-spacing: 0.02em;
  color: var(--title-color);
  border-bottom: 1px solid var(--title-color);
}

.frame__link:hover {
  border-bottom: 1px solid transparent;
}

.frame__name {
  font-size: 24px;
  line-height: 1.2;
  color: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.frame__nav {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin: 0 -10px 0 0;
}

.frame__btn_full {
  margin-right: 12px;
}

.frame__btn_full svg {
  width: 23px;
  height: 22px;
}

.frame__btn_close svg {
  width: 38px;
  height: 38px;
}

.frame__sidebar {
  width: 240px;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

.frame__sidebar-block {
  width: 100%;
  height: 100%;
  background-color: var(--game-page-sidebar-bg);
  padding: 17px 10px;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
}

.frame__sidebar-block_small {
  margin-bottom: 10px;
  height: 70px;
  -webkit-box-flex: 0;
  flex: none;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}

.frame__sidebar-block_shadow {
  position: relative;
  padding-bottom: 0;
}

.frame__logo img {
  width: 100%;
  margin-bottom: 33px;
}

.frame__img {
  margin: 37px 0 14px;
}

.frame__img img {
  width: 100%;
}

.frame__title {
  font-weight: 700;
  font-size: 18px;
  line-height: 1.2;
  text-align: center;
  text-transform: uppercase;
  color: var(--title-color);
  text-shadow: var(--link-text-shadow);
  margin: 3px 0 20px;
}

.frame__scroll-wrap {
  position: relative;

  height: 100%;
  width: calc(100% + 20px);
}

.frame-scroll-wrap__inner {
  width: 100%;
  padding: 0 10px;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: start;
  align-items: flex-start;

  overflow: hidden;
  overflow-y: auto;

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.frame-scroll-wrap__inner::-webkit-scrollbar {
  width: 6px;
  background-color: var(--game-page-scrollbar-bg);
}

.frame-scroll-wrap__inner::-webkit-scrollbar-thumb {
  background-color: var(--game-page-scrollbar-thumb-bg);
  border-radius: 5px;
}

.frame-scroll-wrap__inner .game {
  margin-bottom: 10px;
}

.frame__user-wrap {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: start;
  align-items: flex-start;
  margin: 0 0 0 -20px;
}

.frame__user-wrap .user-info {
  margin-bottom: 15px;
}

.frame__user-wrap .balance {
  margin-bottom: 15px;
}

/*.frame__lk-wrap {*/
/*  display: -webkit-box;*/
/*  display: flex;*/
/*  -webkit-box-align: center;*/
/*  align-items: center;*/
/*  margin: 23px -17px;*/
/*  border-top: 1px solid rgba(255, 255, 255, 0.2);*/
/*  padding: 10px 21px 0;*/
/*}*/

/*.frame__lk-wrap .link {*/
/*  font-size: 14px;*/
/*}*/

/*.frame__lk-wrap .link:after {*/
/*  display: none;*/
/*}*/

.frame__lk-img {
  width: 40px;
  height: 40px;
}

@media (max-width: 1200px) {
  .frame__sidebar {
    display: none;
  }

  .frame__frame {
    width: 100% !important;
  }
}
