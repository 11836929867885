.burger {
  -webkit-box-flex: 0;
  flex: none;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  margin-right: 16px;
  border-radius: 50%;
  background-color: var(--burger-bg);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  cursor: pointer;

  position: relative;
}

.burger.active {
  background-color: var(--burger-bgActive);
}

.burger.active .burger__line:nth-child(1) {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.burger.active .burger__line:nth-child(2) {
  opacity: 0;
}

.burger.active .burger__line:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.burger:hover {
  background-color: var(--burger-bgHover);
}

.burger__wrap {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: start;
  align-items: flex-start;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.burger__line {
  background-color: #ffffff;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border-radius: 5px;
  position: relative;
}

.burger-menu {
  position: absolute;
  z-index: -1;
  visibility: hidden;
  opacity: 0;
  box-shadow: var(--burger-menu-shadow);
  background-color: var(--burger-menu-bg);
  border-radius: 5px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.burger-menu__list {
  display: -webkit-box;
  display: flex;
}

.burger-menu__item {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.burger-menu__link-help {
  font-weight: 700;
  font-size: 10px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
  border: 1px solid var(--title-color);
  border-radius: 18px;
  padding: 7px 29px 5px;
}

.burger-menu__link-help:hover {
  color: var(--acc-content-bg);
  background-color: var(--title-color);
}

.burger-menu__link:hover,
.burger-menu__link.active {
  color: var(--burger-link-colorHover);
  text-shadow: var(--link-text-shadow);
}

@media only screen and (min-width: 601px) {
  .burger {
    width: 54px;
    height: 54px;
  }
  .burger.active .burger__line:nth-child(1) {
    width: 24px;
    top: 7px;
  }

  .burger.active .burger__line:nth-child(3) {
    width: 24px;
    bottom: 7px;
  }

  .burger__wrap {
    width: 24px;
  }

  .burger__line {
    height: 3px;
  }

  .burger__line:nth-child(1) {
    width: 15px;
    margin-bottom: 4px;
  }

  .burger__line:nth-child(2) {
    width: 24px;
  }

  .burger__line:nth-child(3) {
    width: 19.5px;
    margin-top: 4px;
  }
}

.burger-menu__img {
  fill: var(--burger-icon-color);
}

@media only screen and (min-width: 769px) {
  .burger-menu {
    max-width: 468px;
    top: 40px;
  }

  .burger-menu.active {
    top: 60px;
    z-index: 2;
    visibility: visible;
    opacity: 1;
  }

  .burger-menu:before {
    content: '';
    position: absolute;
    left: 6px;
    top: -32px;
    z-index: -1;
    border: 20px solid transparent;
    border-bottom: 20px solid var(--burger-menu-bg);
  }

  .burger-menu__wrap {
    padding: 23px;
  }

  .with-nav > .burger-menu__list {
    margin-bottom: 24px;
    border-bottom: 1px solid var(--burger-border-color);
  }

  .burger-menu__list {
    flex-wrap: wrap;
  }

  .burger-menu__item {
    margin-bottom: 20px;
    width: 50%;
  }

  .burger-menu__img {
    width: 40px;
    height: 28px;
    margin-right: 20px;
  }

  .burger-menu__link {
    font-weight: 700;
    font-size: 15px;
    line-height: 1.2;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: var(--burger-link-color);
  }

  .burger-menu__nav {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }
}

@media only screen and (max-width: 768px) {
  .burger-menu {
    width: 256px;
    left: 0;
    top: 40px;
  }

  .burger-menu.active {
    top: 60px;
    z-index: 2;
    visibility: visible;
    opacity: 1;
  }

  .burger-menu__wrap {
    padding: 16px 18px 15px;
  }

  .burger-menu__list {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    border-bottom: 1px solid var(--burger-border-color);
    margin-bottom: 15px;
  }

  .burger-menu__item {
    margin-bottom: 12px;
  }

  .burger-menu__item:first-child {
    margin-bottom: 8px;
  }

  .burger-menu__item:first-child svg {
    width: 30px;
    height: 25px;
    margin: 0 8px 0 -2px;
  }

  .burger-menu__img {
    width: 24px;
    height: 20px;
    margin-right: 12px;
  }

  .burger-menu__link {
    font-weight: 700;
    font-size: 13px;
    line-height: 1.2;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: var(--burger-link-color);
    white-space: nowrap;
  }

  .burger-menu__nav {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: start;
    align-items: flex-start;
  }

  .burger-menu__nav select {
    margin-bottom: 18px;
  }

  .burger-menu__link-help {
    margin-bottom: 21px;
  }

  .burger-menu .nav {
    padding-bottom: 10px;
    margin-bottom: 16px;
    border-bottom: 1px solid var(--burger-border-color);
  }

  .burger-menu .nav__list {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: start;
    align-items: flex-start;
  }

  .burger-menu .nav__item {
    margin: 0 0 5px;
  }

  .burger-menu .nav svg {
    margin-right: 9px;
  }

  .burger-menu .main-form {
    margin-bottom: 15px;
  }
  .burger-menu:before {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .burger {
    width: 31px;
    height: 31px;
  }

  .burger.active .burger__line:nth-child(1) {
    width: 14px;
    top: 4px;
  }

  .burger.active .burger__line:nth-child(3) {
    width: 14px;
    bottom: 4px;
  }

  .burger__wrap {
    width: 14px;
  }

  .burger__line {
    height: 1.5px;
  }

  .burger__line:nth-child(1) {
    width: 9px;
    margin-bottom: 2.5px;
  }

  .burger__line:nth-child(2) {
    width: 14px;
  }

  .burger__line:nth-child(3) {
    width: 11px;
    margin-top: 2.5px;
  }
  .burger-menu.active {
    top: 40px;
    z-index: 2;
    visibility: visible;
    opacity: 1;
  }
}
