.btn {
  background-color: transparent;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  outline: none;
  -webkit-appearance: none;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  letter-spacing: 0.02em;
  color: var(--btn-color);
  border-radius: 24px;
  padding: 14px 30px 12px;
  line-height: 1;
  cursor: pointer;
  border: none;
}

.btn:hover {
  background: var(--btn-primary-bgColor);
  text-shadow: var(--btn-primaryHover-text-shadow);
}

.btn.primary {
  background: var(--btn-primary-bgColor);
  text-shadow: var(--btn-primaryHover-text-shadow);
}

.btn.primary:hover {
  background: var(--btn-primaryHover-bgColor);
}

.btn.secondary {
  color: var(--acc-content-bg);
  background-color: var(--btn-secondary-bgColor);
  box-shadow: var(--btn-secondary-shadow);
}

.btn.secondary:hover {
  color: var(--btn-secondaryHover-color);
  background-color: var(--btn-secondaryHover-bgColor);
  box-shadow: none;
  text-shadow: none;
}

.btn.tertiary {
  background: var(--btn-tertiary-bgColor);
  color: var(--title-color);
  text-shadow: var(--btn-primaryHover-text-shadow);
  border: 2px solid var(--title-color);
}

.btn.tertiary:hover {
  color: #fff;
  background: var(--btn-tertiaryHover-bgColor);
  text-shadow: var(--btn-primaryHover-text-shadow);
  box-shadow: var(--btn-secondary-shadow);
  border-color: var(--btn-tertiaryHover-bgColor);
}

.btn.login {
  color: var(--title-color);
  text-shadow: var(--btn-primaryHover-text-shadow);
  border: 2px solid var(--title-color);
}



.btn.deposit {
  font-weight: 700;
  font-size: 14px;
  color: #ffffff;
  background: var(--btn-deposit-bg);
  border-radius: 24px;
  padding: 12px 25px;
  margin: 0 12px 5px 0;
  cursor: pointer;
  box-shadow: var(--btn-deposit-box-shadow);
}

.btn.disabled {
  min-width: 112px;
  font-size: 13px;
  color: #ffffff;
  background: var(--btn-disabled-bgColor);
  border-radius: 24px;
  letter-spacing: 0.02em;
  padding: 13px 10px;
  margin-right: 20px;
  cursor: not-allowed;
}


.btn.disabled:hover {
  background: var(--btn-disabled-bgColor);
}

.btn.deposit:last-child {
  margin-right: 0;
}

.btn.deposit:hover {
  box-shadow: none;
}

@media only screen and (max-width: 600px) {
  .btn {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    letter-spacing: 0.02em;
    border-radius: 24px;
    padding: 14px 30px 12px;
    line-height: 1;
  }

  .btn:hover {
    background: var(--btn-primaryHover-bgColor);
    text-shadow: var(--btn-primaryHover-text-shadow);
  }

  .btn.primary {
    background: var(--btn-primary-bgColor);
    text-shadow: var(--btn-primaryHover-text-shadow);
  }

  .btn.primary:hover {
    background: var(--btn-primaryHover-bgColor);
  }

  .btn.secondary {
    color: var(--acc-content-bg);
    background-color: var(--btn-secondary-bgColor);
    box-shadow: var(--btn-secondary-shadow);
  }

  .btn.secondary:hover {
    background-color: var(--btn-secondaryHover-bgColor);
    box-shadow: none;
    text-shadow: none;
  }

  .btn.tertiary {
    background: var(--btn-tertiary-bgColor);
  }

  .btn.tertiary:hover {
    background: var(--btn-tertiaryHover-bgColor);
    text-shadow: var(--btn-primaryHover-text-shadow);
    box-shadow: var(--btn-secondary-shadow);
  }

  .btn.login {
    color: var(--title-color);
    text-shadow: var(--btn-primaryHover-text-shadow);
    border: 2px solid var(--title-color);
  }

  .btn.disabled {
    min-width: 112px;
    font-size: 13px;
    color: #ffffff;
    background: var(--btn-disabled-bgColor);
    border-radius: 24px;
    letter-spacing: 0.02em;
    padding: 13px 10px;
    margin-right: 20px;
    cursor: not-allowed;
  }
  .btn.deposit {
    font-weight: 700;
    font-size: 14px;
    color: #ffffff;
    background: var(--btn-deposit-bg);
    border-radius: 24px;
    padding: 14px 20px 12px;
    margin: 0 12px 5px 0;
    cursor: pointer;
    box-shadow: var(--btn-deposit-box-shadow);
  }

  .btn.deposit:last-child {
    margin-right: 0;
  }

  .btn.deposit:hover {
    box-shadow: none;
  }
}
