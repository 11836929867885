.tour-table {
    background-color: var(--acc-sidemenu-bg);
    border-radius: 5px;
}

.tour-table__body {
    height: 172px;
    margin: 0px -14px;
    padding: 0 14px;
    display: flex;
    flex-direction: column;
}

.game-section .tour-table__body {
    padding: 0 20px;
}

.tour-table__title {
    font-size: 18px;
    margin-bottom: 15px;
}

.tour-table__row {
    padding: 13px 16px;
    font-size: 14px;
    display: flex;
}

.tour-table__wrap {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 30px 20px 28px;
}

.tour-table__title {
    font-weight: 700;
    font-size: 30px;
    text-align: center;
    color: var(--title-color);
    text-shadow: var(--link-text-shadow);
    margin-bottom: 23px;
}

.tour-table__body {
    overflow: hidden;
    overflow-y: auto;
    margin: 0 -20px;
    padding: 0 48px;
    height: 320px;
}

.tour-table__body::-webkit-scrollbar {
    width: 6px;
    background-color: var(--tournament-table-scrollbar-bg);
}

.tour-table__body::-webkit-scrollbar-thumb {
    background-color: var(--tournament-table-scrollbar-color);
    border-radius: 5px;
}

.tour-table__row {
    display: -webkit-box;
    display: flex;
    font-size: 18px;
    padding: 22px 10px 23px 20px;
    color: var(--description-text-color);
    border-bottom: 1px solid var(--primary-border-color);
}

.tour-table__head {
    font-weight: 700;
    padding-bottom: 7px;
}

.tour-table__head .tour-table__gamer,
.tour-table__head .tour-table__prize {
    color: var(--description-text-color);
}

.tour-table__number {
    width: 40% !important;
    font-weight: 700;
}

.tour-table__gamer {
    color: #ffffff;
    font-size: 16px;
    width: 40% !important;
}

.tournament-content .tour-table__gamer {
    width: 35% !important;
}

.tour-table__prize {
    font-weight: 700;
    width: 20%;
}

.tour-table__footer {
    display: -webkit-box;
    display: flex;
    align-items: flex-end;
    -webkit-box-pack: center;
    justify-content: center;
    flex: 1 1 auto;
    margin-top: 27px;
    margin-bottom: auto;
}

@media (max-width: 600px) {
    .tour-table__body {
        height: 260px;
        padding: 0 14px;
    }
}

@media (max-width: 500px) {
    .tour-table__body {
        height: 210px;
    }
}
