.hall-nav {
  margin-top: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;
  z-index: 1000;
}

.hall-slider-wrap {
  width: 85%;
  margin: 0 auto;
  position: relative;
}

.hall-slider-mob .nav__link.active {
  position: relative;
  overflow: visible;
}

.play-btn.disabled {
  margin-right: 0 !important;
}

.hall-slider-mob .embla__button__svg {
  fill: var(--hall-slider-btn-color);
}

.hall-slider-wrap:after {
  content: '';
  position: absolute;
  right: -10px;
  top: 50%;
  height: 40%;
  width: 25%;

  transform: translateY(-50%);

  pointer-events: none;

  background: -moz-linear-gradient(
    90deg,
    transparent,
    rgba(37, 19, 59, 1) 77%
  );

  background: -webkit-linear-gradient(
    90deg,
    transparent,
    rgba(37, 19, 59, 1) 77%
  );

  background: linear-gradient(
    90deg,
    transparent,
    rgba(37, 19, 59, 1) 77%
  );
  z-index: 4;

}
.hall-slider-wrap:before {
  content: '';
  position: absolute;
  left: -10px;
  top: 50%;
  height: 40%;
  width: 25%;

  transform: translateY(-50%);

  pointer-events: none;
  background: -moz-linear-gradient(
    270deg,
    transparent,
    rgba(37, 19, 59, 1) 77%
  );
  background: -webkit-linear-gradient(
    270deg,
    transparent,
    rgba(37, 19, 59, 1) 77%
  );
  background: linear-gradient(
    270deg,
    transparent,
    rgba(37, 19, 59, 1) 77%
  );
  z-index: 4;
}

.hall-slider-wrap.cant-scroll-next:after {
  content: none;
}
.hall-slider-wrap.cant-scroll-prev:before {
  content: none;
}

.hall-nav .hall-select {
  width: 100%;
  margin-right: 10px;
}

.hall-slider-mob {
  margin-top: -8px;
  margin-bottom: -25px;
  /* margin: 30px 0 10px; */
}

.hall-slider-mob .embla__button {
  margin-top: -3px;
}

.hall-slider-mob .embla__button .embla__button__svg {
  width: 10px;
  height: 15px;
}

.hall-slider-mob .link-box {
  height: 80px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.hall-slider-mob .nav__link {
  padding: 0 13px;
  white-space: nowrap;
}

.hall-slider-mob .nav__link.active {
  text-shadow: 0px 0px 15px var(--hall-slider-text-shadow-color),
    0px 0px 15px var(--hall-slider-text-shadow-color), 0px 0px 15px var(--hall-slider-text-shadow-color),
    0px 20px 130px var(--hall-slider-text-shadow-color), 0px 20px 130px var(--hall-slider-text-shadow-color),
    0px -20px 130px var(--hall-slider-text-shadow-color), 0px -20px 130px var(--hall-slider-text-shadow-color),
    0px 0px 15px var(--hall-slider-text-shadow-color);
}

.hall-slider-mob .embla__slide:last-child .embla__slide__inner {
  /* z-index: 10; */
}

.hall-slider-mob .embla__button--prev {
  z-index: 5;
  left: -25px !important;
}

.hall-slider-mob .embla__slide:first-child .nav__link {
  padding-left: 5px;
}

.hall-slider-mob .embla__button--next {
  z-index: 5;
  right: -25px !important;
}

/* SELECT */

.hall-select {
  z-index: 10;
}

.hall-select.choices.is-open {
  z-index: 102;
}

.hall-select input {
  display: none;
}

.hall-select .choices__inner:after {
  border-color: var(--title-color) transparent transparent transparent;
}

.hall-select.choices.is-open .choices__inner:after {
  margin-top: -8px;
  border-color: transparent transparent var(--title-color) transparent;
}

.hall-select.choices.is-open:before {
  background-color: #291c34;
}

.hall-select .choices-placeholder {
  color: var(--title-color);
  text-shadow: var(--link-text-shadow);
  font-weight: 700;
}

.hall-select .choices__inner {
  background: var(--hall-select-bg);
  border: 2px solid var(--hall-select-border-color);
  box-shadow: 0px 0px 4px var(--hall-select-border-color);
  border-radius: 24px;
  font-size: 14px;
  text-transform: uppercase;
  color: var(--title-color);
  font-weight: 700;
  text-shadow: var(--link-text-shadow);
}

.hall-select .choices__list--dropdown {
  background: var(--hall-select-scrollbar-bg);
}

.hall-select .choices__list::-webkit-scrollbar {
  width: 3px;
}

.hall-select .choices__list::-webkit-scrollbar-thumb {
  background-color: var(--hall-select-scrollbar-color);
  border-radius: 5px;
}


@media (max-width: 600px) {
  .hall-select .choices__inner {
    padding-top: 10px;
    padding-bottom: 8px;
  }
}

@media (max-width: 600px) {
  .hall-slider-mob {
    /* margin-top: -5px; */
    /* margin-bottom: -15px; */
  }
}

.hall-select .choices__item:hover {
  color: var(--hall-select-scrollbar-color);
}

.hall-select .choices__item {
  background: var(--hall-select-item-bg);
  border-bottom: 1px solid rgba(255, 255, 255, 0.07);
}
