.container {
  max-width: 1330px;
  width: 100%;
  margin: 0 auto;
}

.container_small {
  max-width: 1120px !important;
}
@media only screen and (min-width: 769px) {
  .container {
    padding: 0 15px;
  }
}

@media only screen and (max-width: 768px) {
  .container_padding {
    padding: 0 15px;
  }
}
