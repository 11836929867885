.terms__title {
  font-weight: 700;
  font-size: 14px;
  line-height: 1;
  color: #ffffff;
  margin-bottom: 28px;
  text-transform: uppercase;
  text-indent: 20px;
}

.terms__text {
  font-size: 14px;
  line-height: 1.7;
  color: var(--description-title-color);
  margin-bottom: 28px;
  text-transform: none;
  text-indent: 30px;
}

.terms__descr {
  color: var(--description-title-color);
  font-size: 14px;
  line-height: 1.7;
  text-indent: 30px;
}

.terms__descr.mb {
  margin-bottom: 28px;
}

.terms__link {
  display: inline;
  text-decoration: underline;
  color: white;
}

.terms__descr:last-child {
  margin-bottom: 0;
}

.no-numeric .terms__title {
  text-transform: initial;
}

@media only screen and (max-width: 600px) {
  .terms__text_pay {
    margin-bottom: 30px;
  }
}

.no-numeric .terms__title {
  line-height: 1.5;
  color: var(--description-title-color);
  font-weight: 400;
}

.no-numeric .terms__title:before {
  color: var(--description-title-color);
  line-height: 1.5;
  content: '•‎';
}

.no-numeric .terms__sub-list {
  margin-top: 10px;
}

.no-numeric .terms__text {
  margin-bottom: 10px;
}

.no-numeric .terms__text:before {
  content: '•‎';
  left: -12px;
}

.terms__sub-list.numeric {
  counter-reset: none;
}


.numeric.terms__sub-list .terms__text:before {
  counter-increment: sublist;

  content: counters(sublist, ".");
}

.no-content.terms__sub-list .terms__text:before {
  content: ''
}


.terms__sub-list .terms__sub-list {
  width: 97.5%;
  margin-left: auto;
}

.terms__sub-list .terms__sub-list > li:before {
  left: -38px;
}

.terms__sub-list .terms__sub-list > li {
  text-indent: 38px;
}

.terms__sub-list:nth-child(1n + 10) .terms__sub-list > li:before {
  left: -45px;
}

.terms__sub-list:nth-child(1n + 10) .terms__sub-list > li {
  text-indent: 45px;
}

.terms__text:nth-child(1n + 10) .terms__sub-list > li:before {
  left: -45px;
}

.terms__text:nth-child(1n + 10) .terms__sub-list > li {
  text-indent: 45px;
}

.terms__text:nth-child(1n + 10) .terms__sub-list > li:nth-child(1n + 10) {
  text-indent: 55px;
}

.terms__text:nth-child(1n + 10) .terms__sub-list > li:nth-child(1n + 10):before {
  left: -55px;
}

.terms__text .terms__sub-list .terms__text .terms__sub-list .terms__text {
  text-indent: 57px;
}

.terms__text .terms__sub-list .terms__text .terms__sub-list .terms__text:before {
  left: -57px;
}

.terms__text .terms__sub-list .terms__text:nth-child(1n + 10) .terms__sub-list .terms__text {
  text-indent: 65px;
}

.terms__text .terms__sub-list .terms__text:nth-child(1n + 10) .terms__sub-list .terms__text:before {
  left: -65px;
}


.terms__sub-list > li:nth-child(1n + 10):before {
  left: -34px;
}

.terms__sub-list > li:nth-child(1n + 10) {
  text-indent: 34px;
}

.terms__title:nth-child(1n + 10) .terms__text {
  text-indent: 34px;
}

.terms__title:nth-child(1n + 10) .terms__text:before {
  left: -34px;
}

.terms__title:nth-child(1n + 10) .terms__sub-list .terms__sub-list .terms__text {
  text-indent: 46px;
}

.terms__title:nth-child(1n + 10) .terms__sub-list .terms__sub-list .terms__text:before {
  left: -46px;
}

.terms__title:nth-child(1n + 10) .terms__text:nth-child(1n + 10) {
  text-indent: 42px;
}

.terms__title:nth-child(1n + 10) .terms__text:nth-child(1n + 10):before {
  left: -42px;
}


