.file {
  background-color: var(--acc-document-load-bg);
  border: 1px dashed var(--acc-document-load-border-color);
  box-sizing: border-box;
  border-radius: 12px;
  padding: 20px 40px 27px;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
}


.file__input {
  display: none;
}

.main-form.restore-type__form  .restore-type__wrap {
  margin-right: auto;
}

.birthday-error {
  color: var(--field-error-color);
  font-size: 12px;
  position: absolute;
  display: flex;
  align-items: center;
  bottom: -21px;
  left: 4px;
}

.birthday-error svg {
  margin-right: 5px;
}

.file__label {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 11px;
  cursor: pointer;
}

.file__img {
  width: 29px;
  height: 29px;
  margin-right: 9px;
}

.file__title {
  font-weight: 700;
  font-size: 15px;
  line-height: 1;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: var(--title-color);
}

.main-form__row-phone {
  align-items: flex-start;
  margin-bottom: 0px;
}

.main-form__text-content-row .primary,
.main-form__row-phone .primary {
  margin-left: 0;
}

.main-form__row-phone form > * {
  margin-bottom: 20px;
}

.main-form__text-content-row {
  width: 100%;
  max-width: 415px;
  display: flex;
  justify-content: left;
  color: white;
  align-items: center;
  font-size: 14px;
  line-height: 16px;
}

@media (min-width: 992px) {
  .main-form__text-content-row > *:first-child {
    margin-left: 20px;
  }
}

.main-form__text-content-row .react-tel-input input {
  color: white;
}

.main-form__text-content-row span {
  position: relative;
}

.main-form__text-content-row span svg {
  position: absolute;
  right: -37px;
  top: -3px;
  height: 24px;
  width: 24px;
}

.contacts__email-form {
  display: flex;
  width: 100%;
  max-width: 410px;
}

.email-row .field {
  margin-right: 5px;
}

.contacts__email-form .btn {
  padding: 7px;
  min-width: 0;
  width: 100%;
  max-width: 120px;
  font-size: 13px;
  margin-left: 20px;
  margin-right: 0 !important;
}

.contacts__email-form .field {
  width: 100%;
  flex: 1 1 auto;
}

.main-form__text-content-row .main-form__btn {
  margin-left: 30px;
}

.main-form__text-content-row .primary {
  min-width: 150px;
}

.contact-form .main-form__wrap {
  padding: 32px 15px;
}

.contact-form .main-form__main-wrap:first-child {
  margin-bottom: 24px;
}

.try-again__message {
  min-width: 130px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
}

.try-again__message span {
  width: 100%;
  color: var(--description-text-color);
  font-size: 12px;
}

.try-again__message .main-form__btn {
  margin-top: 3px;
  margin-left: 0;
}

.main-form__row-phone form {
  width: 100%;
  max-width: 415px;
}

.main-form__row-phone form > *:last-child {
  margin-bottom: 0px;
}

.file__text {
  font-size: 12px;
  line-height: 1.2;
  text-align: center;
  color: var(--description-text-color);
}

.document {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
}

.document__img {
  width: 27px;
  height: 36px;
  margin-right: 11px;
  fill: var(--acc-file-color);
}

.document__img_download {
  fill: var(--acc-file-download-color);
}

.document__wrap {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  width: 100%;
}

.document__info {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.document__name {
  font-size: 12px;
  line-height: 1.2;
  color: var(--table-name-color);
  margin-right: 12px;
}

.document__delete {
  width: 15px;
  height: 17px;
  fill: var(--acc-document-delete-color);
}

.document__cross {
  width: 10px;
  height: 10px;
}

.document__cross-btn {
  margin-left: auto;
}

.document__status {
  font-size: 10px;
  line-height: 1.2;
  color: var(--field-error-color);
}

.document__line {
  width: 100%;
  height: 2px;
  background: var(--acc-file-color);
  margin-top: 8px;
  border-radius: 15px;
}

.document__percent {
  width: 60%;
  background: var(--acc-document-delete-color);
  height: 100%;
  display: block;
  border-radius: 15px;
}

.autowidth {
  width: auto;
}

.main-form .gender-first-radio {
  margin-right: 12px;
}

.main-form__sms-wrap .btn {
  min-width: 112px;
  padding: 13px 10px;
  margin-right: 20px;
  margin-left: 0px;
  font-size: 13px;
}

.main-form .field__text.error-text.contact-captcha-error {
  margin-left: calc(50% - 90px);
}

@media (max-width: 600px) {
  .main-form .field__text.error-text.contact-captcha-error {
    margin-bottom: 0px;
  }
  .main-form__sms-wrap .main-form__btn {
    margin-top: 0;
  }
}

@media (max-width: 400px) {
  .main-form__text-content-row .primary {
    min-width: 0px;
    font-size: 13px;
  }
}

@media (max-width: 360px) {
  .main-form__text-content-row .primary {
    min-width: 0px;
    font-size: 12px;
    padding: 9px 7px;
  }
  .contact-form .main-form__wrap {
    padding: 32px 10px;
  }
}

@media (min-width: 992px) {
  .try-again__message {
    margin-right: 12px;
  }
}

@media only screen and (min-width: 901px) and (max-width: 992px) {
  .main-form__text-content-row .primary {
    min-width: 0px;
  }
  .main-form__text-content-row > *:first-child {
    margin-left: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 900px) {
  .main-form__text-content-row > *:first-child {
    margin-left: 0px;
  }

  .main-form__text-content-row {
    max-width: initial;
  }
  .main-form__text-content-row .primary {
    margin-right: 0;
  }
  .main-form__main-wrap.main-form__row-phone .captcha-block {
    align-items: flex-start;
  }
  .contacts__email-form {
    max-width: initial;
  }
  .main-form__main-wrap.main-form__row-phone .btn {
    margin-left: 0;
  }
  .main-form__sms-wrap .field {
    max-width: 116px;
  }
  .main-form__row-phone form {
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  .main-form__text-content-row .try-again__message {
    margin-right: 6px;
  }
  .email-row .main-form__text-content-row > *:first-child {
    margin-right: 10px;
    white-space: nowrap;
    overflow: hidden;
    max-width: 60%;
    text-overflow: clip;
    white-space: normal;
    word-break: break-all;
  }
  .main-form__sms-wrap {
    justify-content: space-between;
  }
}

@media (max-width: 600px) {
  .user-info__form .btn.primary {
    width: 100%;
  }

  .main-form__text-content-row > *:first-child {
    margin-left: 0px;
  }
  .contacts__email-form {
    max-width: initial;
  }
  .main-form__text-content-row {
    max-width: initial;
  }
  .main-form__text-content-row .primary {
    margin-right: 0;
  }
  .main-form__main-wrap.main-form__row-phone .captcha-block {
    align-items: flex-start;
  }
  .main-form__main-wrap.main-form__row-phone .btn {
    margin-left: 0;
  }
  .main-form__sms-wrap .main-form__btn {
    width: initial;
  }
  .main-form__row-phone form {
    max-width: 100%;
  }
  .contacts__email-form .primary {
    margin-left: 8px;
  }
}


@media (max-width: 400px) {
  .file {
    padding: 15px 28px 22px;
  }
}

@media (max-width: 360px) {
  .main-form__sms-wrap .main-form__btn {
    font-size: 12px;
  }
  .main-form__wrap .primary {
    min-width: 0;
  }
  .main-form__main-wrap.main-form__row-phone .primary {
    min-width: 200px;
  }
  .try-again__message span {
    font-size: 11px;
  }
  .main-form__text-content-row span {
    font-size: 13px;
  }
  .try-again__message .main-form__btn {
    font-size: 12px;
    margin-top: 1px;
  }
}
