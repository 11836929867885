.pagination {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    margin: 30px 0;
}

.pagination__item {
    margin: 0 14px;
}

.pagination__link {
    font-weight: 700;
    font-size: 24px;
    line-height: 1;
    -webkit-box-align: center;
    align-items: center;
    color: #ffffff;
    cursor: pointer;
}

.pagination__link_active, .pagination__link:hover {
    color: var(--title-color);
}

@media only screen and (max-width: 600px) {
    .pagination__link {
        font-size: 18px;
    }
}
