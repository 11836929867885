.embla {
  position: relative;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.embla__viewport {
  overflow-x: hidden;
  width: 100%;
}

.embla__viewport.is-draggable {
  cursor: move;
  cursor: grab;
}

.embla__viewport.is-dragging {
  cursor: grabbing;
}

.embla__container {
  display: flex;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.embla__slide {
  position: relative;
}

.embla__slide__inner {
  position: relative;
  overflow: hidden;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.embla__button {
  outline: 0;
  cursor: pointer;
  background-color: transparent;
  touch-action: manipulation;
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  border: 0;
  width: 41px;
  height: 71px;
  justify-content: center;
  align-items: center;
  fill: #1bcacd;
  padding: 0;
}

.embla__button:disabled {
  cursor: default;
  opacity: 0.3;
}

.embla__button__svg {
  width: 41px;
  height: 71px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  fill: var(--slider-arrow-button-color);
}

.embla__button__svg:hover {
  fill: var(--title-color);
}

.embla__button--prev {
  left: 27px;
}

.embla__button--next {
  right: 27px;
}

.embla__button--next > svg {
  transform: scale(-1, 1);
}

@media only screen and (max-width: 1200px) {
}

@media only screen and (max-width: 992px) {
  .embla__button,
  .embla__button__svg {
    width: 24px;
    height: 60px;
  }
}

@media only screen and (max-width: 768px) {
  .embla__button,
  .embla__button__svg {
    width: 21px;
    height: 36px;
  }
}

@media only screen and (max-width: 600px) {
  .embla__button,
  .embla__button__svg {
    width: 16px;
    height: 32px;
  }
}

.swiper-pagination {
  position: absolute;
  text-align: center;
  transition: 0.3s opacity;
  transform: translate3d(0, 0, 0);
  z-index: 10;
  bottom: 10px;
  left: 0;
  width: 100%;
}

.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 100%;
  background-color: #9f9f9f;
  opacity: 1;
  margin: 0 4px;
}

.swiper-pagination-bullet-active {
  background-color: #ffffff;
}

.smallBtns .embla__button {
  top: 55%;
  z-index: 3;
}

.smallBtns .embla__button__svg {
  width: 12px;
  height: 22px;
  fill: #813380;
}

.smallBtns .embla__button--next {
  right: -40px;
}

.smallBtns .embla__button--prev {
  left: -40px;
}

.swiper-pagination-bullets {
  transition: all 0.3s ease;
  cursor: pointer;
}
