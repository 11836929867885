.jackpot__title.skelet {
  height: 38px;
}

.jackpot {
  height: 100%;
}

.jackpot__name {
  height: 14%;
  padding: 20px 10px;
}

.jackpot__wrap {
  height: 350px;
  max-height: 350px;
  overflow: hidden;
}

.daily-winners .jackpot__title {
  white-space: nowrap;
  max-width: 95px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.jackpot__item.winner-item {
  display: flex;
  flex-direction: row;
}

.winner-item .jackpot__row {
  align-items: center;
}

.jackpot__item .jackpot__item-img {
  flex: 0 0 24px;
  display: inline;
  margin-right: 7px;
  border-radius: 2px;
}

.jackpot__item-img img {
  width: 100%;
  height: 100%;
  border-radius: 2px;
  object-fit: cover;
}

.jackpot__item:first-of-type * {
  animation: fadeIn 2s;
}

.jackpot__item {
  animation: winner_move_right 2s;
}

.jackpot__item:last-of-type {
  animation: last-item 2s;
}

.jackpot__item:last-of-type * {
  animation: fadeOut 2s;
}

@keyframes winner_move_right {
  0% {
    transform: translateY(-70px);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.5);
  }
  100% {
    transform: translate(0);
    box-shadow: 0px;
  }
}

@keyframes last-item {
  0% {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.5);
    transform: translateY(-70px);
  }
  100% {
    box-shadow: 0px;
    transform: translateY(0);
  }
}
