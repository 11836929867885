.field {
    max-width: 415px;
    width: 100%;
    position: relative;
}

.field_warning .field__text {
    color: var(--field-warning-color);
}

.field_warning .field__input {
    border-color: var(--field-warning-border-color);
}

.field_error .field__text {
    color: var(--field-error-color);
    white-space: nowrap;
}

.field_error .field__input {
    border-color: var(--field-error-border-color);
}

.field__input {
    width: 100%;
    background-color: var(--field-bg);
    border-radius: 24px;
    padding: 11px 44px 11px 19px;
    border: 1px solid var(--field-bg);
    font-size: 14px;
    color: #ffffff;
}

.field__input:not([value='']):not(:focus) {
    /*border: 1px solid var(--field-filled-border-color);*/
    background: #684b8021;
}

.field__input::-webkit-input-placeholder {
    font-size: 14px;
    color: var(--field-placeholder-color);
}

.field__input::-ms-input-placeholder {
    font-size: 14px;
    color: var(--field-placeholder-color);
}

.field__input::-ms-input-placeholder {
    font-size: 14px;
    color: var(--field-placeholder-color);
}

.field__input::placeholder {
    font-size: 14px;
    color: var(--field-placeholder-color);
}

.field__input:focus {
    border: 1px solid var(--title-color);
}

.field__text {
    position: absolute;
    bottom: -17px;
    left: 0;
    font-size: 12px;
}

.field__icon {
    position: absolute;
    right: 20px;
    top: 10px;
    z-index: 1;
    width: 20px;
    height: 20px;
}

.field__btn {
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 12px;
    width: 16px;
    height: 16px;
}

.field__btn .field__icon {
    position: static;
    width: 16px;
    height: 16px;
}
