    .lottery-wrap {
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        -webkit-box-align: start;
        align-items: flex-start;
        flex-wrap: wrap;
        margin: 0 -10px;
    }

    .lottery-wrap .lottery {
        max-width: 540px;
        width: 100%;
        margin-bottom: 25px !important;
    }


    .lottery {
        background: var(--lottery-bg);
        position: relative;
        margin: 0 auto;
        padding: 7px;
        border-radius: 5px;
        overflow: hidden;
    }

    .lottery-table {
        margin-top: 29px;
        padding-top: 34px;
        border-top: 1px solid var(--lottery-description-border-color);
    }

    .lottery-section .lottery {
        margin-bottom: 120px;
    }

    .lottery_game .decor-abs.right {
        top: 47% !important;
    }

    .lottery_game .lottery__wrap {
        padding: 0;
    }

    .lottery .decor-abs {
        position: absolute;
        z-index: 1;
    }

    .lottery .decor-abs.bottom {
        width: 42px;
        height: 45px;
        bottom: 0.5px;
        left: 0.5px;
    }

    .lottery .decor-abs.top {
        width: 67px;
        height: 38px;
        top: 0;
        right: -0.5px;
    }

    .lottery .decor-abs.right {
        top: 21%;
        right: 0;
        width: 7.5px;
        height: 93px;
    }

    .lottery__wrap {
        background-color: var(--article-wrap-bg);
        padding: 40px 20px 28px;
    }

    .lottery__wrap_open {
        padding: 65px 30px 50px;
        width: 100%;
    }

    .lottery__wrap_open .lottery__name svg {
        width: 55px;
        height: 45px;
        margin-right: 20px;
    }

    .lottery__wrap_open .lottery__name-wrap {
        padding-bottom: 40px;
        margin-bottom: 43px;
        border-bottom: 1px solid var(--lottery-description-border-color);
    }

    .lottery__wrap_open .lottery__time-info {
        margin-bottom: 52px;
    }

    .lottery__wrap_open .lottery__time-info .btn {
        width: 287px;
        font-size: 18px;
        border-radius: 36px;
        padding: 18px 30px;
    }

    .lottery .btn {
        min-width: 235px;
        padding: 11px 30px;
        font-size: 18px;
    }

    .lottery__name-wrap {
        margin-bottom: 15px;
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        align-items: center;
    }

    .lottery__name {
        font-weight: 700;
        font-size: 36px;
        letter-spacing: 0.05em;
        color: #ffffff;
        display: -webkit-box;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
    }

    .lottery__name svg {
        position: relative;
        left: -10px;
        width: 35px;
        height: 30px;
    }

    .lottery__status {
        background-color: transparent;
        border-radius: 4px;
        padding: 4px 15px 4px;
        font-weight: 700;
    }

    .lottery__status_active {
        background-color: var(--lottery-status-active-color);
    }

    .lottery__status_finished {
        background: var(--lottery-status-finished-color);
    }

    .lottery__status-text {
        font-size: 12px;
        text-align: center;
        letter-spacing: 0.05em;
        color: #ffffff;
        line-height: 0;
    }

    .lottery__time-info {
        display: -webkit-box;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        justify-content: space-between;
        position: relative;
    }

    .lottery__period-wrap {
        display: -webkit-box;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
    }

    .lottery__period-title {
        font-size: 14px;
        color: #ffffff;
        font-weight: 700;
        margin-bottom: 10px;
        text-transform: uppercase;
    }

    .lottery__period-date {
        font-size: 14px;
        color: #ffffff;
        text-transform: uppercase;
    }

    .lottery__img {
        width: 505px;
        height: 157px;
        position: relative;
        left: 50%;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
        margin-bottom: 27px;
    }

    .lottery__img img {
        width: 100%;
        height: 100%;
    }

    .lottery__main-img {
        position: relative;
        margin-bottom: 32px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        min-height: 860px;
        width: 100%;
    }

    .lottery__main-img:before {
        content: '';
        position: absolute;
        top: -3px;
        left: 0;
        right: 0;
        background: -webkit-radial-gradient( 50% 50%, 50% 50%, var(--lottery-main-img-gradient-first-color) 51.04%, var(--lottery-main-img-gradient-second-color) 100%);
        background: radial-gradient( 50% 50% at 50% 50%, var(--lottery-main-img-gradient-first-color) 51.04%, var(--lottery-main-img-gradient-second-color) 100%);
        border-radius: 2px;
        width: 100%;
        height: 6px;
        z-index: 1;
    }

    .lottery__descr {
        padding-bottom: 25px;
        margin-bottom: 30px;
        border-bottom: 1px solid var(--lottery-description-border-color);
        font-size: 18px;
        line-height: 167%;
        color: var(--description-title-color);
        font-weight: 400;
    }

    .lottery__descr p {
        font-size: 18px;
        line-height: 167%;
        color: var(--description-title-color);
        font-weight: 400;
    }

    .lottery__count {
        font-weight: 700;
        font-size: 54px;
        line-height: 1;
        text-align: center;
        color: #ffffff;
    }

    .lottery__btns {
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
    }

    .lottery__btn {
        font-size: 18px;
        font-weight: 700;
        text-transform: uppercase;
        color: var(--link-color);
    }

    .lottery__wrap_open .lottery__name svg {
        width: 55px;
        height: 45px;
        margin-right: 20px;
    }

    .lottery__wrap_open {
        padding: 65px 30px 50px;
        width: 100%;
    }

    .lottery__period-date.loading {
        height: 10px;
        width: 50%;
        border-radius: 3px;
    }

    .lottery__name.loading {
        height: 45px;
        width: 50%;
        border-radius: 3px;
    }

    @media only screen and (max-width: 1120px) {
        .lottery__main-img {
            min-height: 660px;
        }
        .lottery__descr>p {
            font-size: 16px;
        }
    }

    @media only screen and (max-width: 992px) {
        .lottery__time-info>.btn {
            width: 240px !important;
        }
        .lottery__status.lottery__status_active {
            display: none;
        }
        h2.lottery__name {
            font-size: 30px;
        }
        .lottery__descr {
            font-size: 15px;
        }
        .lottery__descr p {
            font-size: 15px;
        }
        .lottery__main-img {
            margin-bottom: 15px;
        }
        .lottery__main-img {
            min-height: 580px;
        }
    }

    @media only screen and (max-width: 768px) {
        .lottery__period-wrap {
            width: 50%;
        }
        .lottery__time-info>.counter {
            margin-bottom: 0;
        }
        .lottery__time-info {
            flex-wrap: wrap;
            margin-bottom: 33px !important;
        }
        .lottery__wrap_open>.lottery__time-info>.btn {
            margin: 35px auto 0;
        }
        .lottery__name-wrap>h2.lottery__name {
            font-size: 24px;
        }
        .lottery__wrap_open .lottery__name svg {
            width: 48px;
            height: 30px;
            margin-right: 0;
            left: 0;
        }
        .lottery__wrap {
            padding: 20px 10px 14px;
        }
        .lottery-section .lottery {
            margin-bottom: 80px;
        }
        .lottery__wrap_open {
            padding: 32px 15px 25px;
            width: 100%;
        }
        .lottery__main-img {
            min-height: 400px;
        }
        .lottery__descr {
            font-size: 14px;
        }
        .lottery__descr p {
            font-size: 14px;
        }
    }

    @media only screen and (min-width: 768px) {
        .lottery__time-info .counter {
            margin-bottom: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            background-color: transparent;
        }
    }

    @media only screen and (max-width: 600px) {
        .lottery__wrap_open {
            padding: 0px !important;
        }
        .lottery__wrap {
            background: none;
            padding: 0;
        }
        .lottery__wrap_open .lottery__name-wrap {
            padding-bottom: 20px;
            margin-bottom: 20px;
            border-bottom: 1px solid var(--lottery-description-border-color);
        }
        .lottery__name {
            text-align: center;
        }
        .lottery {
            padding: 0;
            background: none;
            margin: 0;
            margin-bottom: 45px !important;
        }
        .decor-abs {
            display: none;
        }
        .lottery__period-title {
            margin-bottom: 3px;
            font-size: 13px;
        }
        .lottery__status {
            display: none;
        }
        .lottery__wrap_open .lottery__time-info .btn {
            padding: 15px 24px;
        }
    }

    @media only screen and (max-width: 460px) {
        .lottery__main-img {
            min-height: 350px;
        }
    }

    @media only screen and (max-width: 400px) {
        .lottery__main-img {
            min-height: 300px;
        }
    }
