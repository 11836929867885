.info-table {
    border: 2px solid #e0dfe2;
    border-radius: 5px;
    margin: 48px 0;
}

.info-table__head {
    font-weight: 700;
}

.info-table__row:nth-child(2n) {
    background-color: #EFEFF0;
}

.info-table__row {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    justify-content: space-between;
    align-items: stretch;
    border-bottom: 2px solid #e0dfe2;
}

.info-table__row:last-child {
    border-bottom: none;
}

.info-table__col {
    padding: 20px;
    border-right: 2px solid #e0dfe2;
    font-size: 18px;
    color: #000000;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.info-table__col:last-child {
    border-right: none;
}

.info-table__col_bonus {
    width: 212px;
}

.info-table__col_deposit {
    width: 178px;
}

.info-table__col_persent {
    width: 133px;
}

.info-table__col_wager {
    width: 122px;
}

.info-table__col_max {
    width: 207px;
}

@media only screen and (max-width: 1200px) {
    .info-table__col {
        padding: 17px 12px;
    }
}

@media only screen and (max-width: 992px) {
    .info-table__col {
        font-size: 16px;
        padding: 14px 8px;
    }
}

@media only screen and (max-width: 768px) {
    .info-table__col {
        font-size: 14px;
        padding: 14px 5px;
    }
}

@media only screen and (max-width: 600px) {
    .info-table__col {
        font-size: 12px;
        padding: 8px 3px;
    }
}

@media only screen and (max-width: 400px) {
    .info-table__col {
        font-size: 10px;
        padding: 5px 2px;
    }
}

@media only screen and (max-width: 360px) {
    .info-table__col {
        font-size: 9px;
    }
}