.password-reset__email {
  align-items: flex-start;
}

.restore-type__wrap {
  width: 100%;
  max-width: 415px;
}

.password-reset__fields-form .main-form__wrap {
  padding-top: 0;
}

.restore-type__form .main-form__main-wrap {
  margin-bottom: 18px;
}

.restore-type__form .main-form__wrap {
  padding-bottom: 0;
}
.change-password__field {
  width: 100%;
  max-width: 415px;
}

.change-password__field .captcha-block {
  margin-bottom: 18px;
}

.change-password__field .field:first-child {
  margin-bottom: 18px;
}

.change-password__field .btn {
  margin-left: 0;
}

.restore-type__wrap .modal__tabs-head {
  margin: 0;
  padding-left: 0;
  padding-bottom: 0;
  padding-right: 0;
  border: none;
}
.recovery__warning-text {
  color: var(--field-warning-color);
  display: flex;
  align-items: center;
}

.recovery__warning-text svg {
  width: 15px;
  min-width: 15px;
  height: 15px;
  margin-right: 10px;
}
