

.frame__list {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.frame__item {
    margin-right: 41px;
}

.frame__link {
    font-size: 14px;
    line-height: 0.8;
    letter-spacing: 0.02em;
    color: var(--title-color);
    border-bottom: 1px solid var(--title-color);
    cursor: pointer;
}

.frame__name {
    font-size: 24px;
    line-height: 1.2;
    color: #ffffff;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.frame__nav {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    margin: 0 -10px 0 0;
}

.frame__btn_full {
    margin-right: 12px;
}

.frame__btn_full svg {
    width: 23px;
    height: 22px;
}

.frame__btn_close svg {
    width: 38px;
    height: 38px;
}
