.balance-wrap {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.balance__wrap {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

.balance__info {
  font-weight: 700;
  font-size: 12px;
  color: #ffffff;
  padding-left: 8px;
}

.balance__title {
  font-size: 12px;
  color: var(--description-text-color);
  padding-left: 8px;
  white-space: nowrap;
}

.balance__count-wrap {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin: -5px 0;
}

.balance__img {
  -webkit-box-flex: 0;
  flex: none;
}

.balance__img svg {
  width: 32px;
  height: 32px;
}

.balance__count {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  font-weight: 700;
  font-size: 14px;
  color: #ffffff;
  white-space: nowrap;
}

.balance__count_main {
  color: var(--title-color);
}

.balance__commission {
  font-size: 12px;
  color: var(--title-color);
  padding: 0 0 0 8px;
}
