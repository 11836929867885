.slide {
  position: relative;
  margin-bottom: -4px;
  overflow: hidden;
  flex: 1 1 auto;
  display: flex;
  align-items: stretch;
}

.slide {
  height: 100%;
}

.slide > img {
  min-width: 100%;
  object-fit: cover;
  height: 45vh;
  min-height: 450px;
}

.intro-nav__left {
  position: relative;
}

.intro .embla__slide:first-child .slide {
  background-image: var(--intro-first-slide-load-bg);
}

.intro-nav__left span {
  position: absolute;
  font-size: 24px;
  font-weight: 700;
  color: var(--title-color);
  text-transform: uppercase;
  right: 29px;
  top: 23px;
}

.intro-nav__center {
  position: relative;
}

@media (min-width: 769px) {
  .intro .embla__button {
    top: 45%;
  }
}

.intro-nav__bullets {
  position: absolute;
  top: 9%;
  left: 49%;
  display: flex;
  transform: translate(-50%, -50%);
}

.intro-nav__bullets-item {
  margin: 0 16px;
  display: block;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  background: var(--title-color);
  opacity: 0.5;
  box-shadow: 0px 0px 4px rgba(250, 255, 10, 0.25);
  cursor: pointer;
  transition: all 0.3s ease;
}

.intro-nav__bullets-item.active,
.intro-nav__bullets-item:hover {
  opacity: 1;
}

.intro {
  position: relative;
}

.intro-slider {
  position: relative;
}

.intro-slider__text {
  position: absolute;
  top: 80px;
  left: 165px;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: start;
  align-items: flex-start;
}

.intro-slider__text > .btn.not-auth__btn {
  font-size: 16px;
}

.intro-slider__title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-weight: 700;
  font-size: 36px;
  letter-spacing: 0.05em;
  color: #ffffff;
  -webkit-box-flex: 0;
  flex: none;
  max-width: 460px;
  width: 100%;
}

.intro-slider__title span {
  background-color: var(--intro-slider-text-bg);
  padding: 5px 10px;
  margin: 0 0 13px;
}

.intro-slider__arrow {
  top: 155px;
}

.intro-slider__arrow:after {
  display: none;
}

.intro-slider__arrow:hover svg {
  fill: var(--title-color);
}

.intro-slider__arrow svg {
  width: 41px;
  height: 71px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  fill: #c841c4;
}

.intro-slider .swiper-slide img {
  width: 100%;
  display: block;
}

.intro-slider .swiper-button-next {
  -webkit-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
  right: 33px;
}

.intro-slider .swiper-button-prev {
  left: 33px;
}

.intro-slider__text > .btn {
  width: 256px;
  position: relative;
  font-size: 24px;
  padding: 16px 80px 16px 44px;
  border-radius: 48px;
  margin-top: 43px;
}

.intro-slider__text .btn svg {
  width: 27px;
  height: 29px;
  position: absolute;
  top: 20%;
  right: 37px;
}

.intro-nav {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  align-items: flex-end;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
}

.intro-slider__title-wrap {
  display: flex;
  flex-direction: column;
}

.intro-nav__center {
  margin: 0 -76px;
}

.intro-nav .nav {
  position: absolute;
  bottom: 27px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 1;
  margin: 0;
}

.intro-nav .nav__item {
  margin: 0 10px;
}

@media (min-width: 1440px) {
  .intro-nav .nav__item {
    margin: 0 16px;
  }
  .intro-nav .nav__text {
    font-size: 15px;
  }
}

.intro-nav .nav__link {
  font-size: 15px;
  white-space: nowrap;
  margin: 0;
}

.intro-nav__right {
  position: relative;
}

.intro-nav__right .search-form {
  position: absolute;
  top: 25px;
  left: 30px;
  z-index: 1;
}

@media only screen and (max-width: 1400px) {
  .intro-slider__text > .btn {
    margin-top: 23px;
    width: 240px;
    padding: 12px 72px 12px 36px;
  }
  .intro-slider__text > .btn svg {
    width: 24px;
    height: 24px;
  }

  .intro-nav__left {
    display: none;
  }

  .slide > img {
    min-height: 400px;
  }

  .intro-nav__left img {
    width: 170px;
  }

  .intro-nav__center img {
    width: 900px;
  }

  .intro-nav .nav {
    left: 48%;
    bottom: 20px;
  }

  .intro-nav .nav__link {
    font-size: 14px;
  }
}

@media only screen and (max-width: 1200px) {
  .intro-slider__title {
    font-size: 36px;
  }

  .slide > img {
    min-height: 350px;
  }

  .intro-slider__text {
    top: 0;
    height: 100%;
    justify-content: center;
    padding-bottom: 90px;
  }

  .intro-slider__text > .btn {
    margin-top: 20px;
    padding: 12px 66px 12px 30px;
  }
}

@media only screen and (max-width: 1120px) {
  .intro-nav__center img {
    width: 800px;
  }

  .intro-nav .nav {
    left: 48%;
    bottom: 20px;
  }

  .intro-nav .nav__link {
    font-size: 13px;
  }
}

@media only screen and (max-width: 992px) {
  .slide > img {
    height: 30vh;
  }

  .intro-nav {
    bottom: -2px;
  }

  .intro-nav__center > img {
    width: 700px;
  }

  .intro-nav .nav {
    left: 49%;
    bottom: 17px;
  }

  .intro-nav .nav__link {
    font-size: 11px;
  }

  .intro-nav__right {
    bottom: -5px;
  }

  .intro-slider__text {
    /* top: 5%; */
    transform: translate(0, 0%);
    width: 100%;
    left: 0;

    padding-bottom: 70px;
  }

  .intro-slider__title span {
    margin-bottom: 8px;
    margin-left: auto;
    margin-right: auto;
  }

  .intro-slider__title-wrap {
    margin: 0 auto;
    flex-wrap: wrap;
    justify-content: center;
  }

  .intro-slider__title {
    background: none;
    font-size: 36px;
  }

  .intro-slider__text > .btn {
    padding: 10px 32px 10px 12px;
    font-size: 20px;
    width: 185px;
    margin: 15px auto 0;
  }

  .intro-slider__text .btn svg {
    width: 19px;
    height: 21px;
    position: absolute;
    top: 20%;
    right: 21px;
  }

  .intro-slider__title-wrap {
    flex-direction: row;
  }

  .intro-nav__bullets-item {
    width: 8px;
    height: 8px;
  }
}

@media only screen and (max-width: 900px) {
  .intro-nav__right {
    right: -20px;
  }
  .slide > img {
    min-height: 280px;
  }
  .intro-nav__right .search-form {
    top: 18px;
    left: 37px;
  }

  .intro-nav__right input {
    width: 160px;
    height: 32px;
  }

  .intro-nav__right img {
    width: 250px;
    height: 63px;
  }
}

@media (max-width: 860px) {
  .intro-nav__center > img {
    width: 650px;
    margin-left: 25px;
  }

  .intro-nav .nav {
    left: 51%;
  }

  .intro-nav .nav__link {
    font-size: 10px;
  }
}

@media (max-width: 800px) {
  .intro-nav__center > img {
    width: 630px;
    margin-left: 35px;
  }

  .intro-nav .nav {
    left: 52%;
    bottom: 16px;
  }
}

@media (max-width: 769px) {
  .slide {
    min-height: 250px;
    height: 25vh;
  }
}

@media only screen and (max-width: 768px) {
  .slide > img {
    min-height: 0;
  }

  .intro .embla:after {
    content: '';
    height: 2px;
    position: absolute;
    bottom: -2px;
    left: 0;
    right: 0;
    z-index: 5;
    background: var(--intro-mobile-after-bg);
  }

  .intro-slider__title span {
    background-color: transparent;
    font-weight: 600;
    margin-bottom: 3px;
    text-shadow: 0px 6px 6px rgba(0, 0, 0, 0.5);
  }

  .intro .embla__slide:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background: linear-gradient(
      6deg,
      rgba(0, 0, 0, 0.95) 0%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  .intro .intro-slider__text {
    z-index: 2;
  }

  .intro-slider__title {
    text-align: center;
    font-size: 32px;
  }

  .intro-slider__text {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
    padding-bottom: 0;
  }

  .slide {
    background-size: cover;
    background-position: 82% 53%;
    background-repeat: no-repeat;
    padding: 35px 0 50px;
  }
}

@media only screen and (max-width: 600px) {
  .intro-slider__title {
    font-size: 30px;
  }
  .intro-slider__text > .btn.not-auth__btn {
    margin-top: 5px;
  }

  .slide {
    height: 20vh;
    min-height: 185px;
  }

  .intro .embla__button__svg {
    display: none;
  }
}
@media only screen and (max-width: 400px) {
  .intro-slider__title {
    font-size: 24px;
  }
}
@media only screen and (max-width: 340px) {
  .intro-slider__title {
    font-size: 22px;
  }
}
