.loyalty-article .article__info {
  min-height: 1000px;
}

.loyalty-table .info-table__col {
    padding: 15px;
}


.loyalty-table .col--range {
    padding-left: 0;
    padding-right: 0;
}

@media (max-width: 1200px) {
    .loyalty-table {
        width: calc(100% + 100px);
        margin-left: -50px;
    }
    .loyalty-table .info-table__col {
        font-size: 16px;
    }
}

@media (max-width: 992px) {
    .loyalty-table {
        width: calc(100% + 80px);
        margin-left: -40px;
    }
    .loyalty-table .info-table__col {
        font-size: 15px;
    }
}

@media (max-width: 768px) {
    .loyalty-table {
        width: calc(100% + 60px);
        margin-left: -30px;
    }
    .loyalty-table .info-table__col {
        font-size: 12px;
        padding: 10px 5px;
    }
}

@media (max-width: 600px) {
    .loyalty-table {
        width: calc(100% + 36px);
        margin-left: -18px;
    }
    .loyalty-table .info-table__col {
        font-size: 10px;
        padding: 8px 3px;
    }
    .loyalty-table {
        margin-top: 20px;
        margin-bottom: 30px;
    }
}

@media only screen and (max-width: 400px) {
    .loyalty-table .info-table__col {
        font-size: 8px;
        padding: 3.5px 3px;
    }
    .loyalty-table .info-table__row .info-table__col {
        font-size: 6px
    }
    .loyalty-table {
        margin-top: 20px;
        margin-bottom: 20px;
    }
}
