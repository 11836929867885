.search-form__wrap {
  position: relative;
  display: inline-block;
}

.search-form__wrap {
  position: relative;
  display: inline-block;
}

.search-form__input {
  background: var(--field-bg);
  box-shadow: inset 0px 6px 6px rgba(0, 0, 0, 0.5);
  border-radius: 24px;
  width: 204px;
  height: 36px;
  padding: 3px 40px 2px 14px;
  font-size: 14px;
  color: #ffffff;
  transition: 0.3s linear;
}

.mob .search-form__input {
  width: 46px;
  padding: 0;
  height: 46px;
  box-shadow: none;
  font-size: 0;
}

.mob .search-form__input:focus,
.mob .search-form__input:not([value='']) {
  font-size: 14px;
  width: 204px;
  padding: 3px 40px 2px 14px;
}

@media (max-width: 420px) {
  .mob .search-form__input:focus {
    width: 150px;
  }
}

.search-form__input::-webkit-input-placeholder {
  color: var(--field-placeholder-color);
}

.search-form__input:-ms-input-placeholder {
  color: var(--field-placeholder-color);
}

.search-form__input::-ms-input-placeholder {
  color: var(--field-placeholder-color);
}

.search-form__input::placeholder {
  color: var(--field-placeholder-color);
}

.search-form__btn {
  position: absolute;
  top: 50%;
  right: 12px;
  -webkit-transform: translateY(-45%);
  -ms-transform: translateY(-45%);
  transform: translateY(-45%);
}

.search-form__btn svg {
  fill: var(--field-placeholder-color);
  width: 21px;
  height: 20px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.search-form__btn:hover svg {
  fill: var(--title-color);
}

.search-form__input {
  background: var(--field-bg);
  box-shadow: inset 0px 6px 6px rgba(0, 0, 0, 0.5);
  border-radius: 24px;
  width: 204px;
  height: 36px;
  padding: 3px 40px 2px 14px;
  font-size: 14px;
  color: #ffffff;
  transition: 0.3s linear;
}

.mob .search-form__input {
  width: 46px;
  padding: 0;
  height: 46px;
  box-shadow: none;
  font-size: 0;
}

.mob .search-form__input:focus,
.mob .search-form__input:not([value='']) {
  font-size: 14px;
  width: 204px;
  padding: 3px 40px 2px 14px;
}

@media (max-width: 420px) {
  .mob .search-form__input:focus {
    width: 150px;
  }
}

.search-form__input::-webkit-input-placeholder {
  color: var(--field-placeholder-color);
}

.search-form__input:-ms-input-placeholder {
  color: var(--field-placeholder-color);
}

.search-form__input::-ms-input-placeholder {
  color: var(--field-placeholder-color);
}

.search-form__input::placeholder {
  color: var(--field-placeholder-color);
}

.search-form__btn {
  position: absolute;
  top: 50%;
  right: 12px;
  -webkit-transform: translateY(-45%);
  -ms-transform: translateY(-45%);
  transform: translateY(-45%);
}

.search-form__btn svg {
  fill: var(--field-placeholder-color);
  width: 21px;
  height: 20px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.search-form__btn:hover svg {
  fill: var(--title-color);
}

.search-abs {
  position: fixed;
  top: 75px;
  left: 0;
  right: 0;
  bottom: 70px;
  z-index: 15;
  background: rgba(37, 31, 45, 0.8);
  width: 100%;
  height: 100%;
  padding: 25px 15px;
  display: none;
  overflow: auto;
  text-align: center;
}

.search-abs .search-form__wrap {
  width: 100%;
}

.search-abs .search-form__wrap input {
  box-shadow: none;
  width: 100%;
}

.search-abs .results-wrap {
  padding-bottom: 80px;
}

.search-abs.active {
  display: block;
}

.search-title .game-head__name {
  font-size: 18px !important;
  margin-bottom: 5px;
}

.search-games > .search-form__wrap,
.search-games > .search-form__wrap input {
  width: 100%;
}

.search-games > .search-form__wrap input {
  box-shadow: none;
}

.results-wrap .results-info {
  font-weight: 700;
}

.search-button {
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 700;
  color: #fff;
  margin-left: 7px;
  text-transform: uppercase;
  font-size: 12px;
  cursor: pointer;
}

.search-button.active {
  color: var(--link-color);
  text-shadow: var(--link-text-shadow);
}

.search-button.active svg {
  fill: var(--title-color) !important;
}

@media only screen and (max-width: 600px) {
  .search-abs {
    top: 43px;
  }
}
