.results-wrap {
  padding: 30px 0;
  margin-bottom: 20px;
  border-bottom: 1px solid var(--primary-border-color);
}

.results-text {
  font-size: 14px;
  line-height: 1.3;
  text-align: center;
  color: #ffffff;
  max-width: 1080px;
  width: 100%;
  margin: 0 auto 10px;
}

.results-wrap .results-text_bg {
  padding: 13px 30px;
  background: var(--acc-content-bg);
  border-radius: 5px;
}

@media only screen and (min-width: 768px) {
  .results-text {
    font-size: 24px;
    line-height: 28px;
  }
}
