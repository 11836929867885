.bonus-slider {
  margin-top: 12px;
}

.game_slider .game__wrap:after {
  display: none;
}

@media (min-width: 601px) {
  .game-section .bonus-slider:before {
    pointer-events: none;
    content: '';
    top: 0;
    right: 0;
    width: 15%;
    height: 100%;
    position: absolute;
    z-index: 10;
    transition: all 0.5s linear;
    background: var(--bonus-card-slider-shadow-bg);
    opacity: 1;
  }
}

@media (max-width: 1400px) {
  .game-section .smallBtns .embla__button--next {
    z-index: 11;
  }

  .game-section .smallBtns .embla__button--prev {
    z-index: 11;
  }
}

.game-section .embla__button__svg {
  fill: var(--btn-primary-bgColor);
}

.game-section .bonus-slider.last:before {
  opacity: 0;
}

.bonus-slider .embla__slide {
  padding-left: 10px;
}


.bonus-slider .embla__slide:first-child .bonus-card {
  /*margin-left: 0;*/
}

.bonus-slider .embla__slide__inner {
  padding-top: 29px;
}

.main-content .game-section .game-nav {
  margin-bottom: 20px;
}

.partner-slider {
  min-height: 77px;
  display: flex;
  align-items: center;
  padding: 17px 0;
  position: relative;
  background: var(--partner-slider-bg);
  box-shadow: var(--partner-slider-box-shadow);
}

.border-top {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: var(--partner-border-top-bg);
}

.partner-slider__svg {
  width: 125px;
  height: 40px;
}

.partner-slider .nav__link {
  font-size: 14px;
  white-space: nowrap;
  padding: 0 30px;
}

@media (max-width: 992px) {
  .partner-slider .nav__link {
    font-size: 14px;
    white-space: nowrap;
    padding: 0 35px;
  }
}

.partner-slider:after {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 17%;
  background: var(--partner-after-bg);
  pointer-events: none;
  z-index: 5;
}

.partner-slider.off:after,
.partner-slider.off:before {
  opacity: 0;
}

.partner-slider.off .embla__container {
  justify-content: space-between;
}

.game-section .grid-container {
  margin-bottom: 0;
}

.partner-slider:before {
  pointer-events: none;
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 17%;
  background: var(--partner-before-bg);
  z-index: 5;
}

.new-games-section {
  padding-bottom: 18px;
}

@media (max-width: 600px) {
  .new-games-section .block-title__svg {
    width: 18px;
    height: 18px;
  }

  .new-games-section {
    padding-bottom: 10px;
  }
}

@media (min-width: 769px) {
  .new-games-section .block-title__svg {
    width: 38px;
    height: 38px;
  }

  .new-games-section .game-head {
    padding-bottom: 5px;
  }
}

.popular-games-section {
  margin-top: 30px;
  padding-bottom: 29px;
}

.popular-games-section .block-title__svg {
  width: 63px;
  height: 61px;
  margin-right: 0;
  margin-left: -10px;
}

@media (max-width: 992px) {
  .partner-slider .nav__link {
    font-size: 12px;
  }

  .popular-games-section .block-title__svg {
    width: 54px;
    height: 51px;
  }
}

@media (max-width: 768px) {
  .popular-games-section .block-title__svg {
    width: 45px;
    height: 42px;

    margin-left: -8px;
  }

  .popular-games-section {
    margin-top: 15px;
  }
}

@media (max-width: 600px) {
  .popular-games-section .block-title__svg {
    margin-right: 3px;
    width: 24px;
    height: 24px;
    margin-left: -3px;
  }
}

@media (max-width: 850px) {
  .partner-slider .nav__link {
    font-size: 10px;
  }
}

.game-slider {
  margin: 13px 0 0;
}

.partner-slider .embla__button__svg {
  width: 33px;
  height: 25px;
}

@media only screen and (max-width: 1200px) {
  .partner-slider__svg {
    width: 90px;
    height: 30px;
  }

  .partner-slider {
    padding: 15px 0;
  }
}

@media only screen and (max-width: 992px) {
  .partner-slider__svg {
    width: 75px;
    height: 24px;
  }

  .partner-slider {
    padding: 12px 0;
  }
}

@media only screen and (max-width: 768px) {
  .partner-slider {
    padding: 10px 0;
  }
}

.main-page__game-slider .embla__button.embla__button--next {
  padding-right: 15px;
}

@media (max-width: 1330px) {
  .main-page__game-slider .embla__viewport {
    max-width: 1040px;
    margin: 0 auto;
  }

  .main-page__game-slider .embla__button.embla__button--next {
    right: 1vw;
    padding-right: 15px;

  }

  .main-page__game-slider .embla__button.embla__button--prev {
    left: 1vw;
  }
}

@media (max-width: 1171px) {
  .main-page__game-slider .embla__button.embla__button--next {
    right: 0.75vw;
  }

  .main-page__game-slider .embla__button.embla__button--prev {
    left: 0.75vw;
  }
}

@media (max-width: 1065px) {
  .main-page__game-slider .embla__viewport {
    max-width: 780px;
  }
}

@media (max-width: 800px) {
  .main-page__game-slider .embla__viewport {
    max-width: 520px;
  }
}


@media (max-width: 600px) {
  .main-page__game-slider .embla__viewport {
    max-width: 420px;
  }
}


@media (max-width: 500px) {
  .main-page__game-slider .embla__viewport {
    max-width: 380px;
  }

}
@media (max-width: 400px) {

  .main-page__game-slider .embla__slide  {
    opacity: 0.45;
    transition: .3s ease;
  }
  .main-page__game-slider .embla__slide.is-selected  {
    opacity: 1;
  }
}
