.select-language {
  width: 194px;
  font-size: 14px;
  position: relative;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.select-language__options {
  background-color: #eeeeee;
  border-radius: 5px;
  position: absolute;
  left: 0;
  width: 100%;
  display: none;
}

.select-language .current-option {
  position: relative;
  color: #fff;
  height: 46px;
  padding-left: 13px;
  display: flex;
  align-items: center;
}

.select-language__options.open {
  display: block;
}

.select-language .current-option:after {
  content: '';
  height: 0;
  width: 0;
  border-style: solid;
  border-color: #ffffff transparent transparent transparent;
  border-width: 5px;
  position: absolute;
  right: 15px;
  top: 50%;
  margin-top: -2.5px;
  pointer-events: none;
  transition: all 0.3s ease;
}

.select-language.active .current-option:after {
  margin-top: -8px;
  border-color: transparent transparent #fff transparent;
}

.select-language svg {
  height: 14px;
  width: 19px;
  margin-right: 14px;
}

.select-language .options-item {
  height: 46px;
  display: flex;
  align-items: center;
  padding-left: 13px;
  color: #58466f;
  transition: 0.3s ease;
}

.select-language .options-item:hover,
.select-language .options-item.active {
  background: var(--select-language-bgHover);
  color: white;
}
