.toast {
  position: fixed;
  right: 38px;
  top: 28px;
  min-width: 343px;
  max-width: 400px;
  min-height: 62px;
  padding: 25px 30px;
  background: var(--toast-bg);
  box-shadow: var(--toast-box-shadow);
  border-radius: 2px;
  z-index: 1000;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: var(--toast-color);
  animation-name: learn;
  opacity: 0;
}

.toast + .toast {
  margin-top: 20px;
  transform: translateY(100%);
}
.toast + .toast + .toast {
  margin-top: 20px;
  transform: translateY(200%);
}

.toast.error {
  background: var(--toast-bg-error);
}

.toast .close-btn {
  width: 24px;
  height: 24px;
  fill: white;
  top: 3px;
  right: 3px;
}

@media (max-width: 550px) {
  .toast {
    width: 100vw;
    top: 0;
    right: 0;
  }
}

@keyframes learn {
  0% {opacity: 1;}
  70% {opacity: 1;}
  100% {opacity: 0;}
}


