
.payment-info-wrap .failed-icon {
  width: 103px;
  height: 103px;
  margin-bottom: 15px;
}

.payment-info-wrap .chat-btn {
  cursor: pointer;
  text-decoration: underline;
}

.payment-info-wrap .chat-btn:hover {
  text-decoration: none;
}

@media (max-width: 600px) {
  .payment-info-wrap .failed-icon {
    width: 72px;
    height: 72px;
  }
}


@media (max-width: 600px) {
  .payment-info-content.failed {
    max-height: 55vh;
    padding-top: 50px;
  }
}






