.info-section+.game-section {
    padding-bottom: 0;
}

.join-turnament-btn {
    display: block;
    margin: auto;
}

@media (min-width: 992px) {
    .tournament-games .grid-item.lg-2 {
        width: 20%;
    }
    .tournament-games .game__wrap {
        height: 370px;
    }
}

@media (max-width: 600px) {
    .tournament-games .game-head {
        justify-content: center;
    }
    .tournament-games .block-title__svg {
        display: none;
    }
    .tournament-games .game-head__name {
        font-size: 26px;
        font-weight: 700;
        margin-bottom: 21px;
    }
    .tournament-section {
        padding-bottom: 32px;
    }
}

@media (max-width: 360px) {
    .tournament-games .game-head__name {
        font-size: 24px;
    }
}