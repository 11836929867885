.choices {
  position: relative;
  overflow: hidden;
  font-size: 16px;
  cursor: pointer;
}

.choices__item:hover {
  background-color: var(--field-bg);
  cursor: pointer;
}

.choices:focus {
  outline: none;
}

.choices__error {
  border: 1px solid var(--field-error-color);
  border-radius: 24px;
}

.choices:last-child {
  margin-bottom: 0;
}

.choices-placeholder {
  color: var(--choices-placeholder-color);
}

.choices.is-open {
  overflow: initial;
  position: relative;
  z-index: 2;
}

.choices.is-open:before {
  content: '';
  position: absolute;
  top: 0;
  z-index: -1;
  left: 0;
  width: 100%;
  height: 40px;
  background-color: var(--choices-dropdown-bg);
  border-radius: 24px 24px 0 0;
}

.choices__input {
  display: inline-block;
  vertical-align: baseline;
  background-color: var(--choices-dropdown-bg);
  font-size: 14px;
  margin-bottom: 5px;
  border: 0;
  border-radius: 0;
  width: 100%;
  padding: 4px 0 4px 8px;
  position: relative;
  color: #ffffff;
  border-bottom: 1px solid var(--field-bg);
}

.choices [hidden] {
  display: none !important;
}

.choices__inner {
  display: inline-block;
  vertical-align: top;
  color: #ffffff;
  width: 100%;
  background-color: var(--field-bg);
  padding: 13px 30px 11px 20px;
  border-radius: 24px;
  font-size: 14px;
  overflow: hidden;
}

.choices__list {
  margin: 0;
  padding-left: 0;
  list-style: none;
  overflow: hidden;
}

.choices__list--single {
  display: inline-block;
  width: 100%;
}

.choices__list--single .choices__item {
  width: 100%;
}

.choices__list--multiple {
  display: inline;
}

.choices__list--multiple .choices__item {
  display: inline-block;
  vertical-align: middle;
  border-radius: 2px;
  padding: 4px 10px;
  font-size: 12px;
  font-weight: 500;
  margin-right: 3.75px;
  margin-bottom: 3.75px;
  background-color: #00bcd4;
  border: 1px solid #00a5bb;
  color: #ffffff;
  word-break: break-all;
  box-sizing: border-box;
}

.choices__list--dropdown {
  visibility: hidden;
  z-index: 1;
  position: absolute;
  width: 100%;
  background-color: var(--choices-dropdown-bg);
  top: 100%;
  margin-top: -1px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  overflow-x: hidden;
  overflow-y: auto;
  word-break: break-all;
  will-change: visibility;
}

.choices__list::-webkit-scrollbar {
  width: 7px;
  background-color: var(--choices-dropdown-scrollbar-bg);
}

.choices__list::-webkit-scrollbar-thumb {
  background-color: var(--choices-dropdown-scrollbar-color);
  border-radius: 5px;
}

.choices__list--dropdown.is-active {
  visibility: visible;
}

.is-open .choices__list--dropdown {
  border-color: #b7b7b7;
}

.choices__list--dropdown .choices__list {
  position: relative;
  max-height: 300px;
  overflow: auto;
  overflow-x: hidden;
  --webkit-overflow-scrolling: touch;
  will-change: scroll-position;
}

.choices .choices__inner:after {
  content: '';
  height: 0;
  width: 0;
  border-style: solid;
  border-color: #ffffff transparent transparent transparent;
  border-width: 5px;
  position: absolute;
  right: 15px;
  top: 50%;
  margin-top: -2.5px;
  pointer-events: none;
}
.choices .choices__inner.disabled:after {
  display: none;
}

.choices.is-open .choices__inner:after {
  margin-top: -8px;
  border-color: transparent transparent #fff transparent;
}

.choices__list--dropdown .choices__item {
  position: relative;
  padding: 10px;
  font-size: 14px;
  white-space: nowrap;
  color: #ffffff;
}
