/*VARIABLES*/




/* GLOBAL STYLES */
html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  background-color: transparent;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

.centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

input[type='number'] {
  -moz-appearance:textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: none;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type='checkbox'],
[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none;
}

html,
body,
div,
span,
h1,
h2,
h3,
h4,
h5,
h6,
p,
em,
img,
strong,
sub,
sup,
b,
u,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
table,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
  background: transparent;
}

* {
  text-decoration: none;
  list-style: none;
  box-sizing: border-box;
}

body {
  font-family: 'Roboto';
}

body.overflow {
  overflow: hidden;
}

a {
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

a:hover,
a:active,
a:focus {
  text-decoration: none;
}

input {
  border: none;
  background-color: transparent;
  outline: none;
  -webkit-appearance: none;
}

textarea {
  resize: vertical;
  border: none;
  background-color: transparent;
  outline: none;
  -webkit-appearance: none;
}

button {
  cursor: pointer;
  border: none;
  background-color: transparent;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  outline: none;
  -webkit-appearance: none;
  padding: 0;
}

.pd0 {
  padding: 0;
}

select {
  cursor: pointer;
  border: none;
  background-color: transparent;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  outline: none;
  -webkit-appearance: none;
}

.status {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.status.success .status__name {
  color: var(--title-color);
}

.status.process .status__name {
  color: var(--table-name-color);
}

.status.error .status__name {
  color: var(--field-error-color);
}

.status__name {
  font-size: 14px;
}

.status__icon {
  width: 16px;
  height: 16px;
  margin-right: 5px;
}

.status__icon svg {
  width: 16px;
  height: 16px;
}

.loading {
  position: relative;
}


/* The moving element */

.loading::after {
  display: block;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  transform: translateX(-100%);
  background: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(rgba(142, 0, 255, 0.2)), to(transparent));
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.1), transparent);
  /* Adding animation */
  animation: loading 1.5s 1;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


/* Loading Animation */

@keyframes loading {
  100% {
    transform: translateX(100%);
  }
}

body {
  background: var(--main-bg);
}
