.jackpot-article--page {
  height: 410px;
}

.jackpot-article--page .jackpot-article__wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 20px 20px;
}


@media (max-width: 600px) {


  .jackpot-article--page {
    height: 250px;
  }

  .jackpot-article--page .jackpot-article__wrap {
    padding: 35px 20px 20px;
  }
}

@media (max-width: 400px) {
  .jackpot-article--page {
    height: 190px;
  }

  .jackpot-article--page .jackpot-article__wrap {
    padding: 25px 20px 20px;
  }

  .jackpot-article--page .jackpot-word {
    width: 50%;
  }
}
