.stock-card__wrap {
    width: 100%;
    background: #ffffff;
    overflow: hidden;
    min-height: 435px;
    height: 100%;
    border-radius: 5px;
}

.stock-card {
    padding-bottom: 40px;
    height: 100%;
}

.stock-section {
    margin-top: 63px;
}

.stock-card__img {
    width: 100%;
    height: 120px;
    border-radius: 5px;
    overflow: hidden;
}

.stock-card__img.loading::after {
    background: linear-gradient( 90deg, transparent, rgba(255, 255, 255, 1), transparent);
}

.stock-card__img.loading {
    background: #e2e7ee;
}

.stock-card__img img {
    width: 100%;
    height: 100%;
}

.stock-card__title {
    display: block;
    font-size: 20px;
    line-height: 140%;
    color: #25133c;
    font-weight: 700;
    margin-bottom: 15px;
}

.stock-card__info {
    padding: 12px 23px 18px;
    height: calc(100% - 120px);
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
}

.stock-card__title {
    display: block;
    font-size: 20px;
    line-height: 140%;
    color: #25133c;
    font-weight: 700;
    margin-bottom: 15px;
}

.stock-card__descr {
    font-size: 14px;
    line-height: 129%;
    color: #58466f;
    font-weight: 400;
    margin-bottom: 30px;
    overflow-x: hidden;
}

.stock-card__more-wrap {
    width: 100%;
    margin-bottom: 20px;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
}

.stock-card__btns {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
}

.stock-card .btn {
    max-width: 230px;
    width: 100%;
}

@media only screen and (max-width: 500px) {
    .grid-stock-item {
        margin: 0 auto;
        width: 95% !important;
    }
    .stock-card__wrap {
        min-height: 400px;
    }
}

@media only screen and (max-width: 992px) {
    .stock-card__info {
        padding: 12px;
    }
}

.stock-mob {
    width: 100%;
    margin-bottom: 20px;
}

.stock-mob.loading {
    height: 120px;
    background: #e2e7ee;
}

.stock-mob__wrap {
    width: 100%;
    overflow: hidden;
    position: relative;
}

.stock-mob__img {
    width: 100%;
    height: 100px;
    position: relative;
    overflow: hidden;
}

.stock-mob__img img {
    width: 100%;
    height: 100%;
}

.stock-mob__img:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    box-shadow: inset 0px -40px 60px rgba(0, 0, 0, 0.75);
}

@media (min-width: 500px) {
    .stock-mob__img {
        height: 120px;
    }
}

@media (max-width: 600px) {
    .stock-section {
        margin-top: 24px;
    }
}

.stock-mob__title {
    display: block;
    font-size: 12px;
    line-height: 1.33;
    color: #ffffff;
    font-weight: 700;
    position: absolute;
    left: 19px;
    bottom: 10px;
    right: 19px;
}