.grid-container {
    display: flex;
    flex-wrap: wrap;
    padding: 12px 0 0;
    margin: 0 -7px 25px;
}

.grid-item {
    display: flex;
    flex-direction: column;
}


/* SPACING-1 */

.spacing-1 {
    margin-left: -5px;
    margin-right: -5px;
}

.spacing-1>.grid-item {
    padding: 0 5px;
}


/* SPACING-2 */

.spacing-2 {
    margin-left: -10px;
    margin-right: -10px;
}

.spacing-2>.grid-item {
    padding: 0 10px;
}


/* SPACING-3 */

.spacing-3 {
    margin-left: -15px;
    margin-right: -15px;
}

.spacing-3>.grid-item {
    padding: 0 15px;
}


/* SPACING-4 */

.spacing-4 {
    margin-left: -20px;
    margin-right: -20px;
}

.spacing-4>.grid-item {
    padding: 0 20px;
}


/* SPACING-5 */

.spacing-5 {
    margin-left: -25px;
    margin-right: -25px;
}

.spacing-5>.grid-item {
    padding: 0 25px;
}

.grid-item.xs-12 {
    width: 100%;
}

.grid-item.xs-11 {
    width: 91.66%;
}

.grid-item.xs-10 {
    width: 83.33%;
}

.grid-item.xs-9 {
    width: 75%;
}

.grid-item.xs-8 {
    width: 66.66%;
}

.grid-item.xs-7 {
    width: 58.33%;
}

.grid-item.xs-6 {
    width: 50%;
}

.grid-item.xs-5 {
    width: 41.66%;
}

.grid-item.xs-4 {
    width: 33.33%;
}

.grid-item.xs-3 {
    width: 25%;
}

.grid-item.xs-2 {
    width: 16.66%;
}

.grid-item.xs-1 {
    width: 8.33%;
}

@media only screen and (min-width: 600px) {
    .grid-item.sm-12 {
        width: 100%;
    }
    .grid-item.sm-11 {
        width: 91.66%;
    }
    .grid-item.sm-10 {
        width: 83.33%;
    }
    .grid-item.sm-9 {
        width: 75%;
    }
    .grid-item.sm-8 {
        width: 66.66%;
    }
    .grid-item.sm-7 {
        width: 58.33%;
    }
    .grid-item.sm-6 {
        width: 50%;
    }
    .grid-item.sm-5 {
        width: 41.66%;
    }
    .grid-item.sm-4 {
        width: 33.33%;
    }
    .grid-item.sm-3 {
        width: 25%;
    }
    .grid-item.sm-2 {
        width: 16.66%;
    }
    .grid-item.sm-1 {
        width: 8.33%;
    }
}

@media only screen and (min-width: 768px) {
    .grid-item.md-12 {
        width: 100%;
    }
    .grid-item.md-11 {
        width: 91.66%;
    }
    .grid-item.md-10 {
        width: 83.33%;
    }
    .grid-item.md-9 {
        width: 75%;
    }
    .grid-item.md-8 {
        width: 66.66%;
    }
    .grid-item.md-7 {
        width: 58.33%;
    }
    .grid-item.md-6 {
        width: 50%;
    }
    .grid-item.md-5 {
        width: 41.66%;
    }
    .grid-item.md-4 {
        width: 33.33%;
    }
    .grid-item.md-3 {
        width: 25%;
    }
    .grid-item.md-2 {
        width: 16.66%;
    }
    .grid-item.md-1 {
        width: 8.33%;
    }
}

@media only screen and (min-width: 992px) {
    .grid-item.lg-12 {
        width: 100%;
    }
    .grid-item.lg-11 {
        width: 91.66%;
    }
    .grid-item.lg-10 {
        width: 83.33%;
    }
    .grid-item.lg-9 {
        width: 75%;
    }
    .grid-item.lg-8 {
        width: 66.66%;
    }
    .grid-item.lg-7 {
        width: 58.33%;
    }
    .grid-item.lg-6 {
        width: 50%;
    }
    .grid-item.lg-5 {
        width: 41.66%;
    }
    .grid-item.lg-4 {
        width: 33.33%;
    }
    .grid-item.lg-3 {
        width: 25%;
    }
    .grid-item.lg-2 {
        width: 16.66%;
    }
    .grid-item.lg-1 {
        width: 8.33%;
    }
}

@media only screen and (min-width: 1200px) {
    .grid-item.xl-12 {
        width: 100%;
    }
    .grid-item.xl-11 {
        width: 91.66%;
    }
    .grid-item.xl-10 {
        width: 83.33%;
    }
    .grid-item.xl-9 {
        width: 75%;
    }
    .grid-item.xl-8 {
        width: 66.66%;
    }
    .grid-item.xl-7 {
        width: 58.33%;
    }
    .grid-item.xl-6 {
        width: 50%;
    }
    .grid-item.xl-5 {
        width: 41.66%;
    }
    .grid-item.xl-4 {
        width: 33.33%;
    }
    .grid-item.xl-3 {
        width: 25%;
    }
    .grid-item.xl-2 {
        width: 16.66%;
    }
    .grid-item.xl-1 {
        width: 8.33%;
    }
}