.input {
  border: none;
  background-color: transparent;
  outline: none;
  -webkit-appearance: none;
  width: 100%;
  background-color: var(--field-bg);
  border-radius: 24px;
  padding: 11px 44px 11px 19px;
  border: 1px solid var(--field-bg);
  font-size: 14px;
  color: #ffffff;
}

.input:focus {
  border: 1px solid var(--title-color);
}

.input:disabled {
  cursor: not-allowed;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

input:focus::-webkit-input-placeholder {
  color: transparent;
}

input:focus::-ms-input-placeholder {
  color: transparent;
}

input:focus:-ms-input-placeholder {
  color: transparent;
}

input:focus::placeholder {
  color: transparent;
}
