.article__title {
    font-weight: 700;
    font-size: 30px;
    line-height: 1.6;
    text-align: center;
    text-transform: uppercase;
    color: #000000;
    padding-bottom: 25px;
    margin-bottom: 30px;
    border-bottom: 1px solid #e0dfe2;
}

.article__descr {
    margin: 0;
    font-size: 18px;
    line-height: 1.56;
    margin-bottom: 25px;
    color: var(--article-text-color);
}

.article__descr a {
    text-decoration: underline;
}

.article__sub-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 1.56;
    color: var(--article-text-color);
    margin-bottom: 25px;
}

.article__sub-title_mb {
    margin-bottom: 0;
}

.article__list_mb .article__descr {
    margin-bottom: 0;
}

.article {
    overflow: hidden;
    border-radius: 5px;
    margin-bottom: 77px;
    background: white
}

.article.skeleton .article__img {
    width: 100%;
    height: 385px;
}

.article.skeleton .article__info {
    min-height: 500px;
}

.article_violet {
    background-color: var(--article-violet-bg);
    padding: 4px 4px 14px 4px;
}

.article_violet .article__wrap {
    background-color: var(--article-wrap-bg);
    border-radius: 5px;
}

.article__img {
    position: relative;
}

@media (min-width: 1200px) {
  .article__img {
    min-height: 365px;
  }
}


.article__img img {
    max-height: 365px;
    width: 100%;
    display: block;
    border-radius: 5px;
}

.article__img-mask {
    position: relative;
}

.article__img-mask:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 50%;
    bottom: 0;
    left: 0;
    z-index: 1;
    border-radius: 5px;
    background: var(--article-img-mask-bg);
}

.article__info {
    padding: 29px 115px 68px;
}

.article__info .btn {
    display: block;
    width: 318px;
    margin: 0 auto 25px;
    font-size: 24px;
    padding: 18px;
    line-height: 1;
    border-radius: 48px;
}

.article__title_white {
    color: #ffffff;
    text-transform: none;
    border-bottom: 1px solid var(--article-title-border-color);
}

.article__sub-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 1.56;
    color: var(--article-text-color);
    margin-bottom: 25px;
}

.article__sub-title_mb {
    margin-bottom: 0;
}

.article__sub-title_white {
    color: #ffffff;
}

.article__sub-title_gray {
    color: var(--description-text-color);
}

.article__list_mb {
    margin-bottom: 25px;
}

.article__list_mb .article__text {
    margin-bottom: 0;
}

.article__list-disk {
    margin-bottom: 25px;
}

.article__list-item {
    font-size: 18px;
    line-height: 1.56;
    color: var(--description-text-color);
    position: relative;
    padding-left: 18px;
}

.article__list-item:before {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    background: var(--title-color);
}

.article__text-info {
    display: inline-block;
    font-weight: 700;
    font-size: 18px;
    line-height: 1.56;
    color: var(--article-text-color);
    margin-right: 5px;
}

.article__descr_dark {
    color: #000000;
    margin-bottom: 0;
}

.article__descr_gray {
    color: var(--description-text-color);
    margin-bottom: 0;
}

.article__nav {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    position: absolute;
    bottom: 34px;
    left: 50%;
    z-index: 2;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}

.article__nav .btn {
    min-width: 240px;
    height: 46px;
    font-size: 20px;
    padding: 12px 20px;
    margin: 0 31px;
}

.article__nav .btn.login {
    padding: 10px 20px;
}


/* Small devices (portrait tablets and large phones, 600px and up) */

@media only screen and (max-width: 992px) {
    .article__descr {
        margin-bottom: 20px;
    }
    .article {
        margin-bottom: 40px;
    }
    .article__info {
        padding-left: 80px;
        padding-right: 80px;
    }
}


/* Medium devices (landscape tablets, 768px and up) */

@media only screen and (max-width: 768px) {
    .article__info {
        padding: 20px 20px 10px;
    }
    .article {
        margin-bottom: 30px;
    }
    .article__title {
        font-size: 24px;
        padding-bottom: 25px;
        margin-bottom: 30px;
    }
    .article__sub-title {
        font-size: 14px;
        margin-bottom: 15px;
    }
    .article__text-info {
        font-size: 18px;
    }
    .article__descr {
        font-size: 14px;
    }
    .article__info {
        padding-left: 60px;
        padding-right: 60px;
    }
}

@media only screen and (max-width: 600px) {
    .article__text-info {
        font-size: 16px;
    }
    .article {
        margin-bottom: 20px;
    }
    .article__info {
        padding-left: 40px;
        padding-right: 40px;
    }
}

@media only screen and (max-width: 500px) {
    .article__info {
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media only screen and (max-width: 460px) {
    .article__title {
        font-size: 20px;
        padding-bottom: 20px;
        margin-bottom: 20px;
    }
}
