.main-form__wrap {
  padding: 30px 15px;
  border-bottom: 2px solid var(--main-form-wrap-border-color);
}

.main-form__wrap.no-border {
  border: none !important;
}

.main-form__wrap .primary {
  min-width: 200px;
  font-size: 15px;
  padding: 12px 10px 11px;
}

.main-form__main-wrap.column-style {
  flex-direction: column;
}

.main-form__main-wrap {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 20px;
}

.cashbox-mainform .main-form__wrap {
  border-bottom: none;
}

.acc-content.mob .main-form__bonus-head {
  padding: 15px 0;
  margin: 0 auto 10px;
  max-width: 510px;
}

.main-form__main-wrap_sms {
  margin-top: 30px;
  margin-bottom: 0;
}

.main-form__main-wrap_method {
  -webkit-box-align: start;
  align-items: flex-start;
}

.main-form__label {
  font-size: 14px;
  color: var(--title-color);
  max-width: 135px;
  width: 100%;
}

.main-form__label_white {
  color: #ffffff;
}

.main-form__edit {
  position: absolute;
  content: '';
  width: 16px;
  height: 16px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 14px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.75 3.58333L14.125 5.20833L10.7917 1.875L12.4167 0.25C12.5833 0.0833333 12.7917 0 13.0417 0C13.2917 0 13.5 0.0833333 13.6667 0.25L15.75 2.33333C15.9167 2.5 16 2.70833 16 2.95833C16 3.20833 15.9167 3.41667 15.75 3.58333ZM0 12.6667L9.83333 2.83333L13.1667 6.16667L3.33333 16H0V12.6667Z' fill='%23B48ED6'/%3E%3C/svg%3E%0A");
}

.main-form__edit.password {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='14' viewBox='0 0 16 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.3849 2.58487L0.727096 0.927062L1.65416 0L14.5422 12.8916L13.6151 13.815L11.4956 11.6919L11.1866 11.3829C10.2013 11.7791 9.12522 11.9973 7.99819 11.9973C4.36265 11.9973 1.25791 9.73596 0 6.54397C0.567144 5.10068 1.51602 3.85368 2.71575 2.91571L2.3849 2.58487ZM5.8714 6.06772L4.74802 4.94434C4.50808 5.43149 4.36265 5.96956 4.36265 6.54397C4.36265 8.55078 5.99138 10.1795 7.99819 10.1795C8.57263 10.1795 9.11067 10.0341 9.59421 9.79414L8.47083 8.67076C8.31813 8.70348 8.16179 8.72529 7.99819 8.72529C6.79482 8.72529 5.81687 7.74734 5.81687 6.54397C5.81687 6.38037 5.83868 6.22406 5.8714 6.06772ZM7.99806 2.90858C10.0049 2.90858 11.6336 4.53731 11.6336 6.54413C11.6336 7.01311 11.5391 7.46028 11.3755 7.87109L13.5023 9.99788C14.6002 9.08172 15.4655 7.89655 15.9999 6.54413C14.7384 3.35214 11.6373 1.09082 7.9981 1.09082C6.98013 1.09082 6.00583 1.27259 5.10059 1.59978L6.67114 3.16669C7.08191 3.00674 7.52908 2.90858 7.99806 2.90858ZM7.87817 4.3737L10.1686 6.66409L10.1795 6.54412C10.1795 5.34074 9.20152 4.36279 7.99815 4.36279L7.87817 4.3737Z' fill='%23B48ED6'/%3E%3C/svg%3E%0A");
}

.main-form__sms-title {
  font-size: 14px;
  text-transform: uppercase;
  color: #a4a4a4;
}

.main-form__sms-wrap {
  max-width: 415px;
  width: 100%;
  display: -webkit-box;
  display: flex;
}

.main-form__sms-wrap .field {
  max-width: 116px;
  margin-right: 14px;
}

.main-form__sms-wrap .field__input {
  width: 100%;
  background: #555555;
  border-color: #555555;
  padding: 12px 9px;
}

.main-form__sms-wrap .field__input::-webkit-input-placeholder {
  font-size: 14px;
  letter-spacing: -0.02em;
  color: #a4a4a4;
}

.main-form__sms-wrap .field__input:-ms-input-placeholder {
  font-size: 14px;
  letter-spacing: -0.02em;
  color: #a4a4a4;
}

.main-form__sms-wrap .field__input::-ms-input-placeholder {
  font-size: 14px;
  letter-spacing: -0.02em;
  color: #a4a4a4;
}

.main-form__sms-wrap .field__input::placeholder {
  font-size: 14px;
  letter-spacing: -0.02em;
  color: #a4a4a4;
}

.main-form__btn {
  font-size: 13px;
  color: var(--title-color);
  padding: 0;
}

.main-form__btn:hover {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

.main-form__select-row {
  position: relative;
  display: -webkit-box;
  display: flex;
  max-width: 415px;
  width: 100%;
}

.main-form__select-row .choices {
  margin-right: 13px;
}

.main-form__select-row .choices:nth-child(1) {
  /* width: 80px; */
}

.main-form__select-row .choices:nth-child(2) {
  width: 116px;
}

.main-form__select-row .choices:nth-child(3) {
  width: 104px;
}

.main-form__select-wrap {
  max-width: 415px;
  width: 100%;
  position: relative;
}

.main-form__select-wrap_transaction {
  max-width: 150px;
}

.main-form__checkbox-wrap {
  max-width: 415px;
  width: 100%;
  display: -webkit-box;
  display: flex;
  margin: 10px 0;
}

.main-form__input-check {
  display: none;
}

.main-form__checkbox {
  margin-right: 28px;
}

.main-form__checkbox:last-child {
  margin-right: 0;
}

.main-form__check-rules {
  margin-bottom: 15px;
}

.main-form__rules-link {
  font-size: 14px;
  color: var(--title-color);
  margin-left: 5px;
}

.main-form__rules-link:hover {
  text-decoration: underline;
}

.main-form__label-rules {
  font-size: 14px;
  cursor: pointer;
  color: #ffffff;
  padding-left: 32px;
  position: relative;
}

.main-form__label-rules:before {
  position: absolute;
  content: '';
  width: 21px;
  height: 21px;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  background: var(--main-form-present-bg);
  border: 1px solid var(--main-form-present-border-color);
  box-shadow: inset 4px 4px 4px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

.main-form__label-rules:after {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  position: absolute;
  content: '';
  width: 13px;
  height: 11px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 5px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0;
  background-image: url("data:image/svg+xml,%3Csvg width='13' height='11' viewBox='0 0 13 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.03778 10.0571C4.42756 10.5471 5.16664 10.5624 5.57643 10.0891L12.4837 2.10991C12.7831 1.76409 12.7937 1.2541 12.5089 0.896163C12.1385 0.430463 11.436 0.415875 11.0465 0.865792L5.6119 7.14377C5.2076 7.61081 4.48076 7.60323 4.08629 7.12787L1.95879 4.56412C1.58021 4.10792 0.880272 4.10792 0.5017 4.56412C0.210793 4.91468 0.210792 5.42271 0.501699 5.77327L3.60195 9.50923L4.03778 10.0571Z' fill='%23FFF96B'/%3E%3C/svg%3E%0A");
}

.main-form__sub-info {
  font-size: 12px;
  color: var(--description-title-color);
  line-height: 1.4;
  margin-bottom: 11px;
}

.main-form__label-check {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  cursor: pointer;
  font-size: 14px;
  color: var(--title-color);
  position: relative;
  padding-left: 30px;
}

.main-form__label-check:after {
  position: absolute;
  content: '';
  top: 50%;
  left: 2px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 2px solid var(--radio-unchecked-color);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.main-form__label-check:before {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  position: absolute;
  content: '';
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 8px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #fffa88;
  opacity: 0;
}

.main-form input[type='radio']:checked + label:after {
  border-color: var(--radio-checked-color);
}

.main-form input[type='radio']:checked + label:before {
  opacity: 1;
}

.main-form input[type='checkbox']:checked + label.main-form__label-rules:after {
  opacity: 1;
}

/*.main-form__input-file {*/
/*  font-size: 12px;*/
/*  color: #a394b0;*/
/*  background: #522d74;*/
/*  border: 1px dashed #b48ed6;*/
/*  border-radius: 12px;*/
/*}*/

.main-form__present-wrap {
  padding: 0 0 0 145px;
}

.main-form__present-wrap .btn.primary {
  margin: 0;
}

.main-form__gift-wrap {
  max-width: 415px;
  width: 100%;
  margin-left: auto;
}

.main-form__gift-wrap .primary {
  margin: 0;
}

.main-form__remind {
  font-size: 14px;
  text-align: center;
  color: var(--title-color);
  text-decoration: underline;
}

.main-form__present-checkbox {
  margin-bottom: 20px;
}

.main-form__present-label span.main-form__present-count {
  font-weight: 700 ;
  color: #fff;
}

.main-form__method-wrap .pay-method {
  justify-self: center;
}

.main-form .primary {
  margin-left: 145px;
}

.payments-error {
  width: 100%;
  align-items: center;
  color: var(--field-error-color);
  font-size: 12px;
  grid-column-start: 1;
  grid-column-end: 5;
}

.payments-error svg {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.main-form__present-label {
  font-size: 12px;
  font-weight: 700;
  color: #ffffff;
  padding-left: 25px;
  position: relative;
  cursor: pointer;
}

.main-form__present-label:before {
  position: absolute;
  content: '';
  width: 16px;
  height: 16px;
  background: var(--main-form-present-bg);
  border: 1px solid var(--main-form-present-border-color);
  border-radius: 5px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.main-form__present-label:after {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  position: absolute;
  content: '';
  width: 12px;
  height: 12px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 3px;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='iso-8859-1'%3F%3E%3C!-- Generator: Adobe Illustrator 18.1.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 17.837 17.837' style='enable-background:new 0 0 17.837 17.837;' xml:space='preserve'%3E%3Cg%3E%3Cpath style='fill:%23ffffff;' d='M16.145,2.571c-0.272-0.273-0.718-0.273-0.99,0L6.92,10.804l-4.241-4.27 c-0.272-0.274-0.715-0.274-0.989,0L0.204,8.019c-0.272,0.271-0.272,0.717,0,0.99l6.217,6.258c0.272,0.271,0.715,0.271,0.99,0 L17.63,5.047c0.276-0.273,0.276-0.72,0-0.994L16.145,2.571z'/%3E%3C/g%3E%3C/svg%3E%0A");
  opacity: 0;
}

.main-form__present-label span {
  color: var(--description-text-color);
  font-weight: 400;
  margin-left: 3px;
}

.main-form
  input[type='checkbox']:checked
  + label.main-form__present-label:after {
  opacity: 1;
}

.main-form__sum-wrap {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 25px;
}

.main-form__filer-wrap {
  padding: 15px 10px;
  border-bottom: 2px solid var(--acc-border-color);
  margin-bottom: 10px;
}

.main-form__filer-select {
  font-size: 14px;
  width: 100%;
  color: #ffffff;
}

.main-form__filer-select > option {
  color: #000;
}

.main-form__document {
  max-width: 415px;
  width: 100%;
  margin: 0 0 20px auto;
}

/*.main-form__bonus-head {*/
/*  padding: 15px 0 22px;*/
/*  margin-bottom: 19px;*/
/*  border-bottom: 1px solid #543975;*/
/*}*/

.main-form__bonus-head .main-form__label {
  display: block;
  margin-bottom: 8px;
}

.main-form__soc-wrap {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  margin-top: 18px;
}

.main-form__soc-title {
  margin-bottom: 8px;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
}

.main-form__bonus-wrap {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.main-form__bonus-wrap .balance {
  padding-top: 5px;
  margin-left: auto;
}

.main-form__bonus-wrap .field {
  max-width: 100%;
  width: auto;
}

.main-form__bonus-wrap .field__input {
  width: 160px;
  padding-right: 10px;
}

.main-form__bonus-wrap .btn {
  min-width: 0;
  margin: 0 0 0 11px;
  padding-right: 12px;
  padding-left: 12px;
}

.main-form .file {
  max-width: 415px;
  width: 100%;
  margin: 0 0 18px auto;
}

.main-form__points {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: start;
  align-items: flex-start;
  margin-bottom: 18px;
}

.main-form__points .btn {
  font-size: 12px;
  padding: 14px 14px 13px;
}

/*.main-form__point-input {*/
/*  background: #dedede;*/
/*  border-radius: 24px;*/
/*  max-width: 160px;*/
/*  width: 100%;*/
/*  margin-right: 20px;*/
/*  padding: 12px 20px;*/
/*  font-size: 14px;*/
/*  color: #333333;*/
/*}*/

/*.main-form__point-input::-webkit-input-placeholder {*/
/*  color: #989898;*/
/*}*/

/*.main-form__point-input:-ms-input-placeholder {*/
/*  color: #989898;*/
/*}*/

/*.main-form__point-input::-ms-input-placeholder {*/
/*  color: #989898;*/
/*}*/

/*.main-form__point-input::placeholder {*/
/*  color: #989898;*/
/*}*/

@media only screen and (min-width: 768px) and (max-width: 900px) {
  .main-form__main-wrap {
    flex-direction: column;
  }
  .main-form__present-wrap {
    padding: 0;
  }
  .main-form__label {
    max-width: 100%;
    margin-bottom: 5px;
  }
  .main-form__main-wrap .field {
    max-width: 100%;
  }
  .main-form__select-row,
  .main-form__sms-wrap,
  .main-form__select-wrap,
  .main-form__checkbox-wrap,
  .main-form__document,
  .main-form .file {
    max-width: 100%;
  }
  .main-form__wrap .primary {
    margin: 0 auto;
    display: block;
  }
  .main-form__bonus-wrap {
    margin-top: 5px;
    flex-wrap: wrap;
  }
  .main-form__bonus-wrap .field {
    width: 45%;
  }
  .main-form__bonus-wrap .field input {
    width: 100%;
  }
  .main-form__bonus-wrap .btn {
    width: 45%;
  }
  .main-form__bonus-wrap .balance {
    margin: 10px 0 0 0;
  }
}

@media (max-width: 600px) {
  .main-form__main-wrap {
    flex-direction: column;
  }
  .main-form__present-wrap {
    padding: 0;
  }
  .main-form__label {
    max-width: 100%;
    margin-bottom: 5px;
  }
  .main-form__main-wrap .field {
    max-width: 100%;
  }
  .main-form__select-row,
  .main-form__sms-wrap,
  .main-form__select-wrap,
  .main-form__checkbox-wrap,
  .main-form__document,
  .main-form .file {
    max-width: 100%;
  }
  .main-form__wrap .primary {
    margin: 0 auto;
    display: block;
  }
  .main-form__sms-wrap {
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .main-form__sms-wrap .field {
    width: 47%;
    margin-right: 0px;
  }
  .main-form__sms-wrap .btn {
    width: 47%;
    margin-right: 0 !important;
  }
  .main-form__btn {
    width: 100%;
    margin-top: 12px;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .main-form__bonus-wrap {
    margin-top: 5px;
    flex-wrap: wrap;
  }
  .main-form__bonus-wrap .field {
    width: 45%;
  }
  .main-form__bonus-wrap .field input {
    width: 100%;
  }
  .main-form__bonus-wrap .btn {
    width: 45%;
  }
  .main-form__bonus-wrap .balance {
    margin: 10px 0 0 0;
  }
}

@media (max-width: 360px) {
  .main-form__bonus-wrap .btn {
    font-size: 13px;
  }
  .main-form__points {
    flex-wrap: wrap;
    width: 80%;
    margin: 0 auto;
    justify-content: center;
  }
  .main-form__points > * {
    margin-bottom: 10px;
  }
  .main-form__points .btn {
    font-size: 11px;
    -size: 11px;
  }
  .main-form__points input {
    width: 240px;
    max-width: 100%;
    margin-right: 0;
  }
}
