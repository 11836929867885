.captcha-block {
  padding: 0;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
}

.captcha-block .captcha-block__wrap {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.captcha-block__wrap .captcha-block__code {
  width: 86px;
  height: 38px;
  margin-right: 25px;
  -webkit-box-flex: 0;
  flex: none;
}

.captcha-block__code img {
  width: 100%;
  height: 100%;
}

.captcha-block__wrap .captcha-block__update-btn {
  width: 46px;
  height: 38px;
  margin-right: 15px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  background-color: #d19411;
  display: block;
  -webkit-box-flex: 0;
  flex: none;
}

.captcha-block__update-btn svg {
  width: 17px;
  height: 17px;
  fill: #ffffff;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.captcha-block__update-btn:hover svg {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
