.jackpot__inner .lottery_game {
  width: 250px;
  -webkit-box-flex: 0;
  flex: none;
}

.jackpot-table {
  max-width: 1020px;
  margin: 0 auto;
}

.jackpot-table__number {
  padding-left: 20px;
  width: 30% !important;
}

.jackpot-bg-image {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: fill;
  z-index: 0;
}

.jackpot-word {
  z-index: 10;
}

.jackpot-article__wrap .upper-section {
  z-index: 10;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}


.jackpot-table__player {
  width: 26% !important;
}

.jackpot-table__date {
  width: 31% !important;
}

.jackpot-table__prize {
  width: 13% !important;
}

.tour-table__col.jackpot-table__player {
  color: white;
}

.tour-table__col.jackpot-table__date {
  color: white;
}

.tour-table__col.jackpot-table__prize {
  font-weight: 600;
  color: white;
}

.jackpot-table .tour-table__head .tour-table__col {
    color: var(--description-text-color) !important;
}

.jackpot-article__wrap .btn {
  min-width: 112px;
}

.jackpot-article {
  width: 100%;
  margin-right: 12px;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 5px;
  overflow: hidden;
}

.jackpot__inner {
  display: flex;
  justify-content: space-between;
}

.jackpot-article .btn {
  z-index: 10;
}

.jackpot-article__wrap {
  display: -webkit-box;
  display: flex;
  z-index: 2;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  padding: 50px 20px 20px;
  justify-content: space-between;
  height: 100%;
}

.jackpot-article__wrap .footer-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.jackpot-article__wrap .section-title {
  z-index: 10;
  margin-bottom: 30px;
}

.jackpot-article__img {
  width: 100%;
  display: block;
}

.jackpot-article .lottery {
  margin-bottom: 30px;
}

.jackpot-article .lottery__wrap {
  padding: 20px 71px 16px;
}

.jackpot-article .lottery .decor-abs.right {
  top: auto;
  right: 100px;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  bottom: -43px;
  width: 10px;
}

@media (min-width: 600px) {


  .jackpot-article .btn {
    min-width: 160px;
    z-index: 10;
  }
}

.jackpot-article .btn {
  font-size: 18px;
}

.jackpot:last-child .jackpot__wrap {
  border-bottom: none;
  box-shadow: none;
}

.jackpot__name {
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  text-transform: uppercase;
    color: var(--title-color);
  padding: 24px 10px 21px;
    text-shadow: var(--link-text-shadow);
  margin-bottom: 2px;
    background-color: var(--acc-sidemenu-bg);
}

.jackpot__item {
  height: 70px;
  padding: 15px 10px 13px;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  border-bottom: 2px solid #402a5a;
    background-color: var(--acc-sidemenu-bg);
}

.jackpot__item:last-child {
  border-bottom: none;
  box-shadow: none;
}

.jackpot__title {
  font-size: 18px;
  line-height: 1.35;
  color: #ffffff;
}

.jackpot__row {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.jackpot__text {
  font-size: 12px;
  line-height: 1;
  color: #787878;
}

.jackpot__info {
  font-size: 16px;
  font-weight: 700;
    color: var(--link-color);
  line-height: 1;
}

.jackpot__inner .lottery__wrap,
.jackpot-article--page .lottery__wrap {
    background-color: var(--article-wrap-bg);
}

.jackpot__inner .decor-abs,
.jackpot-article--page .decor-abs {
  display: block;
}

.jackpot__inner .lottery,
.jackpot-article--page .lottery {
    background: var(--winners-bg);
  padding: 7px;
}

.lottery__count.loading {
  height: 45px;
  display: block;
  width: 300px;
}

@media (min-width: 768px) and (max-width: 900px) {
  .jackpot__inner .lottery__wrap,
  .jackpot-article--page .lottery__wrap {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media only screen and (max-width: 768px) {
  .jackpot-article {
    margin-right: 0;
  }

  .jackpot__inner .jackpot-article__wrap,
  .jackpot-article--page .jackpot-article__wrap {
    padding: 38px 20px 20px;
  }
}

@media only screen and (max-width: 680px) {
  .jackpot__inner .jackpot-article__wrap,
  .jackpot-article--page .jackpot-article__wrap {
    padding: 38px 20px 20px;
  }
}

@media only screen and (max-width: 600px) {
  .jackpot-article__wrap {
    padding: 30px 20px;
  }

  .jackpot-word {
    width: 65%;
    margin-bottom: 10px;
  }

  .jackpot-article__wrap .section-title {
    margin-bottom: 10px;
  }

  .jackpot-article__wrap .btn {
    width: auto;
    min-width: 150px;
    height: auto;
    padding: 10px 27px;
    font-size: 14px;
  }

  .jackpot__inner .btn,
  .jackpot-article--page .btn {
    font-size: 12px;
  }

  .jackpot__inner .lottery__wrap,
  .jackpot-article--page .lottery__wrap {
    padding: 15px 45px 11px;
  }

  .jackpot__inner .lottery__count,
  .jackpot-article--page .lottery__count {
    font-size: 36px;
  }

  .jackpot__inner .lottery,
  .jackpot-article--page .lottery {
    padding: 7px;
    margin-bottom: 11px !important;
  }

  .lottery__count.loading {
    height: 38px;
    display: block;
    width: 180px;
  }

  .jackpot__inner .jackpot-article__wrap,
  .jackpot-article--page .jackpot-article__wrap {
    padding: 25px 20px 11px;
  }

  /* TABLE */
  .jackpot-table__number {
    width: 15% !important;
  }

  .jackpot-table__player {
    width: 27% !important;
  }

  .jackpot-table__date {
    width: 28% !important;
  }

  .jackpot-table__prize {
    width: 30% !important;
  }
}

@media only screen and (max-width: 480px) {
  .jackpot-article__wrap {
    padding: 15px 20px 11px;
  }

  .jackpot__inner .btn,
  .jackpot-article--page .btn {
    font-size: 12px;
  }

  .jackpot__inner .lottery__count,
  .jackpot-article--page .lottery__count {
    font-size: 24px;
  }

  .jackpot__inner .lottery,
  .jackpot-article--page .lottery {
    background: linear-gradient(0deg, #a10acc, #f20169);
    padding: 7px;
    margin-bottom: 20px !important;
  }

  .jackpot__inner .jackpot-article__wrap,
  .jackpot-article--page .jackpot-article__wrap {
    padding: 15px 20px 20px;
  }
}
