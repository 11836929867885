.bonus-card {
  background-color: var(--bonus-card-bg);
  padding: 4px 4px 14px 4px;
  border-radius: 5px;
  margin: 0 auto;
  width: 248px;
  height: 100%;
  flex-grow: 1;
  display: flex;
  position: relative;
  flex-direction: column;
}


.player-bonus-card__info-item.front-side-wager {
  justify-content: center;
  margin-bottom: 6px;
}

@media (min-width: 601px) {
  .bonus-card__title.percent,
  .bonus-card__title.giftspins {
    font-size: 36px;
  }
}

.bonus-card__title.sum {
  font-size: 24px;
}

.bonus-card__title.sum,
.bonus-card__title.percent,
.bonus-card__title.giftspins {
  margin: 10px 0;
}


.bonus-card.welcome-bonus {
  background-color: #fff;
  position: relative;
}

.bonus-card .btn.disabled {
  margin-right: 0;
}

.bonus-card__info .cashback-descr {
  margin-top: 5px;

  color: var(--bonus-card-description-color);
  font-size: 12px;
  line-height: 19px;
  font-weight: 400;
}

.description-wrap {
  width: 100%;
}

.bonus-card .btn.primary {
  margin-top: 10px;
}

.bonus-card .btn.primary:not(.disabled) {
  background-color: var(--bonus-card-primary-btn-bg);
  color: var(--bonus-card-primary-btn-color);
}

.front-btns .btn.primary {
  margin-top: 0;
}



.total-cashback__wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.total-cashback__title {
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: var(--bonus-card-description-color);
}

.total-cashback__sum {
  margin-top: 3px;
  /*background: #58FFE1;*/
  border-radius: 6px;
  min-width: 120px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5px;

  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: var(--bonus-card-description-color);

}

.bonus-win-sum {
  color: var(--bonus-card-description-color);

  font-size: 24px;
  font-weight: 700;
  margin-top: 12px;
}

.bonus__end-date {
  flex: 1 1 auto;
  display: flex;
  align-items: flex-end;
  margin-bottom: 4px;

  margin-top: 5px;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 400;

  color: var(--bonus-card-description-color);
}

.one-side.killed .bonus-end-message {
  margin-top: 40px;
}

.description-wrap .counter {
  max-width: 174px;
  margin: 0 auto 15px;
}

@media (max-width: 768px ) {
  .description-wrap .counter {
    max-width: 120px;
  }
}

.bonus-section .embla {
  margin-top: 6px;
}

.birthday-img {
  margin-bottom: 5px;
}

.bonus-section .block-title__svg {
  width: 45px;
  height: 70px;
  margin-top: -14px;
  margin-bottom: -10px;
}

@media (max-width: 992px) {
  .bonus-section .block-title__svg {
    width: 54px;
    height: 51px;
    margin-right: 3px;
  }

  .bonus-section .embla {
    margin-top: 9px;
  }
}

@media (max-width: 768px) {
  .bonus-section .block-title__svg {
    width: 45px;
    height: 42px;
  }
}

@media (max-width: 600px) {
  .bonus-section .block-title__svg {
    width: 38px;
    height: 36px;
  }
}

.bonus-slider .embla__slide:first-child .bonus-card {
}

/*.bonus-card.welcome-bonus .bonus-card__icon {*/
/*  fill: white;*/
/*}*/

/*.bonus-card.welcome-bonus:before {*/
/*  content: '';*/
/*  position: absolute;*/
/*  bottom: 0;*/
/*  left: 0;*/
/*  width: 100%;*/
/*  border-bottom-left-radius: 5px;*/
/*  border-bottom-right-radius: 5px;*/

/*  height: 9px;*/
/*  background: #58ffe1;*/
/*}*/

/*.welcome-bonus .bonus-card__wrap {*/
/*  background: #58ffe1;*/
/*}*/

.submit-bonus-modal .player-bonus-card__info {
  width: initial;
}

.submit-bonus-modal .player-bonus-card__info-item span:first-child {
  color: #cfcfcf;
  font-size: 12px;
  font-weight: 400;
}

.submit-bonus-modal .player-bonus-card__info-item span:last-child {
  color: white;
  font-weight: 700;
  font-size: 12px;
}

.player-bonus-card__info {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  margin-bottom: 20px;
  width: 90%;
  margin: 0 auto;
}

.player-bonus-card__info-item {
  width: 100%;
  display: flex;
  font-size: 12px;
  color: var(--bonus-card-description-color);
  margin-bottom: 3px;
}

.bonus-card .link.secondary {
  color: var(--bonus-card-front-secondary-color);
}

.back-side .player-bonus-card__info-item, .task-gs .player-bonus-card__info-item {
  color: var(--bonus-card-back-description-color);
}
.back-side .link.secondary, .task-gs .link.secondary {
  color: var(--bonus-card-back-secondary-color);
}

.player-bonus-card__info-item span:first-child {
  flex: 0 0 66%;
}


.player-bonus-card__info-item span:last-child {
  font-weight: 700;
}

.bonus-program .player-bonus-card__info {
  margin-top: 10px;
}


.player-bonus-card__subtitle {
  color: var(--bonus-card-description-color);
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.player-bonus-card__progress {
  width: 95%;
}

.player-bonus-card__progress-head {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 3px;
}

.flip-card .active-bonus .bonus-card__title.giftspins {
  margin: 7px 0;
}

.flip-card .active-bonus .bonus-card__subtitle {
  margin-bottom: 8px;
}

.flip-card .bonus-card.active-bonus .counter {
  margin-bottom: 9px;
}

.flip-card .bonus-card.active-bonus .player-bonus-card__progress-bar {
  margin-bottom: 9px;
}

.flip-card .bonus-card.active-bonus .bonus-card__wrap {
  padding-bottom: 13px;
}

.active-bonus.back-side .btn {
  min-width: 200px;
}

.player-bonus-card__progress-head span:first-child {
  color: var(--bonus-card-progress-bar-head-color);
  font-weight: 700;
}

.player-bonus-card__progress-head span {
  font-size: 14px;
}

.player-bonus-card__progress-bar {
  width: 100%;
  height: 6px;
  background: var(--bonus-card-progress-bar-bg);
  border: 1px solid var(--bonus-card-progress-bar-bg);
  border-radius: 4px;
  margin-bottom: 15px;
  position: relative;
  overflow: hidden;
}

.player-bonus-card__progress-bar span {
  background: var(--bonus-card-progress-bar-fill-bg);
  position: absolute;
  top: 0.03em;
  height: 3px;
}

.player-bonus-card__win {
  margin-top: 20px;
  margin-bottom: 34px;
  color: var(--bonus-card-description-color);
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 700;
}

.player-bonus-card__win-img {
  text-align: center;
  position: relative;
  margin-bottom: 35px;
}

.player-bonus-card__win-img span {
  position: absolute;
  left: 24%;
  top: 5px;
  text-transform: uppercase;
  font-size: 11px;
  line-height: 12px;
  color: white;
}

.player-bonus-card__cancel {
  color: var(--bonus-card-front-secondary-color);
  font-weight: 700;
  text-transform: uppercase;
  font-size: 14px;
  z-index: 10;
  cursor: pointer;
}

.player-bonus-card__cancel:hover {
  color: var(--btn-primaryHover-bgColor);
}

.btn + .player-bonus-card__cancel {
  margin-top: 15px;
}

.bonus-card .input.field__input {
  margin-bottom: 20px;
}

.wof-icon {
  margin-top: -10px;
  margin-bottom: 15px;
  height: 63px;
  width: 63px;
}

.multiplier-item {
  margin-left: 5px;
}

.bonus-card__title.giftspin-title {
  margin-top: 3px;
  font-size: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 55px;
}

@media (max-width: 768px) {
  .bonus-card__title.giftspin-title {
    margin-top: 3px;
    font-size: 28px;
    height: 48px;
  }
}

@media (max-width: 600px) {
  .bonus-card__title.giftspin-title {
    margin-top: 3px;
    font-size: 24px;
    height: 38px;
  }
}

.bonus-card__title.plus {
  margin: -17px 0 -10px;
  padding: 0;


}

.flip-card .bonus-card .link {
  margin-top: 12px;
  margin-bottom: 0;
}

.flip-card .bonus-card .link:after {
  transition: all 0.3s ease;
  position: absolute;
  content: '>>';
  color: inherit;
  right: 0;
  top: -1px;


  width: initial;
  height: initial;
  border-bottom: initial;
  border-right: initial;
  transform: initial;
}

.flip-card .bonus-card .link:hover:after {
  right: -10px;
  top: -1px;
  transform: initial;
}

.flip-card .bonus-card__nav {
  width: 100%;
  flex: 1 1 auto;
  justify-content: flex-end;
  max-height: none;
}

.loading.bonus-card__wrap {
  height: 370px;
  overflow: hidden;
}

.grid-bonus-item .bonus-card {
  width: 100% !important;
  max-width: 240px;
}

@media only screen and (max-width: 520px) {
  .grid-bonus-item {
    width: 100%;
  }
}

@media only screen and (min-width: 520px) {
  .grid-bonus-item {
    width: 50%;
  }
}

@media only screen and (min-width: 778px) {
  .grid-bonus-item {
    width: 33%;
  }
}

@media only screen and (min-width: 1032px) {
  .grid-bonus-item {
    width: 25%;
  }
}

@media only screen and (min-width: 1280px) {
  .grid-bonus-item {
    width: 20%;
  }
}

.grid-bonus-item {
  margin-top: 45px;
}

.bonus-card__wrap {
  background-color: #ffffff;
  border-radius: 5px;
  padding: 20px 10px 15px;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between;
  position: relative;
  flex-grow: 1;
}

.bonus-card__icon {
  width: 62px;
  height: 62px;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  top: -33px;
  z-index: 10;
  fill: var(--bonus-card-front-icon-bg);
}

.bonus-card__info {
  flex: 1 0 auto;
}

.bonus-card__info,
.bonus-card__nav {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
}

.fs-icon {
  width: 104px;
  height: 91px;
  margin-bottom: 20px;
}


.bonus-card__title {
  padding-top: 5px;
  width: 100%;
  position: relative;
  font-family: 'Peace Sans';
  font-size: 48px;
  text-align: center;
  color: #ffffff;
  font-weight: 400;
  text-shadow:
    -1px -1px 1px var(--text-shadow-color), 1px -1px 1px var(--text-shadow-color), -1px 1px 1px var(--text-shadow-color), 1px 1px 1px var(--text-shadow-color),
    -1px -1px 1px var(--text-shadow-color), 1px -1px 1px var(--text-shadow-color), -1px 1px 1px var(--text-shadow-color), 1px 1px 1px var(--text-shadow-color),
    -1px -1px 1px var(--text-shadow-color), 1px -1px 1px var(--text-shadow-color), -1px 1px 1px var(--text-shadow-color), 1px 1px 1px var(--text-shadow-color),
    -1px -1px 1px var(--text-shadow-color), 1px -1px 1px var(--text-shadow-color), -1px 1px 1px var(--text-shadow-color), 1px 1px 1px var(--text-shadow-color),
    -1px -1px 1px var(--text-shadow-color), 1px -1px 1px var(--text-shadow-color), -1px 1px 1px var(--text-shadow-color), 1px 1px 1px var(--text-shadow-color),
    -1px -1px 1px var(--text-shadow-color), 1px -1px 1px var(--text-shadow-color), -1px 1px 1px var(--text-shadow-color), 1px 1px 1px var(--text-shadow-color),
    -1px -1px 1px var(--text-shadow-color), 1px -1px 1px var(--text-shadow-color), -1px 1px 1px var(--text-shadow-color), 1px 1px 1px var(--text-shadow-color),
    -1px -1px 1px var(--text-shadow-color), 1px -1px 1px var(--text-shadow-color), -1px 1px 1px var(--text-shadow-color), 1px 1px 1px var(--text-shadow-color),
    -1px -1px 1px var(--text-shadow-color), 1px -1px 1px var(--text-shadow-color), -1px 1px 1px var(--text-shadow-color), 1px 1px 1px var(--text-shadow-color),
    -1px -1px 1px var(--text-shadow-color), 1px -1px 1px var(--text-shadow-color), -1px 1px 1px var(--text-shadow-color), 1px 1px 1px var(--text-shadow-color),
    -1px -1px 1px var(--text-shadow-color), 1px -1px 1px var(--text-shadow-color), -1px 1px 1px var(--text-shadow-color), 1px 1px 1px var(--text-shadow-color),
    -1px -1px 1px var(--text-shadow-color), 1px -1px 1px var(--text-shadow-color), -1px 1px 1px var(--text-shadow-color), 1px 1px 1px var(--text-shadow-color),
    -1px 3px 2px var(--text-shadow-color), 1px 3px 2px var(--text-shadow-color), -1px 3px 2px var(--text-shadow-color), 1px 3px 2px var(--text-shadow-color),
    -1px 3px 2px var(--text-shadow-color), 1px 3px 2px var(--text-shadow-color), -1px 3px 2px var(--text-shadow-color), 1px 3px 2px var(--text-shadow-color),
    -1px 3px 2px var(--text-shadow-color), 1px 3px 2px var(--text-shadow-color), -1px 3px 2px var(--text-shadow-color), 1px 3px 2px var(--text-shadow-color),
    -1px 3px 2px var(--text-shadow-color), 1px 3px 2px var(--text-shadow-color), -1px 3px 2px var(--text-shadow-color), 1px 3px 2px var(--text-shadow-color),
    -1px 3px 2px var(--text-shadow-color), 1px 3px 2px var(--text-shadow-color), -1px 3px 2px var(--text-shadow-color), 1px 3px 2px var(--text-shadow-color),
    -1px 3px 2px var(--text-shadow-color), 1px 3px 2px var(--text-shadow-color), -1px 3px 2px var(--text-shadow-color), 1px 3px 2px var(--text-shadow-color),
    -1px 3px 2px var(--text-shadow-color), 1px 3px 2px var(--text-shadow-color), -1px 3px 2px var(--text-shadow-color), 1px 3px 2px var(--text-shadow-color),
    -1px 3px 2px var(--text-shadow-color), 1px 3px 2px var(--text-shadow-color), -1px 3px 2px var(--text-shadow-color), 1px 3px 2px var(--text-shadow-color),
    -1px 3px 2px var(--text-shadow-color), 1px 3px 2px var(--text-shadow-color), -1px 3px 2px var(--text-shadow-color), 1px 3px 2px var(--text-shadow-color),
    -1px 3px 2px var(--text-shadow-color), 1px 3px 2px var(--text-shadow-color), -1px 3px 2px var(--text-shadow-color), 1px 3px 2px var(--text-shadow-color),
    -1px -1px 2px var(--text-shadow-color), 1px -1px 2px var(--text-shadow-color), -1px 1px 2px var(--text-shadow-color), 1px 1px 2px var(--text-shadow-color);
}


.player-bonus-card__info + .total-cashback__wrap {
  margin-top: 10px;
}

.bonus-card__title ~ .bonus-card__title {
  padding-top: 0;
}

.flip-card .bonus-card__title.cashback.currency {
  font-size: 36px;
  margin: 7px 0;
}

.flip-card .bonus-card .counter {
  margin-bottom: 12px;
}

.grid-bonus-item:nth-child(3n + 1) .bonus-card__title,
.embla__slide:nth-child(3n + 1) .bonus-card__title {
  color: #faff00;
}

.grid-bonus-item:nth-child(3n + 1) .bonus-card__title ~ .bonus-card__title,
.embla__slide:nth-child(3n + 1) .bonus-card__title ~ .bonus-card__title {
  color: #58ffe1;
}

.grid-bonus-item:nth-child(3n + 2) .bonus-card__title,
.embla__slide:nth-child(3n + 2) .bonus-card__title {
  color: #ff0eff;
}

.grid-bonus-item:nth-child(3n + 2) .bonus-card__title ~ .bonus-card__title,
.embla__slide:nth-child(3n + 2) .bonus-card__title ~ .bonus-card__title {
  color: #faff00;
}

.grid-bonus-item:nth-child(3n + 3) .bonus-card__title,
.embla__slide:nth-child(3n + 3) .bonus-card__title {
  color: #58ffe1;
}

.grid-bonus-item:nth-child(3n + 3) .bonus-card__title ~ .bonus-card__title,
.embla__slide:nth-child(3n + 3) .bonus-card__title ~ .bonus-card__title {
  color: #ff0eff;
}

.grid-bonus-item:nth-child(3n + 3) .task-gs .bonus-card__title,
.embla__slide:nth-child(3n + 3) .task-gs .bonus-card__title {
  color: #ff496a !important;
}

.bonus-card__title.red {
  color: #ff496a !important;
}

.bonus-card__title.blue {
  color: #4be9ff !important;
}

.bonus-card__title.yellow {
  color: #faff00 !important;
}

.bonus-card__title.violet {
  color: #ff0eff !important;
}

.bonus-card__title.white {
  color: #fff !important;
}

.bonus-card__title.lilac {
  color: #58ffe1 !important;
}

.birthday .bonus-card__title {
  color: #ff496a !important;
}

.bonus-card__star {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 19px;
  height: 19px;
  fill: var(--title-color);
}

.bonus-card__star_left {
  left: -2px;
}


.bonus-card__star_right {
  right: -2px;
}

.task-gs .bonus-card__wrap, .back-side .bonus-card__wrap {
  background-color: var(--bonus-card-back-wrap-bg);
}

.task-gs.bonus-card {
  background-color: var(--bonus-card-back-bg);
}

.task-gs .bonus-card__icon, .back-side .bonus-card__icon {
  fill: var(--bonus-card-back-icon-bg);
}

.task-gs .bonus-card__subtitle, .back-side .bonus-card__subtitle {
  color: var(--bonus-card-back-subtitle-color);
}

.bonus-card.back-side {
  background-color: var(--bonus-card-back-bg);
}

.back-side .bonus-card__info {
  padding-top: 15px;
}

.bonus-card__subtitle.second {
  margin-top: 11px;
}

.flip-card .bonus-card__title.currency {
  font-size: 24px;
  margin-bottom: 5px;
}

.bonus-card__nav {
  flex: 1;
}

.front-btns {
  display: flex;
  justify-content: center;
  width: 100%;
}


.front-btns .btn {
  height: 100%;
  min-width: 0 !important;
}

.active-bonus .description-wrap.has-active {
  opacity: 1;
}

.back-side .has-active.not-active-bonus .bonus-card__subtitle,
.back-side .has-active.not-active-bonus .player-bonus-card__info {
  opacity: 0.3;
}

/*.wagering-complete-message {*/
/*  height: 42px;*/
/*  width: 100%;*/
/*  max-width: 180px;*/
/*  margin: 0 auto;*/
/*  background: var(--bonus-card-bg);*/
/*  border-radius: 5px;*/

/*  display: flex;*/
/*  align-items: center;*/
/*  justify-content: center;*/

/*  font-size: 14px;*/
/*  font-weight: 700;*/
/*  text-transform: uppercase;*/
/*  color: var(--acc-content-bg);*/
/*}*/

/*.killed .wagering-complete-message {*/
/*  background: #7B6392;*/
/*  color: white;*/
/*}*/

.bonus-card.killed {
  background: var(--bonus-card-killed-bg);
}

.bonus-card.killed .bonus-card__icon {
  fill: var(--bonus-card-killed-icon-bg);
}

.bonus-card.killed .bonus-card__title {
  color: white !important;
}

.bonus-end-message {
  text-align: center;
  font-size: 10px;
  color: var(--bonus-card-description-color);
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 8px;
}

.good-luck-message {
  margin-top: 10px;
  position: relative;
}

.good-luck-message svg {
  width: 160px;
  height: 27px;
}

.good-luck-message span {
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);

  text-align: center;

  color: white;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 700;
}

.wagering-end-message {
  color: var(--bonus-card-description-color);
  font-size: 18px;
  font-weight: 900;
  margin-top: 10px;
  text-transform: uppercase;
  text-align: center;
}

/*.bonus-card.killed  .bonus-card__title{*/
/*  text-shadow: -1px 1px 0 #7B6392, -1px -1px 0px #7B6392,*/
/*  -2px 2px 0 #7B6392, -3px 3px 0 #7B6392, -4px 4px 0 #7B6392,*/
/*  1px -1px 0px #7B6392, 1px 1px 0px #7B6392,*/
/*  0px -1px 0px #7B6392, -1px -1px 0px #7B6392,*/
/*  -4px 5px 0px #7B6392, 2px -2px 0px #7B6392,*/
/*  0px -2px 0px #7B6392, 0px 3px 0px #7B6392,*/
/*  1px -2px 0px #7B6392, -1px -2px 0px #7B6392,*/
/*  2px -1px 0px #7B6392, 2px 0px 0px #7B6392,*/
/*  2px 5px 0px #7B6392, 2px 4px 0px #7B6392,*/
/*  1px 5px 0px #7B6392, 1px 4px 0px #7B6392,*/
/*  0px 5px 0px #7B6392, -1px 4px 0px #7B6392,*/
/*  -2px 5px 0px #7B6392, -3px 5px 0px #7B6392,*/
/*  -3px 4px 0px #7B6392, 1px 1px 0px #7B6392,*/
/*  2px 5px 0px #7B6392, -3px 4px 0px #7B6392,*/
/*  -1px -3px 0px #7B6392, 1px -3px 0px #7B6392*/
/*}*/

.gl-message {
  max-width: 105px;
  font-size: 10px;
  font-weight: 700;
  line-height: 18px;
  color: var(--bonus-card-description-color);
  text-transform: uppercase;

  margin-top: 40px;
  text-align: center;
}

.front-btns svg {
  width: 13px;
  height: 17px;
  fill: var(--bonus-card-front-secondary-color);
  transition: all 0.3s ease;
}

.front-btns .tertiary {
  width: 51px;
  height: 41px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: 1px solid var(--bonus-card-tertiary-btn-border-color);
  border-radius: 24px;
}

@media (max-width: 768px) {
  .front-btns .tertiary {
    width: 45px;
    height: 35px;
  }
}

.front-btns .tertiary:hover svg {
  fill: #fff;
}
.front-btns .tertiary:hover  {
  background-color: var(--bonus-card-tertiary-btn-border-color);
}

.front-btns .primary {
  padding-left: 0;
  padding-right: 0;

  margin-left: 8px;

  flex: 1 1 auto;
}


.bonus-card__subtitle {
  font-weight: 900;
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;
  color: var(--bonus-card-subtitle-color);
  margin-bottom: 10px;
}


.bonus-card .link {
  margin-bottom: 37px;
  padding: 0 18px 0 0 !important;
  z-index: 5;
}

.bonus-card .link:after {
  content: '';
  width: 8px;
  height: 8px;
  border-bottom: 2px solid var(--btn-primary-bgColor);
  border-right: 2px solid var(--btn-primary-bgColor);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  right: 0px;
}

.bonus-card .link:hover:after {
  -webkit-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  transform: rotate(-135deg);
  right: 0;
  top: 4px;
}

.bonus-card .btn {
  min-width: 180px;
  font-size: 14px;
}

.tooltip.active {
  top: 0;
  visibility: visible;
  opacity: 1;
  z-index: 1;
}

.tooltip {
  width: 216px;
  position: absolute;
  top: -150%;
  left: 50%;
  -webkit-transform: translate(-50%, calc(-115% - 5px));
  -ms-transform: translate(-50%, calc(-115% - 5px));
  transform: translate(-50%, calc(-115% - 5px));
  visibility: hidden;
  z-index: -1;
  opacity: 0;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.tooltip__wrap {
  padding: 9px 15px 6px 12px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background: #eaeaea;
  border-radius: 5px;
  position: relative;
}

.tooltip__wrap:before {
  content: '';
  bottom: 0;
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%, 75%);
  -ms-transform: translate(-50%, 75%);
  transform: translate(-50%, 73%);
  border: 20px solid transparent;
  border-top: 20px solid #eaeaea;
  border-radius: 1px;
  z-index: -1000;
}

.tooltip__row {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.tooltip__name {
  font-size: 12px;
  line-height: 1.5;
  color: #58466f;
}

.tooltip__count {
  font-weight: 700;
  font-size: 12px;
  line-height: 1.5;
  color: var(--main-bg2);
}

/*.point__title {*/
/*  font-weight: 700;*/
/*  font-size: 14px;*/
/*  text-transform: uppercase;*/
/*  color: #58466f;*/
/*  margin-bottom: 14px;*/
/*}*/

/*.point__status {*/
/*  font-size: 12px;*/
/*  color: #58466f;*/
/*  margin-bottom: 8px;*/
/*}*/

/*.point__status:last-of-type {*/
/*  margin-bottom: 20px;*/
/*}*/

/*.point__count {*/
/*  font-weight: 900;*/
/*  margin-left: 3px;*/
/*}*/

/*.point__subinfo {*/
/*  font-weight: 700;*/
/*  font-size: 14px;*/
/*  letter-spacing: 0.02em;*/
/*  color: #25133c;*/
/*}*/

/*.point__subinfo:hover {*/
/*  -webkit-text-decoration-line: underline;*/
/*  text-decoration-line: underline;*/
/*}*/

/*.point .btn {*/
/*  min-width: 100px;*/
/*}*/

@media (max-width: 600px) {
  .front-btns .btn {
    font-size: 12px;
    padding: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}



@media only screen and (max-width: 992px) {
  .bonus-card__wrap {
    min-height: 0px;
  }
}

@media only screen and (min-width: 1201px) {
  .bonus-card__wrap {
    min-height: 350px;
  }
}


@media only screen and (max-width: 768px) {
  .player-bonus-card__info-item span:first-child {
    margin-right: 10px;
    white-space: nowrap;
  }

  .fs-icon {
    width: 95px;
    height: 82px;
  }

  .bonus-card__nav {
    margin-bottom: 10px;
  }

  .player-bonus-card__info {
    width: 95%;
  }

  .player-bonus-card__info-item span:first-child {
    flex: 0 0 60%;
  }

  .bonus-card__title {
    font-size: 42px;
  }

  .bonus-card .link {
    margin-bottom: 27px;
  }

  .bonus-card__wrap {
    padding: 15px 8px 12px;
  }

  .bonus-card {
    padding: 2px 2px 6px;
    width: 220px;
  }

  .bonus-card__icon {
    width: 48px;
    height: 48px;
    top: -27px;
  }

  .loading.bonus-card__wrap {
    height: 310px;
  }
}

.grid-item .bonus-card {
  min-height: 320px;
}

@media only screen and (max-width: 600px) {
  .point__wrap {
    flex-direction: column;
  }

  .point__img {
    max-width: 100px;
    width: 100%;
    margin-right: 8px;
  }

  .wagering-end-message {
    font-size: 14px;
  }

  .bonus-win-sum {
    font-size: 18px;
  }

  .fs-icon {
    width: 90px;
    height: 80px;
  }

  .bonus-card__title {
    font-size: 34px;
  }

  .bonus-card .link {
    margin-bottom: 13px;
  }

  .bonus-card .btn.primary {
    min-width: 140px;
    padding: 10px 21px;
  }


  .bonus-card__icon {
    width: 42px;
    height: 42px;
    top: -24px;
  }

  .loading.bonus-card__wrap {
    height: 280px;
  }

}

.not-active-message {
  font-weight: 900;
  font-size: 18px;
  text-transform: uppercase;
  color: var(--acc-content-bg);
  margin-bottom: 10px;
  text-align: center;
  margin-top: 10px;
}

.description-wrap.has-active {
  opacity: 0.3;
}

@media (max-width: 767px) {
  .not-active-message {
    font-size: 14px;
  }
}
