.password {
  max-width: 590px;
  width: 100%;
  margin: 0 auto;
  padding: 19px 15px 43px;
  background-color: var(--modal-bg);
}

.change-password .password-reset__email {
  flex-direction: column;
}

.restore-type__wrap.change-password__page {
  width: 100%;
  margin-bottom: 10px;
  margin-left: 25px;
}




.password__wrap {
  max-width: 320px;
  margin: 0 auto;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
}

.password__info {
  font-size: 13px;
  line-height: 1.69;
  text-align: center;
  color: #fcfcfc;
  padding-bottom: 18px;
  margin-bottom: 15px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.password__text {
  font-size: 13px;
  line-height: 1.69;
  text-align: center;
  color: #fcfcfc;
}

.password__link {
  font-size: 13px;
  line-height: 1.69;
  line-height: 22px;
  text-align: center;
  color: var(--title-color);
  margin-bottom: 20px;
  border-bottom: 1px solid transparent;
}

.password__link:hover {
  border-bottom: 1px solid var(--title-color);
}

.password__row {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.password__row .password__link {
  margin: 0 5px;
}

.password .main-form {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  margin-bottom: 16px;
}

.password .main-form__wrap {
  max-width: 288px;
  margin: 0 auto;
  padding: 0;
}

.password .main-form__password-wrap {
  margin: 22px 0 25px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.password .main-form__password-code {
  width: 86px;
  height: 38px;
  margin-right: 15px;
  -webkit-box-flex: 0;
  flex: none;
}

.password .main-form__password-code img {
  width: 100%;
}

.password .main-form__update-btn {
  width: 46px;
  height: 38px;
  margin-right: 15px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  background-color: #d19411;
  display: block;
  -webkit-box-flex: 0;
  flex: none;
}

.password .main-form__update-btn svg {
  width: 17px;
  height: 17px;
  fill: #ffffff;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.password .main-form__update-btn:hover svg {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.password .main-form__label {
  margin-bottom: 8px;
  font-size: 12px;
  display: block;
  max-width: 100%;
}

.password .main-form .btn {
  width: 100%;
  margin: 0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.change-password .password-reset__captcha .main-form__label {
  display: none;
}

@media (max-width: 400px) {
  .change-password__page .modal__tabs-item{
    width: calc(50% - 20px);
  }

}
