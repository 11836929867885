.main-form__input-check {
  display: none;
}

.main-form__checkbox {
  margin-right: 28px;
}

.main-form__checkbox:last-child {
  margin-right: 0;
}

.main-form__label-check {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  cursor: pointer;
  font-size: 14px;
  color: var(--title-color);
  position: relative;
  padding-left: 30px;
}

.main-form__label-check:after {
  position: absolute;
  content: '';
  top: 50%;
  left: 2px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 2px solid var(--radio-unchecked-color);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.main-form__label-check:before {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  position: absolute;
  content: '';
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 8px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: var(--radio-checked-color);
  opacity: 0;
}

.main-form__checkbox input[type='radio']:checked + label:after {
  border-color: var(--radio-checked-color);
}

.main-form__checkbox input[type='radio']:checked + label:before {
  opacity: 1;
}


