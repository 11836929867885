.payment-info-wrap {
  min-height: 95vh;

  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

}

.payment-info-content {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0 10px;

  margin: 0 auto;
  flex: 1;
}

.payment-info-content .bg-img {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  height: 100%;
  width: 100%;
  z-index: 2;
}

.payment-info-wrap .message {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 3;

  margin-top: -19vh;

}

.payment-info-wrap .message h3 {
  font-size: 36px;
  color: #fff;
  letter-spacing: 0.02em;
  font-weight: bold;
}

.payment-info-wrap .message p {
  font-size: 24px;
  font-weight: 400;
  color: #fff;
  text-align: center;
  margin-top: 15px;
  line-height: 30px;
}

@media (max-width: 600px) {
  .payment-info-content {
    max-height: 50vh;
  }

  .payment-info-content .bg-img {
    width: 130%;
    object-fit: unset;
    left: 50%;
    transform: translate(-50%);
  }

  .payment-info-wrap .message {
    margin-top: -8vh;
  }

  .payment-info-wrap .message h3 {
    font-size: 24px;
  }

  .payment-info-wrap .message p {
    font-size: 18px;
  }
}
