.terms__text_pay {
  text-indent: 0;
  margin-bottom: 45px;
}

.terms__title_pay {
  text-indent: 0px;
  margin-bottom: 20px;
}

.payment {
  margin-bottom: 78px;
}

.terms__pay-wrap {
  padding: 43px 112px 23px;
}

.payment-container {
  margin-bottom: 78px;
}

.payment__row {
  height: 67px;
  padding: 10px 20px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.07);
}

.payment__head {
  border-top: 1px solid rgba(255, 255, 255, 0.07);
}

.payment__head .payment__text {
  font-weight: 700;
  color: #ffffff !important;
}

.payment__text {
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  color: var(--description-title-color);
  display: block;
  white-space: nowrap;
}

.payment__method {
  min-width: 280px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.payment__method .payment__text {
  color: #ffffff;
}

.payment__type {
  margin-left: 10px;
  width: 80px;
}

.payment__price {
  width: 80px;
}

.payment__price .payment__text {
  color: #58ffe1;
}

.payment__time {
  width: 105px;
  text-align: right;
  margin-left: 70px;
}

@media (max-width: 1120px) {
  .payment__time {
    display: none;
  }

  .payment__row {
    justify-content: space-between;
  }
}

.payment__time .payment__text {
  color: #58ffe1;
}

.payment__limit {
  width: 125px;
  margin-left: 70px;
}

.payment__limit .payment__text {
  text-align: left;
}

.payment__img {
  margin-right: 40px;
  width: 57px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}

.payment__img img {
  width: 57px;
  height: 35px;
  object-fit: contain;
}


/*mob --- */

.payment__item-mob {
  background-color: var(--acc-sidemenu-bg);
  border-radius: 5px;
  padding: 0 10px;
}

.payment__container-mob > .grid-item {
  margin-bottom: 10px;
}

.payment__head-mob .payment__text-mob:nth-child(1) {
  width: 47%;
  font-weight: 700;
}

.payment__text-mob.with-img {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
}

@media only screen and (min-width: 900px) {
  .payment__container-mob > .grid-item {
    width: 33%;
  }
}

.payment__row-mob {
  padding: 13px 8px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.07);
}


.payment__head-mob {
  padding: 15px 8px 8px;
}

.payment__head .payment__text-mob.payment__text-mob:nth-child(1) {
  width: 47%;
  font-weight: 700;
}

.payment__body-mob .payment__row-mob:last-child {
  border-bottom: none;
}

.payment__body-mob .payment__text-mob:nth-child(1) {
  width: 47%;
  font-weight: 700;
}

.payment__body-mob .payment__text-mob:nth-child(2) {
  width: 53%;
  color: var(--description-title-color);
}

.payment__text-mob {
  color: #ffffff;
  font-weight: 400;
  font-size: 12px;
  display: block;
}


.payment__limits-mob {
  display: flex;
  flex-wrap: wrap;
}

.payment__text-mob span {
  white-space: nowrap;
}

.payment__text_color-mob {
  color: #58ffe1;
}

.payment__img-mob {
  margin-left: auto;
  width: 30px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}

.payment__img-mob img {
  max-width: 100%;
}

.payment__img-mob svg {
  width: 30px;
  height: 20px;
}

@media only screen and (max-width: 768px) {
  .terms__text_pay {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 600px) {
  .terms__text_pay {
    margin-bottom: 15px;
  }
}
