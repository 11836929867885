.user-info {
  margin-bottom: 12px;
}

.user-info__wrap {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.user-info__img {
  width: 56px;
  height: 56px;
  margin-right: 4px;
  -webkit-box-flex: 0;
  flex: none;
  position: relative;
  z-index: 1;
}

.user-info__img_bg:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: var(--user-image-bg);
  z-index: -1;
}

.user-info__img img {
  width: 100%;
}

.user-info__data {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

.user-info__name {
  line-height: 1;
  font-size: 14px;
  color: #ffffff;
  margin-bottom: 3px;
}

.user-info__status {
  font-size: 12px;
  color: var(--description-text-color);
  margin-bottom: 3px;
}

.user-info__rating {
  display: -webkit-box;
  display: flex;
}

.user-info__rating svg {
  width: 14px;
  height: 14px;
  margin-right: 2px;
}
