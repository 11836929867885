.card-container {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  position: relative;
  transform-style: preserve-3d;
  box-sizing: border-box;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

.card-container .size-component {
  opacity: 0;
  height: 100%;
  z-index: -100;
  flex: 1 1 auto;
}

.card-container.flipped .front {
  transform: rotateY(180deg);
  z-index: 2;
}

.card-container.flipped .back {
  transform: rotateY(0deg);
  z-index: 3;
}

.card-container .front,
.card-container .back {
  box-sizing: border-box;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: block;
  position: absolute;
  border-radius: 10px;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  transition: -webkit-transform ease 700ms 0s;
  transition: transform ease 700ms 0s;
}

.card-container .back {
  bottom: initial;
}

.card-container .front {
  z-index: 2;
  transform: rotateY(0deg);
  /* front tile styles go here! */
}

.card-container .back {
  transform: rotateY(-180deg);
  /* back tile styles go here! */
}

