.table {
  max-width: 100% !important;
}

.table__row {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid var(--primary-border-color);
}

.table__head {
  padding: 11px 15px;
}

.table__head .table__name {
  font-size: 14px;
  color: var(--table-head-name-color);
}

.table__name {
  font-size: 12px;
  color: var(--table-name-color);
}

.table__id {
  width: 83px;
  margin-right: 10px;
}

.table__created {
  width: 98px;
  margin-right: 10px;
}

.table__amount {
  width: 100px;
  margin-right: 13px;
  word-break: break-word;
  font-weight: 700;
  font-size: 14px;
  color: var(--title-color);
}

.table__bank {
  width: 98px;
  margin-right: 10px;
  word-break: break-word;
}

.table__delete {
  margin-left: auto;
}

.table__delete svg {
  width: 15px;
  height: 17px;
  fill: var(--table-head-name-color);
}

.table .status {
  width: 96px;
  margin-right: 13px;
}

.table .btn.primary {
  font-weight: 700;
  font-size: 11px;
  color: #ffffff;
  width: 65px;
  padding: 8px 10px 7px;
  margin-left: auto;
}

.table .accordion__head:after {
  left: 16px;
}
.table .accordion__head {
  padding: 0;
}

.accordion .table__row {
  padding-left: 35px;
  border: none;
}
.accordion .table__name {
  width: 33.33%;
}

.table__col {
  width: 36%;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

.table__col .table__name {
  width: 100%;
}

.table .accordion {
  border-bottom: 1px solid var(--primary-border-color);
}

.table__row_content {
  padding-top: 0;
}

@media (max-width: 600px) {
  .main-form__filer-wrap {
    border: none;
    margin-bottom: 0;
  }
}
