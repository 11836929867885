.about-article .article__info  {
  min-height: 850px;
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .about-img {
    min-height: 100px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .about-img {
    min-height: 210px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .about-img {
    min-height: 300px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .about-img {
    min-height: 340px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .about-img {
    min-height: 375px;
  }
}
