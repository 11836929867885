.nav__link {
  text-decoration: none;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  font-weight: 700;
  color: var(--nav-link-color);
  margin-left: 7px;
  text-transform: uppercase;
  font-size: 12px;
  cursor: pointer;
}

.nav__link.active,
.nav__link:hover {
  color: var(--nav-link-colorHover);
  text-shadow: var(--link-text-shadow);
}

.nav-link.active svg,
.nav-link:hover svg {
  fill: #fff023;
}
