.mask {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100vh;
  background: rgba(37, 31, 45, 0.8);
}

.modal_signin .captcha-block .field__text {
  right: 0;
  min-width: 100%;
  left: initial;
}

.mask.active {
  display: block;
}

.currency-select .choices__inner {
  min-height: 40px;
}

.auth-mask .btn {
  min-width: 100% !important;
}

.confirm-email {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.confirm-email .field  {
  width: 100%;
}

.confirm-email .btn  {
  height: 40px;
  padding: 0 6px !important;
  margin-top: 10px !important;
}

.confirm-email .main-form__btn {
  margin-top: 10px;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 13;
  padding: 15px;
  display: none;
}

.modal.active {
  display: block;
}

.modal__close {
  position: absolute;
  top: -15px;
  right: -15px;
  cursor: pointer;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.modal__close:hover {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.modal__close svg {
  width: 30px;
  height: 30px;
}

.modal__content {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.modal__img {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  width: 380px;
  height: 430px;
}

.modal__img img {
  width: 100%;
  height: 100%;
}

.modal__img .section-title {
  position: relative;
  margin: 0;
  top: 30px;
  right: -50px;
}

.modal__wrap {
  background: var(--modal-bg);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.5);
  border-radius: 3px;
  padding: 23px 16px;
  width: 320px;
}

.modal .password {
  padding: 0;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
}

.modal .main-form__wrap {
  padding: 0;
}

.modal .main-form__password-wrap {
  margin: 18px 0 14px !important;
}

.modal .main-form__main-wrap {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: start;
  align-items: flex-start;
  margin-bottom: 16px;
}

.modal .main-form__label {
  margin-bottom: 8px;
}

.modal .main-form__label {
  max-width: 100%;
}

.modal .main-form .btn {
  transition: none !important;
}

.modal .main-form .btn.disabled {
  width: 100%;
  margin: 12px 0 0;
  padding: 12px 10px 11px;
  font-size: 15px;
  transition: none !important;
}

.modal .section-title {
  margin-bottom: 30px;
  font-size: 18px;
  text-shadow: none;
}

.modal .primary {
  min-width: 100%;
  margin: 12px 0 0;
}

.modal__sign-in {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  margin-top: 24px;
}

.modal__sign-title {
  font-size: 12px;
  color: #a4a4a4;
  margin-right: 13px;
}

.modal__sign-link {
  cursor: pointer;
  font-size: 14px;
  color: var(--title-color);
  text-transform: uppercase;
}

.modal__sign-link:hover {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

.modal__tabs {
  width: 100%;
  position: relative;
  margin-bottom: 17px;
}

.modal__tabs-head {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding: 0 17px 12px;
  margin: 0 -17px 16px;
}

.modal__tabs-item {
  width: calc(50% - 35px);
  font-size: 14px;
  color: #ffffff;
  padding-left: 24px;
  position: relative;
  cursor: pointer;
}

.modal__tabs-item:before {
  position: absolute;
  content: '';
  width: 12px;
  height: 12px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  border-radius: 50%;
  background-color: var(--description-title-color);
  box-sizing: border-box;
}

.modal__tabs-item.active:before {
  border: 4px solid #408bfa;
  background-color: #ffffff;
}

@media only screen and (max-width: 768px) {
  .modal {
    overflow-y: auto;
    height: 100%;
    padding: 0 15px;
  }
  .modal__content {
    min-height: 100%;
  }
  /*.modal__wrap {*/
  /*  flex: 1 0 auto;*/
  /*}*/
  .modal__close {
    display: none;
  }
  .modal__content {
    flex-direction: column;
    justify-content: center;
  }
}

.modal__head {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  background: var(--modal-head-bg);
  box-shadow: var(--modal-head-shadow);
  padding: 15px 12px 12px;
  position: relative;
  width: 100%;
}

.modal__info-title {
  font-weight: 700;
  font-size: 14px;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
}

.modal-head__close {
  position: absolute;
  top: 13px;
  right: 13px;
  cursor: pointer;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  z-index: 1000;
}

.modal-head__close > svg {
  width: 15px;
  height: 15px;
}

.modal__img-header {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  margin-bottom: 32px;
}

.modal_signin .btn {
  margin-left: 0 !important;
}

.modal_signin .captcha-block__wrap .error-text {
  width: 200%;
  margin-bottom: 0;
}

.modal_signin .captcha-block {
  margin: 10px 0;
}

.modal_signin .main-form__wrap .main-form__main-wrap {
  margin-bottom: 22px !important;
}

.sign-up-btn {
  margin-top: 20px !important;
}

.soc-wrap span {
  cursor: pointer;
}
