.popup__btns {
  display: flex;
}

.popup.secondary-modal {
  padding-top: 15px;
  text-align: center;
  background-color: var(--modal-bg);
  max-width: 370px;
  width: 100%;
  min-height: 167px;

  color: var(--modal-color);
  font-size: 14px;
}

.secondary-modal .popup__btns .btn {
  font-size: 12px;
  padding: 12px 11px;
}

.secondary-modal .popup__btns .reject-btn {
  background: none;
  font-size: 14px;
  color: var(--title-color);
  text-decoration: underline;
  margin: 25px auto 0;
  letter-spacing: 0.02em;
  padding: 0;
  text-transform: uppercase;
  width: 150px;
}

.secondary-modal .popup__btns .reject-btn:hover {
  background: none;
  text-decoration: none;
}

.secondary-modal.popup .close-btn {
  fill: var(--title-color);
  right: -26px;
  top: -25px;
}

.secondary-modal.popup .btn {
  margin-top: 25px;
  width: 150px;
}

.secondary-title {
  color: var(--title-color);
  text-transform: uppercase;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 13px;
}


