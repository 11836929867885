.pages-nav {
  background-color: var(--page-nav-bg);
  position: fixed;
  left: 0;
  bottom: 0;
  padding: 9px 0;
  width: 100%;
  z-index: 16;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  height: 58px;
}

.pages-nav + .footer {
  margin-bottom: 58px;
}

.pages-nav .nav {
  margin: 0;
  width: 100%;
}
.pages-nav .nav svg {
  height: 22px;
  fill: #bfbfbf;
  width: 35px;
  margin-bottom: 5px;
}
.pages-nav .nav__list {
  width: 100%;
  padding: 0 15px;
  -webkit-box-pack: justify;
  justify-content: space-between;
  display: flex;
  align-items: center;
}
.pages-nav .nav__item {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
}
.pages-nav .nav__link.active svg {
  fill: var(--title-color);
}
.pages-nav .nav__item:first-child svg {
  top: auto;
}
.nav__link.active svg,
.nav__link:hover svg {
  fill: var(--link-color);
}

.pages-nav .nav__text {
  margin: 0 !important;
  font-size: 10px;
  letter-spacing: 0.03em;
  color: #bfbfbf;
  text-transform: none !important;
}
