.popup-mask {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100vh;
  background: rgba(37, 31, 45, 0.4);
}

.popup-mask.active {
  display: block;
}

.popup {
  font-weight: 600;
  font-size: 14px;
  width: 300px;
  background-color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 27px 22px 14px 19px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.close-btn {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  fill: #000000;
  transition: .3s linear;
}

.close-btn:hover {
  transform: rotate(90deg);
}

.popup .btn {
  display: block;
  margin: 12px auto 0;
  min-width: 120px;
}
