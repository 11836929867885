.terms-article .article__info  {
  min-height: 850px;
}


.terms__list {
    counter-reset: section;
    list-style-type: none;
}

.terms__list>li {
    position: relative;
}

.terms__list>li:before {
    position: absolute;
    left: -20px;
    font-weight: 700;
    font-size: 14px;
    line-height: 1;
    color: #ffffff;
    counter-increment: section;
    content: counters(section, ".") ".";
}

.terms__list>li:nth-child(1n + 10) {
    text-indent: 25px;
}

.terms__list>li:nth-child(1n + 10):before {
    left: -25px;
}

.terms__sub-list li {
    font-weight: 400;
}

.terms__text {
    position: relative;
}

.terms__sub-list {
    counter-reset: section;
    list-style-type: none;
    margin-top: 25px;
}

.terms__sub-list>li:before {
    counter-increment: section;
    position: absolute;
    font-weight: 400;
    top: 0px;
    left: -30px;
    font-size: 14px;
    line-height: 1.7;
    color: var(--description-title-color);
    content: counters(section, ".") ".";
}
