.game {
  width: 100%;
  display: flex;
  flex: 1;
}

#scroll-games .infinite-scroll-component {
  overflow: hidden !important;
}

.partner-slider .embla__button {
  z-index: 6;
}

.partner-slider .embla__button__svg {
  fill: var(--partner-slide-btn-color);
}
.partner-slider .embla__button__svg:hover {
  fill: var(--title-color);
}

.game__wrap {
  height: 305px;
  overflow: hidden;
  position: relative;
  border-radius: 5px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  cursor: pointer;
  flex: 1;
}

.game__wrap:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 1;
  left: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(
    180deg,
    rgba(35, 13, 52, 0) 45.83%,
    rgba(23, 8, 35, 0.74) 100%
  );
}

.game__wrap:hover .game__nav {
  opacity: 1;
}

.game__wrap:hover .game__nav:before {
  opacity: 1;
}

.game__wrap:hover .btn {
  opacity: 1;
}

.game__wrap:hover .game__demo {
  opacity: 1;
}

.game_slider .game__wrap {
  height: 370px;
}

.game_slider .game__nav {
  opacity: 0;
}

.game .game__nav:before {
  opacity: 0;
  background-image: var(--game-card-before-bg-webkit);
  background-image: var(--game-card-before-bg)
}

.game:hover .game__nav {
  opacity: 1;
}

.game__img {
  width: 100%;
  height: 100%;
  min-height: 250px;
  position: relative;
}

.game__nav {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 55% 20px 15%;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: end;
  justify-content: space-between;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.game__nav:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  background-image: var(--game-card-nav-before-bg);
}

.game__nav .btn {
  max-width: 159px;
  width: 100%;
  margin-bottom: 22px;
  padding: 15px 15px;
  opacity: 0;
}

.game__demo {
  font-size: 15px;
  text-align: center;
  letter-spacing: 0.1em;
  color: #ffffff;
  font-weight: 700;
  padding-bottom: 2px;
  border-bottom: 2px solid transparent;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  margin-bottom: 50px;
  opacity: 0;
}

.game__demo:hover {
  border-color: #ffffff;
}

.game__info {
  width: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  align-items: flex-end;
  margin-top: auto;
  z-index: 3;
  position: relative;
}

.game__rating-wrap {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.game__rating-wrap svg {
  width: 16px;
  height: 16px;
  margin-right: 4px;
}

.game__rating {
  font-size: 15px;
  color: #ffffff;
  padding-top: 3px;
}

.game__name {
  font-size: 18px;
  color: #ffffff;
  margin-bottom: 10px;
}

.game__name .like-button {
  width: 24px;
  height: 21.35px;
  margin-bottom: -5px;
  margin-right: 5px;
}

.like-button {
  z-index: 1000;
  fill: rgba(255, 255, 255, 0.6);
  transition: all 0.3s ease;
}

.like-button:hover {
  fill: rgba(255, 255, 255, 1);
}

.grid-game-item {
  margin-bottom: 14px;
  padding: 0 7px !important;
}

.game.loading {
  overflow: hidden;
  background-color: var(--article-violet-bg);
}

.embla__slide .game__wrap {
  height: 370px;
  margin-right: 11px;
}

.embla__slide .game.loading {
  margin: 0 20px;
}

.game-slider .game__wrap {
  width: 250px;
}

.partner-slider .embla {
  padding: 0 75px;
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .game-slider .game__wrap {
    width: 200px;
  }
}
@media only screen and (max-width: 500px) {
  .game-slider .game__wrap {
    width: 180px;
  }
}

@media only screen and (max-width: 1200px) {
  .game__wrap {
    height: auto !important;
  }
  .loading .game__wrap {
    height: 250px !important;
  }
}

@media (max-width: 700px) {
  .game__nav .btn {
    margin-bottom: 22px;
    padding: 12px 17px;
    opacity: 0;
  }
  .game__name {
    font-size: 17px;
    margin-bottom: 0;
  }
  .game__name .like-button {
    width: 22px;
    height: 19px;
  }
}

@media only screen and (max-width: 600px) {
  .loading .game__wrap {
    height: 180px !important;
  }
  .game__img {
    height: 180px;
  }
  .game__nav .btn {
    width: 100%;
    font-size: 13px;
    margin-bottom: 22px;
    opacity: 0;
  }
}

@media only screen and (max-width: 420px) {
  .grid-game-item {
    width: 50% !important;
  }
  .game__name {
    font-size: 14px;
    margin-bottom: 0;
  }
  .game__name .like-button {
    width: 18px;
    height: 15px;
  }
}
