.tournaments-wrap {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin-top: 20px;
}

.tournaments-wrap .tournament {
  width: calc(50% - 7px);
  margin-bottom: 15px;
}

.tournament-content {
  display: -webkit-box;
  display: flex;
  margin-top: 15px;
  height: 100%;
  position: relative;
}

.tournament-section .block-title__svg {
  width: 59px;
  height: 29px;
}

@media (max-width: 992px) {
  .tournament-section .block-title__svg {
    width: 54px;
    height: 27px;
  }
}
@media (max-width: 768px) {
  .tournament-section .block-title__svg {
    width: 45px;
    height: 25px;
  }
}
@media (max-width: 600px) {
  .tournament-section .block-title__svg {
    width: 25px;
    height: 23px;
    margin-bottom: -3px;
  }
}

.tournament__winner-table {
  margin: 0 auto;
  max-width: 1020px;
}

.tournament {
  width: 100%;
  margin-right: 20px;
  position: relative;
  padding: 4px 4px 14px 4px;
  background-color: var(--tournament-bg);
  border-radius: 5px;
  height: 100%;
}

.tournament-content .tournament {
  height: auto;
}

.tournament-content .btn {
  font-size: 18px;
  width: 260px;
  height: 54px;
  padding: 19px;
}

.tournament-content .tour-table__body {
  max-height: 280px;
  height: auto;
}

.tour-table__prize {
  color: white;
}

.tournament-content .tour-table {
  width: 418px;
  -webkit-box-flex: 0;
  flex: none;
  display: flex;
  align-items: center;
}

.tournament-content .tour-table > .tour-table__wrap {
  width: 100%;
}

.tournament_big {
  margin-bottom: 43px;
  padding: 0;
}

.tournament_big .tournament__info {
  width: 100%;
}

.tournament_big .counter {
  position: static !important;
  top: auto;
  right: auto;
  background-color: var(--acc-content-bg) !important;
  margin-bottom: 50px;
}

.tournament_big .tournament__sub-title {
  margin-bottom: 10px;
}

.tournament_big .tournament__price {
  font-size: 64px;
  line-height: 0.9;
  margin-bottom: 37px;
}

.tournament_big .tournament__name {
  font-size: 48px;
  max-width: 680px;
  margin: 0 auto 30px;
}

.tournament__wrap {
  border-radius: 5px;
  background-color: var(--article-wrap-bg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  padding: 50px 50px 44px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

/*
.tournament__wrap:before {
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    position: absolute;
    background: linear-gradient(90deg, #25133B 37.68%, rgba(37, 19, 59, 0) 61.31%);
}


/*
.tournament_big .tournament__wrap:before {
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    position: absolute;
    background: linear-gradient(180deg, rgba(37, 19, 59, 0) 39.25%, #25133B 91.13%)
} */

.tournament__wrap > * {
  z-index: 2;
}

.tournament-content .tour-table__title {
  margin-bottom: 18px;
}

.tournament-content .tour-table__row.tour-table__head {
  padding-bottom: 8px;
}

.tournament-content .tour-table__footer {
  font-size: 14px;
}

.tournament-content .tournament__info .btn {
  margin-top: auto;
}

.jackpot-article.jackpot-article--page {
  background-position: top;
}

.withFooter.tour-table__body {
  margin: 0;
  overflow: hidden;
  padding: 0;
}

.grid-tournament-item {
  margin-bottom: 20px;
}

.tournament__info {
  width: 60%;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  flex: 1 1 auto;
}

.tournament__info .btn {
  margin-top: auto;
}

.tournament-content .tour-table__number {
  font-weight: 400;
  width: 27% !important;
}

.tournament-content .tour-table__gamer {
  font-weight: 400;
  width: 50% !important;
}

.tournament-content .tour-table__prize {
  font-weight: 400;
  width: 33% !important;
}

.tournament__name {
  text-align: center;
  font-weight: 900;
  font-size: 24px;
  line-height: 1.25;
  color: #ffffff;
  margin-bottom: 15px;
  text-transform: uppercase;
  width: 100%;
  text-align: center;
}

.tournament__descr {
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: var(--description-text-color);
  max-width: 780px;
  margin: 0 auto;
}

.tournament__sub-title {
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
  color: #a394b0;
  margin-bottom: 32px;
}

.tournament-table {
  max-width: 1020px;
  margin: 0 auto;
}

.tournament__price {
  font-weight: 900;
  font-size: 48px;
  color: var(--title-color);
  line-height: 1;
  width: 100%;
  text-align: center;
  margin-bottom: 5px;
}

.tournament .counter {
  min-width: 143px;
  position: absolute;
  top: 37px;
  right: -4px;
  z-index: 1;
  background-color: transparent;
}

.tournament_big .tournament__price {
  margin-left: 0;
}

.tournament .counter .counter__wrap {
  border-radius: 5px 0 0 5px;
}

.tour-descr {
  max-width: 930px;
  width: 100%;
  margin: 0 auto 60px;
  line-height: 24px;
}

.tour-descr .accordion__text {
  width: 100%;
  line-height: 24px;
}

.tour-descr p b {
  color: white;
}

.tour-descr h3 {
  font-weight: 700;
  font-size: 18px;
  -webkit-box-align: center;
  align-items: center;
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 21px;
}

.tour-descr p {
  font-size: 14px;
  line-height: 1.7;
  color: var(--description-text-color);
}

.tour-descr .mb  {
  margin-bottom: 20px;
}

.tour-descr {
  font-size: 14px;
  line-height: 1.7;
  color: var(--description-text-color);
}

.tour-descr__item {
  font-size: 14px;
  line-height: 1.7;
  color: var(--description-text-color);
}

.tournament.loading {
  height: 300px;
  overflow: hidden;
}

.tournament_big.loading {
  height: 500px;
}

@media only screen and (min-width: 400px) {
  .tournament .btn {
    min-width: 192px;
  }
}

@media only screen and (min-width: 601px) {
  .tournament_big .counter__wrap {
    min-width: 230px;
    padding: 5px 10px 3px;
    border-radius: 5px !important;
  }
  .tournament_big .counter {
    margin-bottom: 30px;
  }
  .tournament_big .counter__count {
    font-size: 32px;
    height: 28px;
  }
  .tournament_big .counter__count:before {
    right: -10px !important;
    font-size: 28px !important;
  }
  .tournament_big .counter__descr--final {
    font-size: 20px;
    top: 15px;
    letter-spacing: 1px;
  }
  .tournament_big .counter__col {
    margin: 0 10px 0 0;
  }
  .tournament_big .counter__text {
    font-size: 13px;
  }
  .tournament_big .counter__img {
    margin-right: 14px;
  }
  .tournament_big .counter__img svg {
    width: 36px;
    height: 36px;
  }
}

@media only screen and (min-width: 769px) {
  .tournament_big .counter__wrap {
    min-width: 270px;
    padding: 10px 10px 8px;
  }
  .tournament-content .tournament__name {
    font-size: 36px;
    margin-bottom: 20px;
  }
  .tournament-content .tournament__price {
    font-size: 64px;
    margin-bottom: 10px;
  }
  .tournament_big .counter__count {
    font-size: 32px;
    height: 28px;
  }
  .tournament_big .counter__col {
    margin: 0 10px 0 0;
  }
  .tournament_big .counter__text {
    font-size: 13px;
  }
  .tournament_big .counter__img {
    margin-right: 14px;
  }
  .tournament_big .counter__img svg {
    width: 46px;
    height: 46px;
  }
  .tournament_big .counter__col:nth-child(2) .counter__count:before,
  .tournament_big .counter__col:nth-child(3) .counter__count:before {
    top: 1px;
  }
}

@media only screen and (min-width: 993px) {
  .tournament-content .tournament__name {
    font-size: 32px;
    margin-bottom: 24px;
  }
  .tournament-content .tournament__price {
    font-size: 56px;
  }
}

@media only screen and (min-width: 1200px) {
  .tournament-content .tournament .counter__wrap {
    padding: 12px 8px 9px;
  }
  .tournament-content .tournament .counter__descr--final {
    font-size: 16px;
  }
  .tournament__sub-title {
    font-size: 18px;
  }
  .tournament-content .tournament__price {
    font-size: 64px;
  }
  .tournament-content .tournament__name {
    font-size: 36px;
    margin-bottom: 30px;
  }
  .tournament-content .tournament__sub-title {
    margin-bottom: 43px;
  }
  .tournament-content .tour-table__row {
    padding: 27px 10px 26px 20px;
  }
}

@media only screen and (max-width: 1200px) {
  .tournament__name {
    font-size: 22px;
    margin-bottom: 13px;
  }
  .tournament__price {
    font-size: 36px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1280px) {
  .tournament-content .tour-table__row {
    padding: 20px 10px 20px;
  }
  .tournament-content .withFooter.tour-table__body {
    height: auto;
    max-height: 232px;
  }
  .tournament-content .tour-table__title {
    font-size: 24px;
  }
  .tournament-content .tour-table {
    width: 375px;
  }
}

@media only screen and (max-width: 992px) {
  .tournament_big.loading {
    height: 440px;
  }
  .tournament_big .tournament__price {
    font-size: 56px;
    margin-bottom: 20px;
  }
  .tournament_big .tournament__name {
    font-size: 42px;
  }
  .tournament__price {
    font-size: 30px;
  }
  .tournament__wrap {
    padding: 30px;
  }
  .tournament-content {
    flex-direction: column;
  }
  .tournament-content > .tour-table {
    margin-top: 10px;
    width: 100%;
  }
  .tournament-content .tour-table__body {
    max-height: 250px;
    height: auto;
  }
}

@media only screen and (max-width: 768px) {
  .tournament_big.loading {
    height: 340px !important;
  }
  .tournament.loading {
    height: 260px;
  }
  .tournament__sub-title {
    display: block;
  }
  .tournament .btn {
    width: auto;
    height: auto;
    padding: 12px 27px;
  }
  .tour-table__wrap {
    padding: 20px 14px;
  }
  .tour-table__title {
    font-size: 24px;
  }
  .tour-table__row {
    padding: 16px 8px 17px 15px;
    font-size: 16px;
  }
  .tournament__name {
    font-size: 24px;
    margin-bottom: 15px;
  }
  .tournament__price {
    font-size: 42px;
  }
  .tournament_big .counter__col:nth-child(2) .counter__count:before,
  .tournament_big .counter__col:nth-child(3) .counter__count:before {
    top: 1px;
  }
  .tournament-content .tour-table__row {
    padding: 15px 10px 15px 20px;
  }
  .tournament-content .tour-table__body {
    max-height: 195px;
  }
  .tournament-content .tour-table__title {
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 600px) {
  .tournament_big .tournament__price {
    font-size: 30px;
    line-height: 0.9;
    margin-bottom: 10px;
  }
  .tournament_big.loading {
    height: 180px !important;
  }
  .tournament_big .tournament__name {
    font-size: 24px;
    margin-bottom: 10px;
  }
  .tournament_big .counter {
    margin-bottom: 30px;
  }
  .tournament {
    padding: 1px 1px 5px;
  }
  .tournament_big {
    padding: 0 !important;
    margin-bottom: 20px;
  }
  .tournament .counter {
    top: 11px;
    min-width: 0;
  }
  .tournament .counter__img > svg {
    margin-top: 3px;
    width: 13px;
    height: 13px;
  }
  .tournament_big .tournament__sub-title {
    font-size: 9px;
  }
  .tournament__wrap {
    padding: 22px 12px 11px;
  }
  .tournament__info {
    width: 60%;
  }
  .tournament__price {
    margin-bottom: 15px;
    font-size: 24px;
  }
  .tournament__name {
    font-size: 16px;
  }
  .tournament.loading {
    height: 130px;
  }
  .tour-table__row {
    font-size: 14px;
  }
  .tournament .btn {
    width: auto;
    min-width: 150px;
    height: auto;
    padding: 10px 27px;
    font-size: 14px;
  }
  /* COUNTER  */
  .tournament_big .counter__wrap {
    min-width: 120px;
    padding: 5px 10px;
    border-radius: 5px !important;
  }
  .tournament_big .counter__col:nth-child(2) .counter__count:before,
  .tournament_big .counter__col:nth-child(3) .counter__count:before {
    top: -3px;
  }
  .tournament_big .counter {
    margin-bottom: 30px;
  }
  .tournament_big .counter__count {
    font-size: 18px;
    height: 14px;
  }
  .tournament_big .counter__count:before {
    right: -8px !important;
    font-size: 20px !important;
  }
  .tournament_big .counter__descr--final {
    font-size: 14px;
    top: 12px;
    letter-spacing: 1px;
  }
  .tournament_big .counter__col {
    margin: 0 10px 0 0;
  }
  .tournament_big .counter__text {
    font-size: 13px;
  }
  .tournament_big .counter__img {
    margin-right: 14px;
  }
  .tournament_big .counter__img svg {
    width: 21px;
    height: 21px;
  }
  .tournament-content .tour-table__row {
    font-size: 12px;
    padding: 8px 10px 8px 20px;
  }
  .tournament-content .tour-table__row {
    font-size: 12px;
    padding: 10px 10px 10px 20px;
  }
  .tournament-content .tour-table__col.tour-table__gamer {
    font-size: 12px;
  }
  .tournament-content .tour-table__body {
    max-height: 140px;
  }
  .tournament-content .tour-table__title {
    font-size: 18px;
    margin-bottom: 15px;
  }
  .tournament-content .tour-table__footer {
    margin-top: 18px;
  }
}

@media (max-width: 400px) {
  .tour-table__row {
    padding-left: 0;
    padding-right: 0;
  }
}
