.link {
  display: inline-block;
  box-sizing: border-box;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0.02em;
  position: relative;
  padding-right: 25px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  text-decoration: none;
}

.link:after {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  position: absolute;
  content: '>>';
  color: inherit;
  right: 0;
  top: -1px;
}

.link:hover:after {
  right: -10px;
}

.link.primary {
  color: var(--link-color);
}

.link.primary:hover {
  color: var(--link-colorHover);
}

.link.secondary {
  padding-right: 20px;
  font-size: 14px;
  color: var(--link--secondary-color);
}

.link.tertiary {
  color: var(--link-color);
  padding-right: 0;
}

.link.tertiary:after {
  display: none;
}

.link.tertiary:hover {
  text-decoration: underline;
}

.link.border {
  min-width: 200px;
  font-size: 14px;
  padding: 15px 50px 13px 20px;
  border: 2px solid var(--btn-primary-bgColor);
  border-radius: 24px;
}

.link.border:after {
  right: 20px;
  top: 14px;
}

.link.border:hover:after {
  right: 25px;
}

@media only screen and (max-width: 1200px) {
  .link {
    font-size: 14px;
  }
}

@media only screen and (max-width: 992px) {
  .link {
    font-size: 14px;
  }
}

@media only screen and (max-width: 768px) {
  .link.border {
    padding: 12px 40px 12px 16px;
    min-width: 170px;
  }
  .link.border:after {
    top: 12px;
    right: 15px;
  }
  .link:after {
    right: 4px;
  }
}

@media only screen and (max-width: 600px) {
  .link.border {
    padding: 7px 20px 7px 10px;
    min-width: 120px;
    font-size: 10px;
  }
  .link.border:after {
    top: 7px;
    right: 7px;
  }
}
