.frame__frame {
  -webkit-box-flex: 0;
  flex: none;
  width: calc(100% - 502px);
  overflow: hidden;
}

.frame__frame.loading {
  border: 1px solid white;
}

.frame__frame iframe {
  width: 100%;
  height: 100%;
}

.game-rules {
  max-width: 1090px;
  max-height: 670px;
  height: 90%;
  width: 90%;
  background: var(--modal-bg);
  border-radius: 6px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 49px 93px 44px;
  overflow: auto;
}

.game-rules__descr {
  color: var(--description-text-color);
}

.game-rules-descr p {
  margin-bottom: 15px;
}
.game-rules-descr p:last-child {
  margin-bottom: 0px;
}

.popup-mask.game-popup .close-btn {
  width: 38px;
  height: 38px;
  right: 10%;
  top: 12%;
}

.popup-mask.game-popup {
  background: rgba(37, 31, 45, 0.8);
}

@media (max-width: 992px) {
  .game-rules {
    padding: 49px 80px 44px;
  }
}

@media (max-width: 768px) {
  .game-rules {
    padding: 49px 50px 44px;
  }
}

@media (max-width: 600px) {
  .game-rules {
    padding: 49px 30px 44px;
  }
}

.game-rules::-webkit-scrollbar {
  width: 20px;
}

.game-rules::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 10px 10px #f7d031;
  border: solid 7px transparent;
  border-radius: 15px;
}

.game-rules::-webkit-scrollbar-track {
  box-shadow: inset 0 0 10px 10px #424242;
  border: solid 7px transparent;
  border-radius: 15px;
}

.game-rules__title {
  text-align: center;
  font-size: 18px;
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 24px;
}

.game-rules__text {
  color: var(--description-text-color);
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 25px;
}

.game-rules__text:last-of-type {
  margin-bottom: 0px;
}
