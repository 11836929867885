.wheel-game-page {
  padding: 56px 0px;
}

.wheel-container {
  display: flex;
  justify-content: center;
  position: relative;
  flex-wrap: wrap;
}


.wheel-game-page.is-loading {
  min-height: 90vh;
}

.content__header {
  font-size: 21px;
}

.content__header.has-prize {
  font-size: 28px;
}

.content__text {
  max-width: 130px;
  margin: 0 auto;
  font-size: 14px;
}

.multiplier-select__wrap {
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  margin: 50px -6px 0;
}

.multiplier-select__item--wrap {
  padding: 0 6px;
}

.multiplier-select__item--wrap .spin-cost {
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 5px;
}

.multiplier-select__item__input {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
}

.btn.multiplier-select__item.disabled {
  color: white;
}

.multiplier-select__item.active {
  opacity: 0.5;
  text-shadow: none !important;
}

.multiplier-select__item__input:focus + .multiplier-select__item {
  outline: 1px solid;
}

.btn.multiplier-select__item span {
  font-size: 14px;
  margin-left: 2px;
}

.btn-multiplier-select__item .content-wrap {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.btn.multiplier-select__item {
  padding: 0 12px;
  width: 88px;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 24px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  color: var(--title-color);
  font-family: var(--wof-multiplier-btn-font-family);
  background: var(--wof-multiplier-btn-bg);
  font-size: 24px;

  letter-spacing: 1px;

  text-shadow: var(--wof-multiplier-btn-text-shadow);
}

.btn.multiplier-select__item.disabled {
  min-width: initial;
}

.context__text .deposit-text {
  font-size: 12px;
}

.wheel-block {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.wheel-block .cta {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-basis: 300px;
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
  position: relative;
}

.wheel-block .cta.cta--left {
  background-image: url('../images/cta1.svg');
}

.wheel-block .cta.cta--right {
  background-image: url('../images/cta2.svg');
}

.freespin-count {
  position: absolute;
  top: 0;
  left: -20%;
  white-space: nowrap;
  display: flex;
  align-items: center;

  font-size: 14px;
  line-height: 16px;
  color: #fff;
}

.freespin-count .count {
  margin-left: 10px;

  color: var(--title-color);
  font-size: 30px;
  font-family: Peace Sans;
}


.wheel-block .cta .cta__text {
  padding-bottom: 30px;
  font-size: 36px;
  font-weight: bold;
  line-height: 54px;
  text-transform: uppercase;
  max-width: 150px;
  color: #fff;
}

.wheel-container .spin-btn-wrapper {
  position: absolute;
  left: calc(50% - 72px);
  top: calc(50% + 34px);
  z-index: 8;
}

.wheel-container .spin-btn-wrapper .btn {
  background-color: var(--wof-btn-bg);
  color: var(--wof-btn-color);
  font-size: 18px;
}

.wheel-container .spin-btn-wrapper .btn.disabled {
  color: #ffffff;
  background: #89719a;
}

.wheel-container .spin-btn-wrapper .btn.disabled {
  margin: 0px 0px;
  padding: 14px 30px 12px;
  cursor: not-allowed;
}

.wheel-container .spin-btn-wrapper.get-prize {
  left: calc(50% - 93px);
}

.wheel-container .spin-btn-wrapper {
  min-width: 140px;
}

.wheel-container .spin-btn-wrapper .btn {
  width: 100%;
}


.wheel-container .pepper {
  width: 234px;
  height: 234px;
  position: absolute;
  left: calc(50% - 117px);
  top: calc(50% - 117px);
  z-index: 7;
}

.wheel-container .boom {
  width: 68px;
  height: 81px;
  background-position: center;
  background-repeat: no-repeat;
}

.wheel-container .boom-container {
  width: 101px;
  height: 125px;
  top: -23px;
  background-position: center;
  position: absolute;
  background-size: cover;
  left: calc(50% - 51px);
  z-index: 6;
  transform: scale(1.2);
  display: flex;
  padding-top: 10px;
  justify-content: center;
}

.wheel-container .red-border {
  width: 700px;
  height: 700px;
  border-radius: 350px;
  z-index: 1;
  display: flex;
  justify-self: center;
  align-items: center;
  background-color: var(--main-bg2);
  background-position: center;
  position: relative;
  overflow: hidden;

  box-shadow: var(--wof-shadow);
}

.wheel-container .stars {
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: bottom;
  position: relative;
  width: 700px;
  height: 700px;
  overflow: hidden;
  border-radius: 350px;
}

.wheel-container .top-triangle {
  width: 315px;
  height: 419px;
  position: absolute;
  background-size: contain;
  background-position: center center;
  z-index: 5;
  left: calc(50% - 156px);
  top: -14px;
  transform: scale(1.12);
}

.wheel-container .sectors {
  position: relative;
  width: 590px;
  height: 590px;
  overflow: hidden;
  border-radius: 295px;
  transform: rotate(-1deg);
}

.is-spinning {
  transition: transform 5s ease;
}

.wheel-container .sectors__content {
  position: relative;
  width: 590px;
  height: 590px;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 295px;
}

.wheel-container .item {
  overflow: hidden;
  position: absolute;
  width: 295px;
  height: 295px;
  transform-origin: 100% 100%;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 18px;
}

.wheel-container .item1 {
  z-index: 8;
  transform: rotate(22.5deg);
}

.wheel-container .item2 {
  z-index: 7;
  transform: rotate(67.5deg);
}


.wheel-container .item3 {
  z-index: 6;
  transform: rotate(112.5deg);
}

.wheel-container .item4 {
  z-index: 5;
  transform: rotate(157.5deg);
}

.wheel-container .item5 {
  z-index: 4;
  transform: rotate(202.5deg);
}

.wheel-container .item6 {
  z-index: 3;
  transform: rotate(247.5deg);
}

.wheel-container .item7 {
  z-index: 2;
  transform: rotate(292.5deg);
}

.wheel-container .item8 {
  border: none;
  position: absolute;
  transform: rotate(-22.5deg);
}

.item1.current-prize ~ .item8 .content,
.item2.current-prize ~ .item1 .content,
.item3.current-prize ~ .item2 .content,
.item4.current-prize ~ .item3 .content,
.item5.current-prize ~ .item4 .content,
.item6.current-prize ~ .item5 .content,
.item7.current-prize ~ .item6 .content,
.item8.current-prize ~ .item7 .content {
  transform: rotate(332deg);
}

.item1.current-prize ~ .item2 .content,
.item2.current-prize ~ .item3 .content,
.item3.current-prize ~ .item4 .content,
.item4.current-prize ~ .item5 .content,
.item5.current-prize ~ .item6 .content,
.item6.current-prize ~ .item7 .content,
.item7.current-prize ~ .item8 .content,
.item8.current-prize ~ .item1 .content {
  transform: rotate(348deg);
}

.wheel-container .item8 .content {
  z-index: 9;
}

.wheel-container .content {
  color: #fff;
  width: 100%;
  height: 100%;
  text-align: center;
  position: absolute;
  max-width: 50%;
  display: flex;
  align-content: center;
  flex-direction: column;
  padding-top: 55px;
  left: 165px;
  top: 0%;
  transform: rotate(-22.5deg);
}

@media (max-width: 1360px) {
  .freespin-count {
    left: -80%;
    top: -10px;
  }
}

@media only screen and (max-width: 1340px) {
  .wheel-block .cta {
    flex-basis: 200px;
    background-size: contain;
    margin: 0;
  }

  .wheel-block .cta .cta__text {
    padding-bottom: 15px;
    font-size: 24px;
    line-height: 30px;
  }
}

@media only screen and (max-width: 1100px) {
  .wheel-game-page {
    padding: 25px 0;
  }

  .wheel-block .cta {
    flex-basis: 100%;
  }

  .wheel-block .cta .cta__text {
    max-width: initial;
    padding-bottom: 0;
  }

  .wheel-container {
    margin: 45px 0;
  }

  .cta.cta--right {
    display: flex;
    flex-direction: column;
  }

  .freespin-count {
    position: initial;
    margin-bottom: 30px;
  }

  .wheel-block .cta.cta--right {
    order: 2;
  }

  .wheel-block .cta.cta--left {
    order: 1;
  }

  .wheel-block .cta.cta--left,
  .wheel-block .cta.cta--right {
    background: none;
  }

  .wheel-block {
    flex-direction: column-reverse;
  }

  .multiplier-select__wrap {
    margin-top: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .wheel-game-page {
    padding: 30px 0px;
  }

  .wheel-container {
    margin: 20px 0;
  }

  .wheel-container .red-border {
    width: 300px;
    height: 300px;
    border-radius: 150px;
  }

  .wheel-container .stars {
    width: 300px;
    height: 300px;
    border-radius: 150px;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .wheel-container .sectors {
    width: 262px;
    height: 262px;
    border-radius: 131px;
    background-size: contain;
  }

  .wheel-container .spin-btn-wrapper {
    min-width: 64px;
  }

  .wheel-container .sectors__content {
    width: 131px;
    height: 131px;
    border-radius: 131px;
  }

  .wheel-container .item {
    width: 131px;
    height: 131px;
    font-size: 10px;
    overflow: visible;
  }

  .wheel-block .cta .cta__text {
    font-size: 18px;
    padding-bottom: 12px;

  }

  .wheel-block .cta .cta__text {
    line-height: 18px;
    padding-bottom: 5px;
  }

  .content__header {
    font-size: 10px;
  }

  .content__header.has-prize {
    font-size: 12px;
  }

  .content__text {
    font-size: 7px;
  }

  .freespin-count {
    margin-bottom: 15px;
    font-size: 12px;
  }

  .freespin-count .count {
    font-size: 18px;
  }


  .item1.current-prize ~ .item8 .content,
  .item2.current-prize ~ .item1 .content,
  .item3.current-prize ~ .item2 .content,
  .item4.current-prize ~ .item3 .content,
  .item5.current-prize ~ .item4 .content,
  .item6.current-prize ~ .item5 .content,
  .item7.current-prize ~ .item6 .content,
  .item8.current-prize ~ .item7 .content {
    transform: rotate(340deg);
  }

  .item1.current-prize ~ .item2 .content,
  .item2.current-prize ~ .item3 .content,
  .item3.current-prize ~ .item4 .content,
  .item4.current-prize ~ .item5 .content,
  .item5.current-prize ~ .item6 .content,
  .item6.current-prize ~ .item7 .content,
  .item7.current-prize ~ .item8 .content,
  .item8.current-prize ~ .item1 .content {
    transform: rotate(340deg);
  }

  .wheel-container .content {
    padding-top: 25px;
    left: 74px;
    transform: rotate(-21.5deg);
  }

  .wheel-container .pepper {
    width: 100px;
    height: 100px;
    left: calc(50% - 50px);
    top: calc(50% - 50px);
    background-size: cover;
  }

  .wheel-container .spin-btn-wrapper {
    left: calc(50% - 32px);
    top: calc(50% + 12px);
  }

  .wheel-container .spin-btn-wrapper .btn {
    padding: 3px 9px;
    font-size: 10px;
    line-height: 18px;
  }

  .wheel-container .spin-btn-wrapper .btn.disabled {
    min-width: auto;
    padding: 3px 9px;
    cursor: not-allowed;
  }

  .wheel-container .top-triangle {
    width: 136px;
    height: 180px;
    left: calc(50% - 68px);
    top: -14px;
    transform: scale(1.15);
  }

  .wheel-container .boom {
    width: 30px;
    height: 34px;
    background-size: contain;
  }

  .wheel-container .boom-container {
    width: 144px;
    height: 53px;
    padding-top: 4px;
    background-size: contain;
    background-repeat: no-repeat;
    top: -12px;
    left: calc(50% - 72px);
  }
}

@media (max-width: 600px) {
  .wheel-block .cta {
    margin: 0;
  }

  .wheel-game-page {
    padding: 15px 0;
  }

  /*.wheel-block .cta.cta--right {*/
  /*  margin-bottom: 15px;*/
  /*}*/

  /*.wheel-block .cta.cta--left {*/
  /*  margin-top: 15px;*/
  /*}*/

}
